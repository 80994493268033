import { makeStyles } from '@material-ui/core/styles';

// These are shared styles between PublicHeader and PrivateHeader
export const useHeaderStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  linkSpacing: {
    display: 'flex',
    gap: theme.spacing(3),
  },
  largeLinkText: {
    fontSize: '1.1rem',
  },
  divider: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
  },
}));
