import { Box, Button, Divider, Hidden } from 'components/shared';
import { PublicRouteName, getRouteName } from './headerUtils';
import { HeaderDrawer } from './HeaderDrawer';
import { useHeaderStyles } from './Header.styles';
import { LinkWrapper } from './LinkWrapper';

interface PublicHeaderProps {
  onClickPublicRoute: (routeName: PublicRouteName) => void;
  isAuthenticated: boolean;
  isMaintenance?: boolean;
}

export const PublicHeader = (props: PublicHeaderProps) => {
  const { onClickPublicRoute, isAuthenticated } = props;
  const classes = useHeaderStyles();

  return (
    <Box id="public-header" className={classes.container}>
      {/* Desktop Header */}
      <Hidden smDown>
        <Box className={classes.linkSpacing}>
          <LinkWrapper routeName="return" onClick={onClickPublicRoute} />
          <LinkWrapper routeName="help-faq" onClick={onClickPublicRoute} />
        </Box>

        <Box>
          {isAuthenticated ? (
            <LinkWrapper routeName="logout" onClick={onClickPublicRoute} />
          ) : (
            <>
              <Button
                id="header-join"
                variant="contained"
                color="secondary"
                onClick={() => onClickPublicRoute('join')}
              >
                {getRouteName('join')}
              </Button>
            </>
          )}
        </Box>
      </Hidden>

      {/* Mobile Header */}
      <Hidden mdUp>
        <HeaderDrawer>
          <LinkWrapper routeName="return" onClick={onClickPublicRoute} />
          <LinkWrapper routeName="help-faq" onClick={onClickPublicRoute} />

          <Divider className={classes.divider} />

          {isAuthenticated ? (
            <LinkWrapper routeName="logout" onClick={onClickPublicRoute} />
          ) : (
            <LinkWrapper routeName="join" onClick={onClickPublicRoute} />
          )}
        </HeaderDrawer>
      </Hidden>
    </Box>
  );
};
