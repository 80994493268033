import { SWR_QUERY_KEY } from 'constants/api';
import { getToken } from 'utils/token';

export async function fetchWithToken<T>({
  url,
  data,
  method = 'POST',
}: {
  url: string;
  data?: T;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
}): Promise<Response> {
  const token = getToken();

  const requestOptions: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_HIGHLIGHT_API_KEY || '',
      authorization: token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify(data),
  };
  return fetch(url, requestOptions);
}

export async function fetchPublicHighlighterApi({route}: {route: string}): Promise<Response> {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${HIGHLIGHTER_API_BASE_URL}${route}`, requestOptions)
}

export async function fetchHighlighterApi<T>({
  route,
  data,
  method,
}: {
  route: string;
  data?: T;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
}): Promise<Response> {
  return fetchWithToken({
    url: `${HIGHLIGHTER_API_BASE_URL}${route}`,
    data: data,
    method: method,
  });
}

const HIGHLIGHTER_API_BASE_URL = process.env.REACT_APP_HIGHLIGHTER_API_URL
  ? process.env.REACT_APP_HIGHLIGHTER_API_URL
  : '';

// A fetcher to fetch data from the highlighter api
export const swrHighlighterApiFetcher = async (url: SWR_QUERY_KEY) => {
  try {
    const response = await fetchWithToken({
      method: 'GET',
      url: HIGHLIGHTER_API_BASE_URL + url,
    });
    if (response.status === 200) {
      const json = await response.json();
      return json;
    }
  } catch (error) {
    console.error('Error fetching data: ', error);
  }
};
