import { PhoneVerification } from 'components/FirstTimeProfile/PhoneVerification';
import { useCustomerAddress } from 'hooks/useCustomerAddress';
import React, { useEffect, useState } from 'react';
import { AuthenticatedUser } from 'types/Authentication';
import type { Address, Customer } from 'types/Customer';

import AddressSection from 'components/FirstTimeProfile/AddressSection';
import CodeVerification from 'components/FirstTimeProfile/CodeVerification';
import DemoQuestionsSection from 'components/FirstTimeProfile/DemoQuestionsSection';
import Dob from 'components/FirstTimeProfile/Dob';
import { OnboardingSection } from 'components/FirstTimeProfile/types';
import { usePageStyles } from 'components/PageElements';
import { Box, Stepper } from 'components/shared';

// These values are used to track which step of the registration process the user is in.
// based on which registration step they are on, we will ask for different information.
// e.g a user on the DateOfBirthRegistrationStep is prompted with filling out their birthday in the ui
// while someone on the AddressRegistrationStep is prompted to fill out their address
export const PhoneRegistrationStep = 1;
export const PhoneVerificationRegistrationStep = 2;
export const DateOfBirthRegistrationStep = 3;
export const DemographicQuestionRegistrationStep = 4;
export const AddressRegistrationStep = 5;

// These values relate to the stepper component which displays the progress of the user profile setup at the top
// of the page during user registration. there are 4 different 'stepper steps' and they change based on registration steps changing.
const PhoneIphoneStepperStep = 0;
const PersonStepperStep = 1;
const FinancialStepperStep = 2;
const AddressStepperStep = 3;

export function getInitialProfileRegistrationStep(customer: Customer): number {
  console.log('getInitialStep => customer', customer);
  // temporarily adding for bombas highlighters to bypass demo questions
  if (customer.signupReferralCode == 'NOV-SOCK-2023') {
    console.log(`initial step = ${AddressRegistrationStep} special referral code`);
    return AddressRegistrationStep;
  }
  if (!customer?.phone || !customer?.phoneVerified) {
    console.log(`initial step = ${PhoneRegistrationStep}`);
    return PhoneRegistrationStep;
  } else if (!customer.dob) {
    console.log(`initial step = ${DateOfBirthRegistrationStep}`);
    return DateOfBirthRegistrationStep;
  } else if (!customer.address?.length && !(customer.address as Address)?.id) {
    // no data (at this point) to determine if demo questions have been
    // completed/partially completed, so we start here unless address is present
    console.log(`initial step = ${DemographicQuestionRegistrationStep}`);
    return DemographicQuestionRegistrationStep;
  } else {
    // if everything is already filled out fallback to the final step
    console.log(`initial step = ${AddressRegistrationStep}`);
    return AddressRegistrationStep;
  }
}

// get a stepper step from a mapped registration step
function getStepperStepFromRegistrationStep(step: number): number {
  return (
    {
      [PhoneRegistrationStep]: PhoneIphoneStepperStep,
      [PhoneVerificationRegistrationStep]: PhoneIphoneStepperStep,
      [DateOfBirthRegistrationStep]: PersonStepperStep,
      [DemographicQuestionRegistrationStep]: FinancialStepperStep,
      [AddressRegistrationStep]: AddressStepperStep,
    }[step] ?? PhoneIphoneStepperStep
  );
}

export function getShouldSkipAddressCreation(customerAddresses: Customer['address']): boolean {
  if (customerAddresses === undefined) return false;

  const address = customerAddresses instanceof Array && customerAddresses.length
      ? customerAddresses?.[0]
      : (customerAddresses as Address);

  return !!address?.id;
}

export const logOnboardingError = (
  onboardingSection: OnboardingSection,
  error: string
): void => {
  console.error(`Onboarding Error: FirstTimeProfile - ${onboardingSection} - ${error}`);
};

const FirstTimeProfile = (props: {
  customer: Customer;
  user: AuthenticatedUser;
  refetchCustomer: () => void;
}): JSX.Element => {
  const pageClasses = usePageStyles();
  const { user, customer } = props;
  const [currentStep, setCurrentStep] = useState(
    getInitialProfileRegistrationStep(customer)
  );
  const [stepperStep, setStepperStep] = useState(
    getStepperStepFromRegistrationStep(currentStep)
  );
  const [errorDuringOnboarding, setErrorDuringOnboarding] = useState(false);

  const shouldSkipAddressCreation = getShouldSkipAddressCreation(customer.address);

  const updateStepper = (step: number): void => {
    setStepperStep(getStepperStepFromRegistrationStep(step));
  };

  const nextStep = (): void => {
    setCurrentStep((prevState): number => {
      console.log(prevState + 1);
      updateStepper(prevState + 1);
      return prevState + 1;
    });
  };

  const previousStep = (): void => {
    setCurrentStep((prevState): number => {
      console.log(prevState - 1);
      updateStepper(prevState - 1);
      return prevState - 1;
    });
  };

  // used to disable the prevStep or nextStep movement
  const _disabledMovement = (): null => null;

  // used for a component to flag the onboarding process failed in some way
  const _setErrorDuringOnboarding = (
    errorDuringOnboarding: boolean,
    onboardingSection: OnboardingSection,
    error: string
  ): void => {
    setErrorDuringOnboarding(errorDuringOnboarding);
    logOnboardingError(onboardingSection, error);
    console.log({
      errorDuringOnboarding,
      onboardingSection,
      error,
    });
  };

  useEffect(() => {
    const data = {
      user: user,
      customer: customer,
      currentStep: currentStep,
      stepperStep: stepperStep,
    };
    console.info(`FirstTimeProfile has loaded for user - ${data}`);
  }, []);

  return (
    <Box className={pageClasses.page}>
      <Box marginBottom="24px">
        <Box style={{ width: '100%', maxWidth: '360px' }}>
          <Stepper
            steps={['PhoneIphone', 'Person', 'Financial', 'Address']}
            activeStep={stepperStep}
          />
        </Box>
      </Box>
      {currentStep === PhoneRegistrationStep ? (
        <PhoneVerification
          user={user}
          customer={customer}
          nextStep={nextStep}
          previousStep={_disabledMovement}
        />
      ) : undefined}
      {currentStep === PhoneVerificationRegistrationStep ? (
        <CodeVerification
          user={user}
          customer={customer}
          nextStep={nextStep}
          previousStep={previousStep}
        />
      ) : undefined}
      {currentStep === DateOfBirthRegistrationStep ? (
        <Dob
          user={user}
          customer={customer}
          nextStep={nextStep}
          previousStep={_disabledMovement}
        />
      ) : undefined}
      {currentStep === DemographicQuestionRegistrationStep ? (
        <DemoQuestionsSection nextStep={nextStep} customer={customer} />
      ) : undefined}
      {currentStep === AddressRegistrationStep ? (
        <AddressSection
          user={user}
          customer={customer}
          nextStep={_disabledMovement}
          previousStep={previousStep}
          refetchCustomer={props.refetchCustomer}
          errorDuringOnboarding={errorDuringOnboarding}
          setErrorDuringOnboarding={_setErrorDuringOnboarding}
          logError={logOnboardingError}
          form={{} as useCustomerAddress}
          shouldSkipAddressCreation={shouldSkipAddressCreation}
        />
      ) : undefined}
    </Box>
  );
};

export default FirstTimeProfile;
