import React from 'react';

import { DefaultEmptyState, EmojiFoodBeverageSharp } from 'components/shared';

const bodyText = `We're working hard to find the perfect products for you to try. When we do, you'll get an email alert and your brand-new Opt-In will appear here.`;

const NoNewOffers = (): JSX.Element => {
  return (
    <DefaultEmptyState
      icon={<EmojiFoodBeverageSharp fontSize="inherit" color="inherit" />}
      title={'No Opt-Ins available right now'}
      body={bodyText}
      footnote={'For now, take the day off; you’re doing great!'}
    />
  );
};

export default NoNewOffers;
