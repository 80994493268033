import React from 'react';
import { RowProps } from './types';
import { getBorderStyle } from './utils';
import styles from './styles.module.scss';

const Row = ({ borderBottom, borderTop, borderLeft, borderRight, children, style, className, onClick }: RowProps): JSX.Element => {
    const classes =
        // 'row' +
        styles.row + getBorderStyle(borderTop, borderBottom, borderLeft, borderRight) + (className ? ' ' + className : '');
    return (
        <div style={style} onClick={onClick} className={classes}>
            {children}
        </div>
    );
};

export default Row;
