import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { CustomerStatus } from 'types/Customer';
import { ActiveSurveyInstance, OptInSurveyInstance } from 'types/Offer';

import AccessRevoked from 'components/EmptyState/AccessRevoked';
import NoNewOffers from 'components/EmptyState/NoNewOffers';
import { OptInSurveyCard } from 'components/Surveys/OptInSurveyCard';
import { Box, Grid } from 'components/shared';

export const useStyles = makeStyles(() => ({
  emptyStateContainer: {
    margin: '0 auto',
  },
}));

export interface NewOffersProps {
  customerStatus: CustomerStatus;
  optInSurveyInstances: OptInSurveyInstance[];
  onPressOptInSurvey: (data: ActiveSurveyInstance) => void;
}

export const NewOffers = (props: NewOffersProps) => {
  const { customerStatus, onPressOptInSurvey, optInSurveyInstances } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const accountLocked = customerStatus === 'disabled' || customerStatus === 'banned';

  const getDispatchedOptInSurveyCards = (): JSX.Element[] => {
    const optInSurveyCards = optInSurveyInstances.map(
      (optInSurvey: OptInSurveyInstance) => {
        return (
          <Grid item key={optInSurvey.id}>
            <OptInSurveyCard
              optInSurveyInstance={optInSurvey}
              onPressButton={onPressOptInSurvey}
            />
          </Grid>
        );
      }
    );
    return optInSurveyCards;
  };

  const getSurveyCards = (): JSX.Element[] => {
    return getDispatchedOptInSurveyCards();
  };

  return (
    <Grid
      container
      spacing={3}
      justifyContent={isSmallScreen ? 'center' : 'flex-start'}
      style={{ marginBottom: 25 }}
    >
      {accountLocked ? (
        <Box className={classes.emptyStateContainer}>
          <AccessRevoked />
        </Box>
      ) : optInSurveyInstances.length > 0 ? (
        getSurveyCards()
      ) : (
        <Box className={classes.emptyStateContainer}>
          <NoNewOffers />
        </Box>
      )}
    </Grid>
  );
};
