import { fetchHighlighterApi } from 'data/services/RequestMethods';
import { IsPhoneAvailableRequest, IsPhoneAvailableResponse } from 'types/Phone';

const useIsPhoneAvailable = () => {
  const isPhoneAvailable = async (
    phoneNumber: string
  ): Promise<IsPhoneAvailableResponse> => {
    try {
      const request: IsPhoneAvailableRequest = { phoneNumber };
      const resp = await fetchHighlighterApi({
        method: 'POST',
        route: `/my/phonenumbers/$availability`,
        data: request,
      });
      return resp.json();
    } catch (e) {
      console.error(`could not retrieve  phone availability error: ${e}`);
      throw e;
    }
  };
  return { isPhoneAvailable };
};

const useSendPhoneVerificationCode = () => {
  const sendPhoneCode = async (
    phoneNumber: string,
    channel: string
  ): Promise<Response> => {
    try {
      const response = await fetchHighlighterApi({
        route: '/my/highlighteraccounts/verification',
        method: 'PUT',
        data: {
          phoneNumber: phoneNumber,
          channel: channel,
          action: 0, // Send Code Action
        },
      });
      return response;
    } catch (error) {
      console.error(`error occurred when sending phone verification code ${error}`);
      throw error;
    }
  };
  return { sendPhoneCode };
};

const useVerifyPhoneCode = () => {
  const verifyPhoneCode = async (phone: string, code: string): Promise<Response> => {
    const response = await fetchHighlighterApi({
      route: '/my/highlighteraccounts/verification',
      method: 'PUT',
      data: {
        phoneNumber: phone,
        code: code,
        action: 1, // Verify Code Action
      },
    });
    return response;
  };
  return { verifyPhoneCode };
};

export { useSendPhoneVerificationCode, useVerifyPhoneCode, useIsPhoneAvailable };
