import { useEffect, useState } from 'react';
import { useQueryString } from 'hooks/useQueryString';

import type { RefCodeInputTheme } from 'components/login/RefCodeInput';
import { getRefCodeInputValues } from './utils';
import { useCheckReferralCode } from 'hooks/api/referralcodes';

export const REFERRAL_CODE_MSGS = {
  initial: 'Enter your invite code here', // space char forces input to save space, preventing align jump
  empty: 'Please enter a valid invite code before signing up',
  error: 'This invite code is not valid',
  success: 'Perfect! Sign up now',
};

interface RefCodeInputProps {
  isValidRefCode: boolean;
  refCodeId: string;
  refCode: string;
  helperText: string;
  theme: RefCodeInputTheme;
}

export const useReferralCode = () => {
  const queryString = useQueryString();
  const referralCodeFromUrl = queryString.get('referralCode') ?? '';
  const [refCodeInputProps, setRefCodeInputProps] = useState<RefCodeInputProps>({
    isValidRefCode: false,
    refCode: referralCodeFromUrl,
    refCodeId: '',
    helperText: REFERRAL_CODE_MSGS.initial,
    theme: 'primary',
  });

  const [isValidateCodeLoading, setIsValidateCodeLoading] = useState(false);
  const { checkReferralCode } = useCheckReferralCode()

  // check for referral code in url
  useEffect(() => {
    const referralCodeFromUrl = queryString.get('referralCode');
    if (referralCodeFromUrl && referralCodeFromUrl?.length > 0) {
      validateReferralCode(referralCodeFromUrl);
    }
  }, []);

  const validateReferralCode = async (value: string): Promise<void> => {
    let tempRefCodeInputProps: RefCodeInputProps = {
      ...refCodeInputProps,
      refCode: value,
    };

    if (value.length > 5) {
      setIsValidateCodeLoading(true);
      const res = await checkReferralCode(value);
      if (res) {
        const refCodeInputValues = getRefCodeInputValues(res, value);
        tempRefCodeInputProps = {
          ...tempRefCodeInputProps,
          isValidRefCode: refCodeInputValues.validReferralCode,
          refCodeId: refCodeInputValues.referralCodeId,
          helperText: refCodeInputValues.helperText,
          theme: refCodeInputValues.refCodeInputTheme,
        };
      }
    } else if (value.length <= 5 && refCodeInputProps.refCode.length >= 6) {
      tempRefCodeInputProps = {
        ...tempRefCodeInputProps,
        helperText: REFERRAL_CODE_MSGS.initial,
        theme: 'primary',
      };
      if (refCodeInputProps.isValidRefCode) {
        tempRefCodeInputProps = {
          ...tempRefCodeInputProps,
          isValidRefCode: false,
        };
      }
    }

    setRefCodeInputProps(tempRefCodeInputProps);
    setIsValidateCodeLoading(false);
  };

  return {
    refCodeInputProps: {
      helperText: refCodeInputProps.helperText,
      initialValue: referralCodeFromUrl,
      loading: isValidateCodeLoading,
      theme: refCodeInputProps.theme,
      validReferralCode: refCodeInputProps.isValidRefCode,
      onChange: validateReferralCode,
    },
    referralCodeId: refCodeInputProps.refCodeId,
    referralCode: refCodeInputProps.refCode,
  };
};
