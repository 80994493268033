import React from 'react';

// AnimatedLetters takes in text and performs 
// the animation on each letter based on the parent class
const AnimatedLetters = ({
  text,
  animatedItemClass,
}: {
  text: string;
  animatedItemClass: string;
}) => {
  const textArray = text.split('');

  return (
    <span>
      {textArray.map((char, index) => (
        <span
          key={index}
          className={char === ' ' ? '' : animatedItemClass}
          style={char === ' ' ? {} : { animationDelay: `${index * 0.05}s` }}
        >
          {char}
        </span>
      ))}
    </span>
  );
};

export default AnimatedLetters;
