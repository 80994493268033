import { AuthenticatedUser } from 'types/Authentication';
import { Customer } from 'types/Customer';

const isNewCustomer = (customer?: Customer) => {
  return customer?.status === 'new';
};

const isValidForCustomerCreation = ({
  actionType,
  referralCode,
  referralCodeId,
  user,
  customer,
}: {
  actionType?: string;
  referralCode?: string;
  referralCodeId?: string;
  user?: AuthenticatedUser;
  customer?: Customer;
}) => {
  const shouldAttemptCustomerCreation = !!(
    actionType === 'signup' &&
    referralCode &&
    referralCodeId &&
    !customer &&
    user
  );

  return shouldAttemptCustomerCreation;
};

export { isNewCustomer, isValidForCustomerCreation };
