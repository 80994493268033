import MAINTENANCE_ICON from 'assets/images/maintenance.svg';

interface MaintenanceProps {
  maintenanceEndTime: Date;
}

export const Maintenance = (props: MaintenanceProps) => {
  const endTime = props.maintenanceEndTime.toLocaleString();
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <img style={{
        maxWidth: "500px",
        padding: "40px",
      }} src={MAINTENANCE_ICON}></img>
      <p style={{
        fontFamily: '"Recoleta", sans-serif',
        fontSize: "2.2rem",
        textAlign: 'center',
      }}>
        Highlight is down for scheduled maintenance.
        <br />
        We expect to be back around {endTime}.
      </p>
    </div>
  );
}
