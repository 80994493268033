import { Theme, makeStyles } from '@material-ui/core';
import React from 'react';

import {
  Box,
  CheckCircleOutline,
  ReportProblemOutlined,
  Typography,
} from 'components/shared';
import { DefaultModalProps } from 'components/shared/Modal';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: '24px',
  },
  iconWrapper: {
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '20px',
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: '40px',
    height: '40px',
    width: '40px',
  },
  warningIcon: {
    color: theme.palette.warning.main,
    fontSize: '40px',
    height: '40px',
    width: '40px',
  },
}));

export interface ModalIconHeaderProps {
  title: DefaultModalProps['title'];
  status?: 'success' | 'warning';
}

export function ModalIconHeader(props: ModalIconHeaderProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant={'h3'}>{props.title}</Typography>
      {props.status ? (
        <Box className={classes.iconWrapper}>
          {props.status === 'success' ? (
            <CheckCircleOutline className={classes.successIcon} fontSize={'inherit'} />
          ) : (
            <ReportProblemOutlined className={classes.warningIcon} fontSize={'inherit'} />
          )}
        </Box>
      ) : undefined}
    </Box>
  );
}
