import { SURVEY_VENDOR } from 'constants/AppConstants';
import { parseSurveyVendorUrl } from 'utils/offersUtils';

import SurveyEmbed from 'components/SurveyEmbed';
import SurveyModal from 'components/SurveyIFrame/SurveyModal';

import type { SurveyProps } from './types';

const Survey = (props: SurveyProps): JSX.Element => {
  const { customer, onClose, onStatusChange, surveyInstance } = props;
  const { id: highlighterId } = customer;

  const [vendor, externalId] = parseSurveyVendorUrl(surveyInstance.surveyUrl);

  switch (vendor) {
    // Typeform Surveys for Solutions (2024)
    case SURVEY_VENDOR.TYPEFORM:
      return (
        <SurveyEmbed
          externalId={externalId}
          highlighterId={highlighterId}
          isOpen
          onClose={onClose}
          onStatusChange={onStatusChange}
          surveyInstance={surveyInstance}
        />
      );
    default:
      return (
        <SurveyModal
          data={{
            surveyId: surveyInstance.id,
            surveyType: surveyInstance.surveyType,
            userFirstName: customer.firstName,
            userId: highlighterId,
          }}
          modalIsOpen
          onClose={onClose}
          onStatusChange={onStatusChange}
          src={surveyInstance.surveyUrl}
        />
      );
  }
};

export default Survey;
