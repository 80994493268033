import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import highlightLogo from './Highlight_Logo_Circular_RGB.svg';

const ROTATION_SPEED = 1.66;

const useStyles = makeStyles((theme) => ({
  spinner: {
    fontSize: 10,
    position: 'absolute',
    borderTop: '0.22em solid rgba(0, 0, 0, 0.2)',
    borderRight: '0.22em solid rgba(0, 0, 0, 0.2)',
    borderBottom: '0.22em solid rgba(0, 0, 0, 0.2)',
    borderLeft: `0.22em solid ${theme.palette['secondary'].main}`,
    transform: 'translateZ(0)',
    animation: '$load8 1.1s infinite linear',
    borderRadius: '50%',
    width: '2em',
    height: '2em',
    bottom: `${theme.spacing(1)}px`,
    right: `${theme.spacing(1)}px`,
    '&::after': {
      borderRadius: '50%',
      width: '2em',
      height: '2em',
    },
  },
  '@keyframes load8': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  highlightSpinnerContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  highlightRotate: {
    display: 'inline-block',
    animation: `$rotate ${ROTATION_SPEED}s linear infinite`,
    '& img': {
      verticalAlign: 'middle',
    },
  },
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

interface SpinnerProps {
  variant?: 'default' | 'highlight';
}

export const Spinner = (props: SpinnerProps): JSX.Element => {
  const classes = useStyles();
  const { variant } = props;

  if (variant === 'highlight') {
    return (
      <div className={classes.highlightSpinnerContainer} data-testid="highlight-spinner">
        <div className={classes.highlightRotate}>
          <img src={highlightLogo} alt="highlight logo" height={100} width={100} />
        </div>
      </div>
    );
  }
  return <div className={classes.spinner} data-testid="default-spinner" />;
};
