import { SWR_QUERY_KEY } from 'constants/api';
import {
  fetchHighlighterApi,
  swrHighlighterApiFetcher,
} from 'data/services/RequestMethods';
import dayjs from 'dayjs';
import { ErrorToastWithContactUsLink, ToastVariant, useToast } from 'hooks/useToast';
import useSWR from 'swr';
import { Customer } from 'types/Customer';
import { Highlighter } from 'types/Highlighter';

const useCreateHighlighter = () => {
  const createHighlighter = async (highlighter:Highlighter): Promise<Customer> => {
    try {
      const resp = await fetchHighlighterApi({
        route: '/my/highlighteraccounts',
        method: 'POST',
        data: highlighter,
      });
      const responseJson = await resp.json();
      responseJson.signupReferralCode = responseJson.signUpReferralCode;
      return responseJson;
    } catch (error) {
      console.error(`error occurred when updating highlighter info ${error}`);
      throw error;
    }
  }
  return { createHighlighter };
}

const useUpdateHighlighter = () => {
  const updateHighlighter = async (highlighter: Highlighter): Promise<Response> => {
    try {
      const resp = await fetchHighlighterApi({
        route: '/my/highlighteraccounts',
        method: 'PUT',
        data: highlighter,
      });
      return resp;
    } catch (error) {
      console.error(`error occurred when updating highlighter info ${error}`);
      throw error;
    }
  };
  return { updateHighlighter };
};

const useGetHighlighter = (execute: boolean, email: string|undefined) => {
  const { showToast } = useToast();

  // we only have this method right now for a specific use case in the useProfile hook.
  // when we get rid of the feature flag to get customer from graphql vs highlighter-api we
  // can refactor that hook and get rid of this
  const getHighlighter = async (): Promise<Customer> => {
    try {
      const resp = await fetchHighlighterApi({
        route: SWR_QUERY_KEY.GetHighlighterHttp,
        method: 'GET',
      });
      return await resp.json();
    } catch (error) {
      console.error(`could not fetch highlighter ${error}`);
      return Promise.reject('could not fetch highlighter');
    }
  };
  const {
    data: highlighter,
    isLoading: isHlApiLoading,
    mutate,
  } = useSWR<Customer>(
    // a null key is used for "conditional fetching". if the key if null we are telling swr to not fetch anything
    // this makes this "lazyish" and work for our current usecase. more info: https://swr.vercel.app/docs/conditional-fetching
    execute ? [SWR_QUERY_KEY.GetHighlighterHttp, email] : null,
    ([url]) => swrHighlighterApiFetcher(url),  // eslint-disable-line @typescript-eslint/no-unused-vars
    {
      revalidateOnFocus: false,
      onError: (error) => {
        console.error(`error fetching highlighter ${error}`);
        showToast({
          text: <ErrorToastWithContactUsLink errorText={`Error fetching highlighter.`} />,
          variant: ToastVariant.Error,
        });
      },
    }
  );
  if (highlighter && highlighter.dateOfBirth) {
    highlighter.dob = dayjs(highlighter.dateOfBirth).unix();
  }
  return {
    highlighter,
    isHlApiLoading,
    mutate,
    getHighlighter,
  };
};

const useSetHighlighterDateOfBirth = () => {
  const setHighlighterDateOfBirth = async (date: Date) => {
    try {
      const resp = await fetchHighlighterApi({
        route: '/my/highlighteraccounts/birthdate',
        method: 'POST',
        data: { DateOfBirth: date.toDateString() },
      });
      return resp;
    } catch (error) {
      console.error(`error occurred when setting highlighter Date of Birth ${error}`);
      throw error;
    }
  }
  return { setHighlighterDateOfBirth }
}

const useActivateHighlighter = () => {
  const activateHighlighter = async () => {
    try {
      return await fetchHighlighterApi({
        route: '/my/highlighteraccounts/activate',
        method: 'POST',
      })
    } catch (error){
      console.error(`error occurred when attempting to activate highlighter: ${error}`)
      throw error;
    }
  }
  return { activateHighlighter }
}

export { useCreateHighlighter, useUpdateHighlighter, useGetHighlighter, useSetHighlighterDateOfBirth, useActivateHighlighter };
