import {
  Box,
  Checkbox,
  DialogActions,
  FormControlLabel,
  PaperProps,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { APP_CONSTANTS } from 'constants/AppConstants';
import { useState } from 'react';

import { Button, DialogTitle, Link, StyledModal } from 'components/shared';

import { GoogleLoginFrame } from '../GoogleLoginFrame';

const useStyles = makeStyles((theme) => ({
  scrollBox: {
    overflowY: 'scroll',
    padding: '0 10px',
    height: '100%',
    minHeight: '150px',
    border: '1px solid #e0e0e0',
    marginTop: theme.spacing(2) + 'px',
  },
}));

const paperProps: Partial<PaperProps> = {
  style: { borderRadius: 0, padding: 24, maxWidth: 755, zIndex: 2300 },
};

interface UserAgreementModalProps {
  isVisible: boolean;
  onClose: () => void;

  referralCode: string;
}

const ModalBody = (): JSX.Element => {
  return (
    <>
      <p></p>
      <p>
        {`I agree to participate in product testing and/or research surveys (collectively, the “Activity”) for Showcase Insights, Inc. d/b/a Highlight (“Highlight”).`}
      </p>
      <p>
        {`In connection with product testing, I agree to follow in good faith such instructions as Highlight may provide to me and to honestly and accurately respond to any follow-up questions that Highlight may ask of me about my participation in the product testing. To the extent I am required to evaluate a product, I agree to do so in good faith, without bias and to the best of my ability. If the product's brand and/or name is blinded, I agree to keep such blinding intact. When required by Highlight to do so, I agree to keep all aspects of the product testing confidential and will not disclose to other parties what product category I am evaluating, how I am evaluating it, or anything else about the evaluation process.`}
      </p>
      <p>
        {`In connection with research surveys, I agree to respond honestly and accurately to the questions posed and will not take any actions which could tend to skew the results of the survey.`}
      </p>
      <p>
        {`I understand that my participation in the Activity is voluntary. I agree that Highlight may use the information I provide to create various insights which will be used by Highlight and others, including business partners and sponsors. Highlight may combine my input with that of others to produce aggregated reports. Highlight may also combine my input with information obtained from other sources and, if applicable, with information obtained through cookies or other automated means. I acknowledge that the Privacy Notice available at `}
        <Link href={APP_CONSTANTS.PRIVACY_POLICY_LINK} target="_blank">
          {APP_CONSTANTS.PRIVACY_POLICY_LINK}
        </Link>
        {` describes how Highlight may use my contact information and other personal information, including for advertising and marketing communications, offers or promotions about Highlight, or third parties’ products, services or other initiatives.`}
      </p>
      <p>
        {`I further acknowledge that after my participation in an Activity, Highlight or its third-party partners may elect to use the results for any lawful use, including without limitation publication or marketing and I hereby irrevocably consent to the inclusion in such use of my first name, image, survey responses and story of my participation in the Activity (the “Results”). The permission herein granted includes the right and permission to copyright, use, reuse, publish, and republish, the Results in any media now known or hereafter developed throughout the world, to reuse the Results in any media now known or hereafter developed throughout the world in conjunction with the branding and indicia of Highlight and its third-party partners, and to use the Results in connection with advertising, publicity and promotion for Highlight, its third-party partners and its affiliates and assigns.`}{' '}
      </p>
      <p>
        {`I hereby waive any right that I may have to inspect or approve the finished product, or the advertising or other copy that may be used in connection therewith, or any use to which it may be applied. I understand that any photographs may be cropped, edited, retouched, colored or otherwise altered. I acknowledge that my participation in the Activity will be at my sole risk and, if it should subject me to discomfort, injury, or other risk of harm, including death, I agree that Highlight and its affiliates shall have no liability whatsoever arising as a result of my participation in the Activity.`}{' '}
      </p>
      <p>
        {`I acknowledge that Highlight makes no representation or warranty whatsoever about the outcome of my participation in the Activity, nor about the suitability or competence of third persons, if any, who may be involved with the Activity. I hereby waive and release any right to assert any and all claims or causes of action, based on any theory of liability or fault that may arise against Highlight as a result of my participation in the Activity.`}{' '}
      </p>
      <p>
        {`Any incentive paid to you is not calculated based on time spent by you and is not pro-rated on an hourly basis or otherwise. By participating in research and receiving the incentive, you acknowledge and agree that your participation is without the control or direction of Highlight or any other party, and you agree the incentive represents fair value for your participation.`}{' '}
      </p>
      <p>
        {`I understand that in the course of the Activity I may be provided with products for testing that have not been released to the general public, and which may be therefore highly confidential. I agree that when required by Highlight, I will not disclose to any other person or entity any of the terms, conditions, or other facts with respect to my participation in the Activity, including when applicable the existence of or details of the products provided to me for testing. I agree that monetary damages may not be a sufficient remedy for my unauthorized use or disclosure of the confidential information regarding the Activity, and that in the event I violate or threaten to violate this Agreement, Highlight may, without waiving any other rights or remedies, without bond and without proof of monetary damages or the inadequacy of other remedies, to seek injunctive or other equitable relief.`}
      </p>
      <p>
        {`I represent that I am over the age of 18, and have the authority to grant Highlight the rights herein granted.`}
      </p>
    </>
  );
};

export const UserAgreementModal = (props: UserAgreementModalProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();
  const smAndDownScreens = useMediaQuery(theme.breakpoints.down('xs'));

  const [userHasAgreed, setUserHasAgreed] = useState(false);
  const handleClose = (): void => props.onClose();

  return (
    <StyledModal
      onClose={handleClose}
      open={props.isVisible}
      PaperProps={paperProps}
      fullWidth={true}
      scroll={'paper'}
      fullScreen={smAndDownScreens}
    >
      <DialogTitle id={'customized-dialog-title'} onClose={handleClose}>
        Participation Agreement
      </DialogTitle>
      <Typography variant="body2">
        One more thing before you sign up - we need you to read and agree to our
        Participation Agreement. This basically describes your responsibilities as a
        Highlighter.
      </Typography>
      <Box className={classes.scrollBox}>
        <ModalBody />
      </Box>
      <div style={{ padding: '20px 20px 20px 10px' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={userHasAgreed}
              onChange={(): void => setUserHasAgreed((prevState: boolean) => !prevState)}
              name="userHasAgreedCheckbox"
              style={{ color: userHasAgreed ? undefined : 'rgba(0, 0, 0, 0.65)' }}
              color="primary"
            />
          }
          label={
            <Typography variant="body2">
              By creating an account I agree to the Participation Agreement
            </Typography>
          }
        />
      </div>
      {smAndDownScreens ? (
        <>
          <>
            <span style={{ display: userHasAgreed ? 'none' : 'inline-flex' }}>
              <Button variant="outlined" color="primary" disabled>
                Create My Account
              </Button>
            </span>
            <span style={{ display: userHasAgreed ? 'inline-flex' : 'none' }}>
              <GoogleLoginFrame
                text="continue_with"
                context="signup"
                referralCode={props.referralCode}
              />
            </span>
          </>
          <div style={{ marginBottom: 16 }} />
          <Button variant="outlined" color="primary" onClick={handleClose}>
            No Thanks
          </Button>
        </>
      ) : undefined}
      <DialogActions style={{ height: 60 }}>
        {!smAndDownScreens && (
          <>
            <Button
              key="No Thanks"
              variant="outlined"
              color="primary"
              style={{ marginLeft: '24px' }}
              onClick={handleClose}
            >
              No Thanks
            </Button>
            <>
              <div style={{ display: userHasAgreed ? 'none' : 'inline-block' }}>
                <Button
                  key="Create My Account"
                  variant="outlined"
                  color="primary"
                  disabled
                  style={{ marginLeft: '21px' }}
                >
                  Create My Account
                </Button>
              </div>
              <div
                style={{
                  marginLeft: 19,
                  display: userHasAgreed ? 'inline-block' : 'none',
                }}
              >
                <GoogleLoginFrame
                  text="continue_with"
                  context="signup"
                  referralCode={props.referralCode}
                />
              </div>
            </>
          </>
        )}
      </DialogActions>
    </StyledModal>
  );
};
