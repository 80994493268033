import React from 'react';
import { IFrameProps } from './types';

const IFrame = (props: IFrameProps): JSX.Element => {
    return (
        <iframe
            width={'100%'}
            height={'100%'}
            frameBorder={0}
            allowFullScreen
            allow="camera *;microphone *"
            // leave destructuring assignment lastso we can override attributes
            {...props}
        />
    );
};

export default IFrame;
