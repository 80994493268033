import { useToken } from 'hooks/api/token';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AuthenticatedUser } from 'types/Authentication';
import { setToken } from 'utils/token';

export const loginError =
  'There was an issue signing into your account. Please try again. ';
export const signUpError =
  'There was an issue attempting to create your account. If the issues persists, please contact support via support@highlighters.\rletshighlight.com';
export const referralError = 'Invalid referral code. Please try again with a valid code.';

export type AuthenticationHandler = (idToken: string) => Promise<void>;

export const useAuth = () => {
  const history = useHistory();

  const { getHighlightApiAccessToken } = useToken();

  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState<boolean>(false);
  const [authenticatedUser, setAuthenticatedUser] = useState<
    AuthenticatedUser | undefined
  >(undefined);
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(true);

  useEffect(() => {
    const idToken = localStorage.getItem('idToken');
    if (idToken && isValid(idToken)) {
      authenticateGoogleUser(idToken);
    }
    setIsLoadingAuth(false);

    return () => clearTokens();
  }, []);

  const doTokenExchange = async (token: string) => {
    const accessToken = await getHighlightApiAccessToken(token);

    setAccessToken(accessToken);
    localStorage.setItem('token', accessToken);
    setToken(accessToken);

    console.log('User has token with length', accessToken.length);
  };

  const clearTokens = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('idToken');

    setAccessToken(null);
    setToken(null);

    console.log("User's tokens have been removed");
  };

  const isValid = (idToken: string) => {
    const decoded = jwtDecode<JwtPayload>(idToken);
    return decoded?.exp && decoded.exp > Date.now() / 1000;
  };

  interface GoogleJwtPayload extends JwtPayload {
    given_name?: string;
    family_name?: string;
    email: string;
  }

  const authenticateGoogleUser: AuthenticationHandler = async (idToken) => {
    const decoded = jwtDecode<GoogleJwtPayload>(idToken);
    const authUser = {
      email: decoded?.email || '',
      family_name: decoded?.family_name || '',
      given_name: decoded?.given_name || '',
    };

    setAuthenticatedUser(authUser);
    setIsGoogleAuthenticated(true);
    localStorage.setItem('idToken', idToken); // doing this here because doTokenExchange is used by both auth0 and google

    await doTokenExchange(idToken);
  };

  const googleAuthLogout = (returnUrl: string) => {
    clearTokens();
    setAuthenticatedUser(undefined);
    setIsGoogleAuthenticated(false);

    const url = new URL(returnUrl);
    history.push(url.pathname);
  };

  return {
    logout: googleAuthLogout,
    isAuthenticated: isGoogleAuthenticated,
    user: authenticatedUser,
    isUserLoading: isLoadingAuth,
    accessToken,
    authenticateGoogleUser,
  };
};
