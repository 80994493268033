import { FontFace } from 'csstype';

/* work-sans-400normal - latin */
export const WorkSans400Normal: FontFace = {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('Work Sans Regular'),
    local('Work Sans-Regular'),
    url(/fonts/WorkSans-VariableFont_wght.ttf) format('truetype')`,
};

/* work-sans-500normal - latin */
export const WorkSans500Normal: FontFace = {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
    local('Work Sans Medium'),
    local('Work Sans-Medium'),
    url(/fonts/WorkSans-VariableFont_wght.ttf) format('truetype')`,
};

/* work-sans-600normal - latin */
export const WorkSans600Normal: FontFace = {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `
    local('Work Sans SemiBold'),
    local('Work Sans-SemiBold'),
    url(/fonts/WorkSans-VariableFont_wght.ttf) format('truetype')`,
};