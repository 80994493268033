import { useGetHighlighter } from 'hooks/api/highlighter';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SignUpAppState } from 'types/Authentication';
import { Customer } from 'types/Customer';

export interface UseCurrentUser {
  customer: Customer | undefined;
  isCustomerLoading: boolean;

  refetchCustomer: () => void;
}

export const useCurrentUser = ({
  accessToken,
  email,
  isUserLoading,
}: {
  accessToken: string | null;
  email?: string;
  isUserLoading: boolean;
}): UseCurrentUser => {
  const history = useHistory();
  const routerState = history?.location?.state as SignUpAppState;
  const isSignUp = routerState?.actionType === 'signup';
  const [isReady, toggleIsReady] = useState(false);

  // the use effect in the useAuth hook that sets the token to local storage has
  // somewhat of a weird race behavior. this hook, when creating a profile, receives a token in its props
  // before the token is avaliable in local storage. that means that highlighter-api will think it should make a request
  // since it has an email and access token, but when we try to make the request, since the token is not in storage, we will
  // fail with unauthorized
  const isAcccessTokenAvailable = () => {
    return !!accessToken ? !!localStorage.getItem('token') : false;
  };

  // since there is no "lazy" swr out of the box, we make our own by only executing this query when there is an email and an access token
  // if we do not do this, when we try to logout, we will fetch a highlighter and be infinitely redirected to profile/home page
  // this is not a longterm solution, but it buys us time to either get all the customer fetches on swr or come up with
  // a different solution
  const { highlighter, isHlApiLoading, mutate } = useGetHighlighter(
    !!email && isAcccessTokenAvailable(), 
    email
  );

  // swrs mutate method causes a revalidation which marks the cached data as invalid and triggers
  // a refetch. We use bound mutate here rather than global because the useSwrHook is not always mounted in places where
  // this is used. for more information on mutate https://swr.vercel.app/docs/mutation
  const refetch = async () => {
    await mutate();
  };

  // Only fetch if it's not a sign up
  // The sign up create customer happens in useProfile hook.
  // we can combine these later so they're in the same place
  useEffect(() => {
    if (!isSignUp && email && isAcccessTokenAvailable()) {
      mutate();
    }
    if (!isUserLoading && (!email || (email && accessToken))) {
      // toggleIsReady to true if isUserLoading is false and (email is not present or both email and accessToken are present)
      console.log('changing toggleIsReady to true'); //TODO remove this when we know more about isReady and what its purpose is. maybe we can remove it
      toggleIsReady(true);
    }
  }, [accessToken, isUserLoading]);

  return {
    customer: highlighter,
    isCustomerLoading: isHlApiLoading || !isReady,
    refetchCustomer: refetch,
  };
};
