// Using a module so that we have a singleton.
let token: string | null = null;

const setToken = (t: string | null) => {
  if (t === null) {
    console.log('utils/token.ts: setting token to null');
  } else {
    console.log(`utils/token.ts: setting token with length ${t.length}`);
  }

  token = t;
};

const getToken = (): string | null => {
  const localStorageToken = localStorage.getItem('token');

  if (localStorageToken !== null) {
    console.log(`utils/token.ts: localStorageToken has length ${localStorageToken.length}`);
    return localStorageToken;
  }

  if (token !== null) {
    console.log(`utils/token.ts: token has length ${token.length}`);
    return token;
  }

  console.log('utils/token.ts: localStorageToken and token are both null');
  return null;
};

export {
  setToken,
  getToken,
};
