import { useState } from 'react';

import BirthdatePicker from 'components/Forms/BirthdatePicker';
import { logOnboardingError } from 'components/profile/FirstTimeProfile';
import { Box, Button, CircularProgress, Typography } from 'components/shared';

import SectionHeader from './SectionHeader';
import { StepProps } from './types';
import { useSetHighlighterDateOfBirth } from '../../hooks/api/highlighter';

const HELPER_TEXT = {
  unableToSaveError: 'Unable to save birthdate, please try again.',
};

export const Dob = (props: StepProps): JSX.Element => {
  const [birthdate, setBirthdate] = useState<Date | null>(null);
  const [helperText, setHelperText] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { setHighlighterDateOfBirth } = useSetHighlighterDateOfBirth();

  const onChange = (date: Date | null): void => {
    setBirthdate(date);
    setHelperText(undefined);
    setError(false);
    console.log('on change: ', date);
  };

  const previousStep = (): void => {
    props.previousStep();
  };

  const nextStep = async (): Promise<void> => {
    setLoading(true);

    if (birthdate) {
      const updateHighlighterDateOfBirthResponse = await setHighlighterDateOfBirth(birthdate)
      if (updateHighlighterDateOfBirthResponse.ok){
        props.nextStep();
      }
      else {
        // there was an error
        setHelperText(HELPER_TEXT.unableToSaveError);
        setError(true);
        setLoading(false);

        // log error to ext service
        let errorMessage = '';
        try {
          errorMessage = `Unable to update customer (birthdate: ${ birthdate ? birthdate.toString() : 'null' }`;
        } catch (error) {
          errorMessage =
            'Unable to update customer birthdate (unable to capture error)';
        }
        logOnboardingError('DobSection', errorMessage);
      }
    }
  };

  return (
    <>
      <SectionHeader
        title={`Let's fill out your profile!`}
        body={
          'We use these questions to match you with the perfect offers for you. Filling in the questions honestly and fully ensures you are eligible for the most offers'
        }
      />
      <Box marginBottom="24px">
        <Typography variant={'h5'}>When were you born?</Typography>
        <Typography variant={'caption'}>
          We won’t store your full birthdate, only the year. You must be 18 or older to
          join Highlight.
        </Typography>
      </Box>
      <BirthdatePicker
        id="dob-picker"
        onChange={onChange}
        error={error}
        helperText={helperText}
      />
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '15px',
        }}
      >
        <Box marginRight="16px">
          <Button
            variant={'outlined'}
            color={'primary'}
            onClick={previousStep}
            disabled={true}
          >
            Go Back
          </Button>
        </Box>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={nextStep}
          disabled={!birthdate || loading}
          endIcon={loading ? <CircularProgress size={20} /> : undefined}
        >
          {loading ? 'Loading... ' : 'Continue'}
        </Button>
      </Box>
    </>
  );
}

export default Dob;
