import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { FC } from 'react';

import { Modal, ModalIconHeader } from 'components/shared';
import type { DefaultModalProps } from 'components/shared/Modal';

export interface OnboardingResultModalProps extends DefaultModalProps {
  status?: 'success' | 'warning';
}

export const OnboardingResultModal: FC<OnboardingResultModalProps> = (
  props: OnboardingResultModalProps
) => {
  const theme = useTheme();
  const smAndDownScreens = useMediaQuery(theme.breakpoints.down('xs'));
  const _handleClose = (): void => {
    props.onClose();
  };

  return (
    <Modal
      onClose={_handleClose}
      open={props.open}
      fullScreen={smAndDownScreens}
      scroll={props.scroll}
      title={<ModalIconHeader title={props.title} status={props.status} />}
      body={props.body}
      buttons={props.buttons}
    />
  );
};
