import * as History from 'history';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { AuthenticatedUser } from 'types/Authentication';
import { Customer } from 'types/Customer';

import { usePageStyles } from 'components/PageElements';
import { ProfileTabs } from 'components/Tab-Features/ProfileTabs';
import { PersonalSection } from 'components/profile/PersonalSection';
import { PreferencesSection } from 'components/profile/PreferencesSection';
import { Box, Grid, Typography } from 'components/shared';

const UNSAVED_CHANGES_MESSAGE =
  'You have unsaved changes, are you sure you want to leave?';

export const logProfileError = (section: 'Address' | 'Basic', error: string): void => {
  console.error(`CompletedProfile: Profile Error: ${section} - ${error}`);
};

interface CompletedProfileProps {
  customer: Customer;
  user: AuthenticatedUser;
  refetchCustomer: () => void;
}

const CompletedProfile = (props: CompletedProfileProps): JSX.Element => {
  const history = useHistory();
  const state = history?.location?.state as { initialTabIndex?: number } | undefined;
  const pageClasses = usePageStyles();

  const [tabIndex, setTabIndex] = useState<number>(() => state?.initialTabIndex || 0);
  const [questionsUnsaved, setQuestionsUnsaved] = useState<string[]>([]);
  const [addressUnsaved, setAddressUnsaved] = useState(false);

  useEffect(() => {
    const incomingTabIndex =
      state?.initialTabIndex === 0 || state?.initialTabIndex === 1
        ? state.initialTabIndex
        : undefined;
    if (incomingTabIndex !== undefined && incomingTabIndex !== tabIndex) {
      setTabIndex(incomingTabIndex);
    }
  }, [props]);

  useEffect(() => {
    // update when unsaved changes are being tracked
  }, [questionsUnsaved, addressUnsaved]);

  const _onChangeTab = (event: ChangeEvent<any>, newValue: number): void => {
    if (event) {
      setTabIndex(newValue);
    }
  };

  const _onQuestionSaveEligibilityChange = (id: string, saveEligibile: boolean): void => {
    if (saveEligibile) {
      // add to array
      setQuestionsUnsaved((prevArray: string[]) => {
        prevArray.push(id);
        // Set() removes duplicates (ie multi-choice question duplicate ids)
        return Array.from(new Set(prevArray));
      });
    } else {
      // remove from array
      setQuestionsUnsaved((prevArray: string[]) => {
        return prevArray.filter((prevId: string) => prevId !== id);
      });
    }
  };

  const _onFormHasChanged = (formHasChanged: boolean): void => {
    setAddressUnsaved(formHasChanged);
  };

  return (
    <Box className={pageClasses.page}>
      <Box mb="25px">
        <Typography variant="h3">{'My Account'}</Typography>
      </Box>
      <ProfileTabs activeIndex={tabIndex} onChange={_onChangeTab}>
        <Grid container style={{ marginTop: 25 }}>
          <Grid item>
            <PersonalSection
              {...props}
              onFormHasChanged={_onFormHasChanged}
              logError={logProfileError}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 25 }}>
          <Grid item>
            <PreferencesSection
              {...props}
              onSaveEligibilityChange={_onQuestionSaveEligibilityChange}
            />
          </Grid>
        </Grid>
      </ProfileTabs>
      <Prompt
        when={questionsUnsaved.length > 0 || addressUnsaved}
        message={(location: History.Location): string | boolean => {
          // ignore nav back to profile view (ie menu link is clicked)
          if (location.pathname.startsWith('/profile')) {
            return true;
          } else {
            return UNSAVED_CHANGES_MESSAGE;
          }
        }}
      />
    </Box>
  );
};

export default CompletedProfile;
