/* eslint-disable */
export function debounce(func: any, wait: number, immediate?: boolean) {
    let timeout: any;
    // @ts-expect-error
    return (...args) => {
        // @ts-expect-error
        const context: any = this;

        const later = () => {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };

        const callNow = immediate && !timeout;
        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };
}
