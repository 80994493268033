import { Box, Typography } from '@material-ui/core';

import { GoogleLoginFrame } from 'components/login/GoogleLoginFrame';
import { PossibleIncorrectUserAccount } from 'components/login/PossibleIncorrectUserAccount';

interface LoginCardProps {
  onLogout: () => void;
  loginError: boolean;
}

export const LoginCard = (props: LoginCardProps) => {
  const { loginError, onLogout } = props;

  return (
    <Box marginBottom="32px" data-testid="login-card">
      {loginError ? (
        <PossibleIncorrectUserAccount onLogout={onLogout} />
      ) : (
        <>
          <Box marginBottom="40px">
            <Typography variant="h4">Already have an account?</Typography>
          </Box>

          <GoogleLoginFrame text="signin_with" context="login" />
        </>
      )}
    </Box>
  );
};
