import { WalkthroughModal } from 'components';
import React, { FC, useEffect, useState } from 'react';
import { Customer } from 'types/Customer';
import { localStorage } from 'utils/localStorage';

import { GridContainer, Row } from 'components/Grid';
import { Button } from 'components/shared';

import WalkthroughCard, { WalkthroughCardProps } from './WalkthroughCard';
import { cardData } from './cardData';
import styles from './styles.module.scss';

export interface WalkthroughManagerProps {
  customer: Customer;
}

export const WALKTHROUGH_MODAL_PRESENTED_KEY = 'walkthroughAlreadyPresented';

export const WalkthroughModalManager: FC<WalkthroughManagerProps> = (
  props: WalkthroughManagerProps
) => {
  const [showModal, setShowModal] = useState(false);
  const modalAlreadyPresented = !!localStorage.getItem(WALKTHROUGH_MODAL_PRESENTED_KEY);

  const _onDismiss = ({
    saveDismissToLocalStorage,
  }: {
    saveDismissToLocalStorage?: boolean;
  }) => {
    if (saveDismissToLocalStorage) {
      localStorage.setItem(WALKTHROUGH_MODAL_PRESENTED_KEY, true);
    }
    setShowModal(false);
  };

  // check local storage to see the value of walkthroughAlreadyPresented.
  // if false, show the walkthrough modal
  // if true, do not show the walkthrough modal
  useEffect(() => {
    setShowModal(!modalAlreadyPresented);
  }, [modalAlreadyPresented]);

  return (
    <WalkthroughModal
      title={`Welcome ${props.customer.firstName}! We’re so excited you’re here. `}
      subtitle={`Read through the steps below to learn how things will work from here:`}
      open={showModal}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose={() => {}}
      body={
        <>
          <GridContainer>
            <Row className={styles.walkthroughCardContainer}>
              {cardData.map((item: WalkthroughCardProps) => (
                <WalkthroughCard
                  title={item.title}
                  description={item.description}
                  imgUrl={item.imgUrl}
                  key={item.title}
                />
              ))}
            </Row>
          </GridContainer>
          <GridContainer>
            <Row className={styles.buttonContainer}>
              <Button
                variant={'outlined'}
                color={'primary'}
                onClick={() => _onDismiss({ saveDismissToLocalStorage: false })}
                className={styles.remindMeButton}
              >
                Remind Me Later
              </Button>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={() => _onDismiss({ saveDismissToLocalStorage: true })}
                className={styles.letsGoButton}
              >
                {"Got it - Let's Go!"}
              </Button>
            </Row>
          </GridContainer>
        </>
      }
      buttons={[]}
    />
  );
};

/*
    // save for debugging for debugging
    // paste below above modal
    <div style={{ padding: 20 }}>
        <hr />
        <br />
        <button onClick={() => setShowModal(true)}>Open Modal</button>
        <button onClick={() => localStorage.deleteItem(WALKTHROUGH_MODAL_PRESENTED_KEY)} style={{ marginLeft: 20 }}>
            delete walkthroughAlreadyPresented from localStorage
        </button>
        <br />
        <br />
        <p>localStorageChecked: {localStorageChecked ? 'true' : 'false'}</p>
        <p>needToCheckSurveyStatus: {needToCheckSurveyStatus ? 'true' : 'false'}</p>
        <p>surveysChecked: {surveysChecked ? 'true' : 'false'}</p>

        <p>walkthroughAlreadyPresented: {walkthroughAlreadyPresented ? 'true' : 'false'}</p>
        <p>showModal: {showModal ? 'true' : 'false'}</p>
        <p>errorFound: {errorFound ? 'true' : 'false'}</p>
        <hr />
    </div>
*/
