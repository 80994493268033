import React from 'react';
// import Input from '@material-ui/core/Input';
import MaskedTextInput from 'react-text-mask';
import type { MaskedInputProps } from 'react-text-mask';


const regexShortcuts = {
    number: /[0-9]/,
    nonZeroNumber: /[1-9]/,
    digit: /\d/,
};
const { number, nonZeroNumber } = regexShortcuts;

const maskPresets = {
    phoneNumber: [
        '+',
        '1',
        ' ',
        '(',
        nonZeroNumber,
        number,
        number,
        ')',
        ' ',
        number,
        number,
        number,
        '-',
        number,
        number,
        number,
        number,
    ],
};
interface TextMaskCustomProps extends MaskedInputProps  {
    inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskCustom = ({ inputRef, ...other }: TextMaskCustomProps) => {
    return (
        <MaskedTextInput
            {...other}
            ref={(ref: any) => {
                // Check if ref is not null and if it contains inputElement
                if (ref && 'inputElement' in ref && ref.inputElement instanceof HTMLInputElement) {
                    inputRef(ref.inputElement);
                } else {
                    inputRef(null);
                }
            }}
            mask={maskPresets['phoneNumber']}
        />
    );
};

export default TextMaskCustom;
