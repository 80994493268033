import { PrivateRoute } from 'components';
import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthenticatedUser } from 'types/Authentication';
import { Customer } from 'types/Customer';
import { getEnvFromWindow } from 'utils/envHelpers';
import { Login, Offers } from 'views';
import Profile from 'views/Profile';
import TestPage from 'views/TestPage';

/**
 *
 * @param status status of customer record (ie banned, active, new, etc)
 * @returns true if onboarding has not been completed (status === 'new')
 */
const isNewCustomer = (status?: string): boolean => {
  return status === 'new';
};

type OnboardingOrComp = JSX.Element | React.FC<Record<string, unknown>> | undefined;

const onboardingOrComponent = (
  routeProps: any,
  Component: any,
  customer?: Customer
): OnboardingOrComp => {
  if (customer) {
    const newUser = isNewCustomer(customer.status);
    if (newUser) {
      return <Redirect to={{ pathname: '/profile' }} />;
    } else {
      return <Component {...routeProps} />;
    }
  } else {
    const data = { routeProps: routeProps, customer: customer };
    console.warn(
      `onboardingOrComponent Function - tried to access route that requires customer object but found undefined or null. Sending user to login screen - ${data}`
    );
    return <Redirect to={{ pathname: '/login' }} />;
  }
};

const getLoginComponent = (
  logout: (returnUrl: string) => void,
  customer?: Customer
): JSX.Element => {
  if (customer) {
    const newUser = isNewCustomer(customer.status);
    return <Redirect to={{ pathname: `/${newUser ? 'profile' : 'home'}` }} />;
  } else {
    return <Login logout={logout}></Login>;
  }
};

type MainRouterProps = {
  customer?: Customer;
  user?: AuthenticatedUser;
  refetchCustomer?: () => void;
  logout: (returnUrl: string) => void;
  accessToken: string | null;
};

const Router: FC<MainRouterProps> = ({
  customer,
  user,
  refetchCustomer,
  logout,
  accessToken,
}: MainRouterProps): JSX.Element => {
  const env = getEnvFromWindow({ allowLocalHost: true });
  const isDevEnv = env === 'dev' || env === 'localhost';
  return (
    <Switch>
      <Route
        path="/login"
        exact
        component={(): JSX.Element => getLoginComponent(logout, customer)}
      />
      <PrivateRoute
        path="/profile"
        exact
        // this instructs <PrivateRoute /> to skip the customer?.email check so
        // we can determine onboarding vs exisiting user
        skipCustomerCheck={true}
        component={Profile}
        user={user}
        customer={customer}
        refetchCustomer={refetchCustomer}
        accessToken={accessToken}
      />
      <PrivateRoute
        path="/home"
        exact
        component={(props: any): OnboardingOrComp =>
          onboardingOrComponent(props, Offers, customer)
        }
        user={user}
        customer={customer}
        refetchCustomer={refetchCustomer}
      />

      {isDevEnv && <Route path="/test" exact component={TestPage} />}

      {/* The last route is the route that users will get redirected to if they try to access an incorrect route */}
      <Route
        path="/"
        component={(): JSX.Element => getLoginComponent(logout, customer)}
      />
    </Switch>
  );
};

export default Router;
