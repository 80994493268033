import React, { useState, FocusEvent } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/icons/VpnKeySharp';
import { makeStyles } from '@material-ui/core';
import { CheckCircleOutline, ErrorOutline, Input, Spinner } from 'components/shared'

export const useStyles = makeStyles(() => ({
  labelOffset: {
    transform: 'translate(44px, 20px) scale(1)',
  },
}));

const CodeInput = (props: any): JSX.Element => {
  const { error, success, loading, onFocus, onBlur, ...otherProps } = props;
  const classes = useStyles();
  const [shrink, setShrink] = useState(false);

  return (
    <Input
      {...otherProps}
      onFocus={(event: FocusEvent<HTMLInputElement>): void => {
        setShrink(true);
        onFocus?.(event);
      }}
      onBlur={(event: FocusEvent<HTMLInputElement>): void => {
        if (event.target.value.length === 0) {
          setShrink(false);
        }
        onBlur?.(event);
      }}
      theme={error ? 'error' : success ? 'success' : 'primary'}
      InputLabelProps={{ shrink: shrink, classes: { root: classes.labelOffset } }}
      InputProps={{
        // inputComponent: PhoneMask as any,
        startAdornment: (
          <InputAdornment position="start">
            <Icon color="inherit" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {loading ? (
              <Spinner />
            ) : error ? (
              <ErrorOutline color="inherit" />
            ) : success ? (
              <CheckCircleOutline color="inherit" />
            ) : (
              <div />
            )}
          </InputAdornment>
        ),
      }}
      inputProps={{
        maxLength: 6,
      }}
    />
  );
};

export default CodeInput;
