import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { FC } from 'react';

import PhoneCodeVerification from 'components/PhoneCodeVerification';
import { Modal, Typography } from 'components/shared';
import type { DefaultModalProps } from 'components/shared/Modal';

export interface PhoneCodeVerificationModalProps extends DefaultModalProps {
  phone: string;
  onVerified: () => void;
  verificationCodeSent: boolean;
  sendTextCode: () => void;
  sendCallCode: () => void;
}

export const PhoneCodeVerificationModal: FC<PhoneCodeVerificationModalProps> = (
  props: PhoneCodeVerificationModalProps
) => {
  const {
    onVerified,
    verificationCodeSent,
    sendCallCode,
    sendTextCode,
    phone,
    scroll,
    open,
    onClose,
  } = props;
  const theme = useTheme();
  const smAndDownScreens = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Modal
      onClose={onClose}
      open={open}
      fullScreen={smAndDownScreens}
      scroll={scroll}
      title="Verify Phone Code"
      body={
        <>
          <Typography variant={'body1'}>
            {
              'Please take a moment to verify your phone number. This helps us confirm your identity and keep your account secure.'
            }
          </Typography>

          <Typography variant={'caption'} color="primary">
            {verificationCodeSent
              ? 'Verification code sent. Please allow a moment for code to arrive.'
              : 'Please click a button below to receive your verification code'}
          </Typography>

          <br></br>
          <PhoneCodeVerification
            phone={phone}
            codeVerifiedText="Code verified! Saving new phone number."
            invalidCodeText="Code invalid. Check to make sure you entered correctly or go back and verify you entered the correct number"
            onVerified={onVerified}
          />
        </>
      }
      buttons={[
        {
          text: 'Call me for my code',
          onClick: sendCallCode,
          disabled: verificationCodeSent,
        },
        {
          text: 'Text me my code',
          onClick: sendTextCode,
          disabled: verificationCodeSent,
        },
      ]}
    />
  );
};
