import {IconButton, Close as CloseIcon} from 'components/shared';
import { createStyles, Theme, withStyles } from '@material-ui/core';
import { StyleRules, WithStyles } from '@material-ui/core/styles/withStyles';

interface CloseButtonProps extends WithStyles<typeof styles> {
    onClose: () => void;
}

export const styles = (theme: Theme): StyleRules => createStyles({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
});

export const CloseButton = withStyles(styles)((props: CloseButtonProps) => {
    return (
        <IconButton aria-label="close" className={props.classes.closeButton} onClick={props.onClose}>
            <CloseIcon />
        </IconButton>
    );
});
