import { ToastVariant, useToast } from 'hooks/useToast';
import { useCreateHighlighter } from 'hooks/api/highlighter';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthenticatedUser, SignUpAppState } from 'types/Authentication';
import { Customer } from 'types/Customer';
import { isValidForCustomerCreation } from 'utils/profileUtils';
import { Highlighter } from 'types/Highlighter';

interface UseProfile {
  existingCustomer?: Customer;
  user?: AuthenticatedUser;
  //TODO: remove this when accessToken is in context
  accessToken: string | null;
  useHlApi?: boolean;
}
// useProfile tries to create a customer in the highlighter DB if the Auth0 user exists, but the customer does not
// if the user does not exist, or it cannot create a customer, it redirects to /login
export const useProfile = ({ existingCustomer, user, accessToken }: UseProfile) => {
  const history = useHistory();
  const { showToast } = useToast();
  const { createHighlighter } = useCreateHighlighter();
  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
  //TODO: maybe customer goes in the user context too
  const [customer, setCustomer] = useState<Customer | undefined>(existingCustomer);

  const routerState = history?.location?.state as SignUpAppState;
  const actionType = routerState?.actionType;
  const referralCode = routerState?.params?.referralCode;
  const referralCodeId = routerState?.params?.referralCodeId;

  // TRUE if it has all of these params EXCEPT customer
  const shouldAttemptCustomerCreation = isValidForCustomerCreation({
    actionType,
    referralCode,
    referralCodeId,
    user,
    customer,
  });

  // if user exists, but customer does not, try to create a customer
  // if customer creation succeeds, fetch the customer
  // if customer creation fails, redirect to login

  // TODO I dont think this needs to be called in a use effect, once we are creating highlighters from highlighter api
  // we should revisit this and have this hook return this as a function. We have enough from the Profile component
  // to know if we need to create a customer or not.
  useEffect(() => {
    const createNewHighlighter = async () => {
      if (!user) return;
      setIsCreatingCustomer(true);
      
      try {
        // this mutation returns the customer object, so no need to have an additional
        // fetch to receive the new highlighter.        
        const highlighter:Highlighter = {
          firstName: user.given_name,
          lastName: user.family_name,
          email: user.email,
          signupReferralCode: referralCode,
        }
        const customer = await createHighlighter(highlighter);

        if (customer?.signupReferralCode) {
          // Remove signup state after create is successsful.
          history.push('/profile');
          setCustomer(customer);
        } else {
          history.replace('/login');
        }
      } catch (error) {
        showToast({
          variant: ToastVariant.Error,
          text: 'Could not create a customer, redirecting to the Login page',
        });
        history.replace('/login');
      } finally {
        setIsCreatingCustomer(false);
      }
    };

    if (accessToken && shouldAttemptCustomerCreation) {
      createNewHighlighter();
    }
    if (!user) {
      history.replace('/login');
    }
  }, [accessToken]);

  return {
    isCreatingCustomer,
    user,
    customer,
  };
};
