import React from 'react';
import { Typography } from '@material-ui/core';
import styles from './styles.module.scss';
import { Column } from 'components/Grid';

export interface WalkthroughCardProps {
    title: string;
    description: string;
    imgUrl: string;
}

const WalkthroughCard = (props: WalkthroughCardProps): JSX.Element => {
    return (
        <Column className={styles.walkthroughCard}>
            <img src={props.imgUrl} className={styles['item-image']} alt="product image" />
            <Typography variant="h6" paragraph>
                {props.title}
            </Typography>
            <Typography variant="body2" paragraph>
                {props.description}
            </Typography>
        </Column>
    );
};

export default WalkthroughCard;
