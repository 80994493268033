import { SWR_QUERY_KEY } from 'constants/api';
import { swrHighlighterApiFetcher } from 'data/services/RequestMethods';
import { ErrorToastWithContactUsLink, ToastVariant, useToast } from 'hooks/useToast';
import useSWR from 'swr';
import { HighlighterQuestion } from 'types/Questions';

const useGetHighlighterQuestions = () => {
  const { showToast } = useToast();
  const { data: questions, isLoading: isHlApiLoading } = useSWR<HighlighterQuestion[]>(
    SWR_QUERY_KEY.GetQuestionsHttp,
    (url) => swrHighlighterApiFetcher(url),
    {
      revalidateOnFocus: false,
      onError: (error) => {
        console.error(`error fetching highlighter ${error}`);
        showToast({
          text: <ErrorToastWithContactUsLink errorText={`Error fetching questions.`} />,
          variant: ToastVariant.Error,
        });
      },
    }
  );
  return { questions, isHlApiLoading };
};

export { useGetHighlighterQuestions };
