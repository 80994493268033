import React, { FC } from 'react';
import { Customer } from 'types/Customer';

import { CountrySelect } from 'components/Forms/CountrySelect';
import { StateSelect } from 'components/Forms/StateSelect';
import { Box, Grid, Input, Typography } from 'components/shared';

interface CustomerAddressInputsProps {
  customer?: Customer;
  addressOne: any;
  addressTwo: any;
  city: any;
  postalCode: any;
  country: any;
  state: any;
  loading?: boolean;
}

export const CustomerAddressInputs: FC<CustomerAddressInputsProps> = (
  props: CustomerAddressInputsProps
): JSX.Element => {
  const { addressOne, addressTwo, city, postalCode, country, state, loading } = props;
  const headerStatement =
    'Important: We do NOT ship to PO/CPO boxes, please provide a STREET address. We are currently ONLY operating in the United States. We do NOT ship to Hawaii, Alaska, Puerto Rico, or Canada.';
  return (
    <>
      <Typography variant="body1" color="error" style={{ marginBottom: 5 }}>
        {headerStatement}
      </Typography>
      <Box marginBottom="24px">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              id={'addressOne'}
              label={'Address Line 1'}
              variant={'outlined'}
              fullWidth
              value={addressOne.value}
              onChange={addressOne.onChange}
              onFocus={addressOne.onFocus}
              theme={addressOne.error ? 'error' : undefined}
              helperText={addressOne.helperText}
              InputProps={{ readOnly: loading }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              id={'addressTwo'}
              label={'Address Line 2'}
              variant={'outlined'}
              fullWidth
              value={addressTwo.value}
              onChange={addressTwo.onChange}
              onFocus={addressTwo.onFocus}
              theme={addressTwo.error ? 'error' : undefined}
              helperText={addressTwo.helperText}
              InputProps={{ readOnly: loading }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              id={'city'}
              label={'City'}
              variant={'outlined'}
              fullWidth
              value={city.value}
              onChange={city.onChange}
              onFocus={city.onFocus}
              theme={city.error ? 'error' : undefined}
              helperText={city.helperText}
              InputProps={{ readOnly: loading }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StateSelect
              id={'state'}
              label={'State/Province'}
              variant={'outlined'}
              fullWidth
              value={state.value}
              onChange={state.onChange}
              onFocus={state.onFocus}
              theme={state.error ? 'error' : undefined}
              helperText={state.helperText}
              InputProps={{ readOnly: loading }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              id={'postal'}
              label={'Postal Code'}
              variant={'outlined'}
              fullWidth
              value={postalCode.value}
              onChange={postalCode.onChange}
              onFocus={postalCode.onFocus}
              theme={postalCode.error ? 'error' : undefined}
              helperText={postalCode.helperText}
              InputProps={{ readOnly: loading }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CountrySelect
              id={'country'}
              label={'Country'}
              variant={'outlined'}
              fullWidth
              value={country.value}
              onChange={country.onChange}
              onFocus={country.onFocus}
              theme={country.error ? 'error' : undefined}
              helperText={country.helperText}
              InputProps={{ readOnly: loading }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
