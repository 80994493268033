import type { FontFace } from 'csstype';

/* work-sans-400italic - latin */
export const WorkSans400Italic: FontFace = {
    fontFamily: 'Work Sans',
    fontStyle: 'italic',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('Work Sans Regular'),
    local('Work Sans-Regular'),
    url(/fonts/WorkSans-Italic-VariableFont_wght.ttf) format('truetype')`,
};

/* work-sans-500italic - latin */
export const WorkSans500Italic: FontFace = {
    fontFamily: 'Work Sans',
    fontStyle: 'italic',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
    local('Work Sans Medium'),
    local('Work Sans-Medium'),
    url(/fonts/WorkSans-Italic-VariableFont_wght.ttf) format('truetype')`,
};

/* work-sans-600italic - latin */
export const WorkSans600Italic: FontFace = {
    fontFamily: 'Work Sans',
    fontStyle: 'italic',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `
    local('Work Sans SemiBold'),
    local('Work Sans-SemiBold'),
    url(/fonts/WorkSans-Italic-VariableFont_wght.ttf) format('truetype')`,
};