import { ChangeEvent, useState } from 'react';

export const useInput = (
    initialValue = '',
    inputValidation: Validation = { required: { value: true, message: 'Required' } },
): any => {
    const [initialPassedValue, setInitialPassedValue] = useState<string>(initialValue);
    const [value, setValue] = useState<string>(initialValue);
    const [error, setError] = useState<boolean>(false);
    const [touched, setTouched] = useState<boolean>(false);
    const [helperText, setHelperText] = useState<string>('');
    const [validation, setValidation] = useState<any>(inputValidation);

    const onFocus = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (!touched && event) {
            setTouched(true);
        }
    };

    const validateInput = (): boolean => {
        if (validation) {
            let valid = true;
            let newErrors = '';

            // validate a required input
            if (validation?.required?.value && !value) {
                valid = false;
                newErrors += validation?.required?.message;
            }
            // skip other checks if input has no value
            if (valid) {
                // validate input value
                const pattern = validation?.pattern;
                if (pattern?.value && !RegExp(pattern.value).test(value)) {
                    valid = false;
                    newErrors += pattern.message;
                }
                // custom check (passed in from user)
                const custom = validation?.custom;
                if (custom?.isValid && !custom.isValid(value)) {
                    valid = false;
                    newErrors += custom.message;
                }
            }

            setError(!valid);
            setHelperText(newErrors);
            return valid;
        }

        // assume no validation = always valid input
        return true;
    };

    const hasChanged = initialPassedValue !== value;

    return {
        value,
        setValue,
        error,
        setError,
        validateInput,
        setValidation,
        helperText,
        setHelperText,
        touched,
        onFocus,
        hasChanged,
        setInitialValue: setInitialPassedValue,
        onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => setValue(event.target.value),
    };
};

// types
/**
 * validations and error state messages
 * example: \n
 * const validations = {
 *     postalCode: {
 *         required: { value: true, message: 'Required ' },
 *         pattern: { value: '/^(?:d{5}(?:[-]d{4})?|d{9})$/', message: 'Enter a valid Postal Code' },
 *     },
 * };
 */
export interface Validation {
    required?:
        | {
              value: true;
              message: string;
          }
        | {
              value: false;
          };
    pattern?: {
        value: RegExp;
        message: string;
    };
    custom?: {
        isValid: (value: string) => boolean;
        message: string;
    };
}
export type ErrorRecord<T> = Record<keyof T, string>;
export type Validations<T extends {}> = Partial<Record<keyof T, Validation>>;

export type UseInputProps = typeof useInput;
