import moment from 'moment';
import React, { FunctionComponent } from 'react';

import type { DatePickerOnChange } from 'components/shared';
import { KeyboardDatePicker } from 'components/shared';

const START_YEAR = 1902; // date picker will go back to UNIX time stamp start
const AGE_TO_REGISTER = 18; // must be 16 years or older to register

const HELPER_TEXT = {
  empty: ' ', // using space so helperText height doesnt collapse
  ageError: 'Sorry, you must be 18 to join Highlight',
  minYearError: 'Sorry, you must select a year over 1901',
};

const BirthdatePicker: FunctionComponent<BirthdatePickerProps> = (
  props: BirthdatePickerProps
): JSX.Element => {
  const startOfRange = moment().set('year', START_YEAR);
  const endOfRange = moment().subtract(AGE_TO_REGISTER, 'year');
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    props?.initialDate ? props.initialDate : null
  );
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState(HELPER_TEXT.empty);

  const _onChange = (date: DatePickerOnChange): void => {
    console.log(`const _onChange = (date: DatePickerOnChange) => `, {
      date,
      toDate: date?.toDate(),
    });
    // user is editting date but hasn't completed filling in
    // required digits (ie 02/12/20__ is missing last digits)
    // @ts-ignore
    if (date?.isValid() === false) {
      console.log(`user is editting date but hasn't completed filling in`);
      setHelperText(HELPER_TEXT.empty);
      setError(false);
      props.onChange(null);
    } else if (date) {
      // user has entered / selected a full date
      const afterStartDate = date.isSameOrAfter(startOfRange);
      const beforeEndDate = date.isBefore(endOfRange);

      // update helpertext / error state
      if (!beforeEndDate) {
        setHelperText(HELPER_TEXT.ageError);
        setError(true);
      } else if (!afterStartDate) {
        setHelperText(HELPER_TEXT.minYearError);
        setError(true);
      } else {
        // the birthdate is within our accepted range
        setHelperText(HELPER_TEXT.empty);
        setError(false);
        props.onChange(date.toDate());
      }

      // update comp state
      setSelectedDate(date.toDate());
      console.log({
        date,
        afterStartDate,
        beforeEndDate,
      });
    }
  };

  return (
    <KeyboardDatePicker
      id={props.id}
      format={'MM/DD/YYYY'}
      value={selectedDate}
      onChange={_onChange}
      helperText={props.helperText || helperText}
      error={props.error || error}
      minDate={startOfRange}
      maxDate={endOfRange}
      placeholder={'MM/DD/YYYY'}
      onBlur={props.onBlur}
    />
  );
};

export type BirthdatePickerProps = {
  id: string;
  initialDate?: Date;
  onChange: (date: Date | null) => Date | void;
  onBlur?: () => void;
  label?: string;
  helperText?: string;
  error?: boolean;
  minDate?: Date;
  maxDate?: Date;
};

export default BirthdatePicker;
