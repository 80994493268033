import React from 'react';

import { BaseEmptyState, HowToVoteSharp, Typography } from 'components/shared';

const bodyText = `Congrats, you're all caught up! Sit back and relax - there will be more Opt-Ins coming your way. `;

const NoActiveOffers = (): JSX.Element => {
  return (
    <BaseEmptyState
      icon={<HowToVoteSharp fontSize="inherit" color="inherit" />}
      title={<Typography variant="h5">{'Nothing here?'}</Typography>}
      body={<Typography variant="body1">{bodyText}</Typography>}
    />
  );
};

export default NoActiveOffers;
