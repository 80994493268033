import { useEffect } from 'react';
import { AuthenticationHandler } from './useAuth';
import { useGoogleLoginHandler } from './useGoogleLoginHandler';
import { GoogleLoginSuccess, GoogleLoginFailure } from 'components/login/GoogleLoginButton/GoogleLoginEventHandlers';

export const useGoogleLoginListener = (authenticate:AuthenticationHandler) => {
  const { handleSuccess, handleFailure } = useGoogleLoginHandler(authenticate);

  useEffect(() => {
    const listener = async (event:MessageEvent<GoogleLoginSuccess|GoogleLoginFailure>) => {
      if (!event.data.context) return;  // ignore if no context, it's not a message we sent from the iframe

      if (event.data.success) {
        await handleSuccess(event.data as GoogleLoginSuccess);
      }
      else {
        handleFailure(event.data as GoogleLoginFailure);
      }      
    }

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, []);  
}
