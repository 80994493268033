import deliveryTruckImage from 'assets/images/walkthrough/Delivery truck logo.png';
import highlightBoxImage from 'assets/images/walkthrough/Highlight Icon Box Open.png';
import greenPortalImage from 'assets/images/walkthrough/Highlight Portal Icons-01.png';
import orangeEmailImage from 'assets/images/walkthrough/Orange email notification.png';

import { WalkthroughCardProps } from './WalkthroughCard';

export const cardData: WalkthroughCardProps[] = [
  {
    title: 'Receive an Opt-In',
    description:
      'We’ll send you an email notification when we match you to an Opt-In we think you’ll be perfect for.',
    imgUrl: orangeEmailImage,
  },
  {
    title: 'Opt-in (or not!)',
    description:
      'In your Opt-In section, review the Opt-In to see if it’s something you’re interested in. If so, click in and answer a few questions to see if you qualify!',
    imgUrl: greenPortalImage,
  },
  {
    title: 'Get Set...',
    description:
      'If you’re selected and you need to try a product, wait for it to be delivered to your door! (We’ll let you know when it’s on the way)',
    imgUrl: deliveryTruckImage,
  },
  {
    title: 'Go! The clock’s ticking!',
    description:
      'Check out your Feedback section and make sure to complete any tasks before the deadlines hit to stay eligible for future Opt-Ins.',
    imgUrl: highlightBoxImage,
  },
];
