import { Paper } from '@material-ui/core';
import { ReactComponent as DisabledGoogleSvg } from 'assets/images/disabled-google.svg';
import { ReactComponent as GoogleSvg } from 'assets/images/google.svg';
import { APP_CONSTANTS } from 'constants/AppConstants';
import React, { useState } from 'react';

import { RefCodeInput } from 'components/login/RefCodeInput';
import { UserAgreementModal } from 'components/login/UserAgreementModal';
import { useReferralCode } from 'components/login/useReferralCode';
import {
  Box,
  Button,
  ErrorOutlineTwoTone,
  FormHelperText,
  Link,
  SvgIcon,
  Typography,
} from 'components/shared';

interface SignupCardProps {
  loginError: boolean;
  isSignupDisabled?: boolean;
}

export const SignupCard = (props: SignupCardProps) => {
  const { loginError, isSignupDisabled } = props;
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const { refCodeInputProps, referralCode } = useReferralCode();

  const handleSubmit = () => {
    if (refCodeInputProps.validReferralCode) {
      setModalIsVisible(true);
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        flex={1}
        data-testid="signup-card"
      >
        {isSignupDisabled ? (
          <Paper
            style={{
              padding: 25,
              marginTop: 20,
              marginBottom: 20,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              color: '#FF5A01',
              border: '1px solid #FF5A01',
              minHeight: '250px',
            }}
            elevation={4}
          >
            <Box display="flex" alignItems="flex-start" minWidth="325px">
              <ErrorOutlineTwoTone
                fontSize="large"
                color="error"
                style={{ marginRight: '6px' }}
              />
              <Typography variant="h6">
                Thank you for your interest in becoming a Highlighter! Sign-up is
                currently disabled. Please check back later!
              </Typography>
            </Box>
          </Paper>
        ) : (
          <>
            <Box marginBottom="32px">
              <Typography variant="h4">Become a Highlighter!</Typography>
            </Box>

            <Box marginBottom="32px">
              <Typography variant="body2">
                {
                  "You'll need an invite code from a current member to join. Don't have an invite? Fill out "
                }
                <Link href={APP_CONSTANTS.APPLY_TO_JOIN_LINK}>this form</Link>
                {" to get on our waitlist and we'll review your application."}
              </Typography>
            </Box>

            <Box marginBottom="24px">
              {loginError && (
                <Box
                  marginBottom="16px"
                  width="100%"
                  maxWidth="262px"
                  data-testid="login-error-message"
                >
                  <FormHelperText error>
                    {
                      'We could not find an account associated with that email. Please try again, or use a valid referral code to create an account'
                    }
                  </FormHelperText>
                </Box>
              )}
              <RefCodeInput {...refCodeInputProps} onEnterKeyPress={handleSubmit} />
            </Box>

            <Box marginBottom="24px">
              <Typography variant="caption">
                {'By creating an account you agree to our '}
                <Link href={APP_CONSTANTS.PRIVACY_POLICY_LINK} target="_blank">
                  Privacy Policy
                </Link>
                {' and '}
                <Link href={APP_CONSTANTS.TOS_LINK} target="_blank">
                  Terms of Service
                </Link>
              </Typography>
            </Box>

            <Box marginBottom="32px">
              <Button
                data-testid="signup-card-google-button"
                startIcon={
                  <SvgIcon
                    component={
                      refCodeInputProps.validReferralCode ? GoogleSvg : DisabledGoogleSvg
                    }
                  />
                }
                variant="outlined"
                color="default"
                disabled={!refCodeInputProps.validReferralCode}
                onClick={() => setModalIsVisible(true)}
              >
                Sign up with Google
              </Button>
            </Box>
          </>
        )}
      </Box>

      <UserAgreementModal
        isVisible={modalIsVisible}
        onClose={() => setModalIsVisible(false)}
        referralCode={referralCode}
      />
    </>
  );
};
