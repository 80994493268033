import { useFeatureFlag } from "configcat-react";

export type useMaintenanceWindowReturn = {
  isMaintenanceLoading: boolean,
} & ({
  isMaintenance: false,
} | {
  isMaintenance: true,
  maintenanceStartTime: Date,
  maintenanceEndTime: Date,
})

export const useMaintenanceWindow = (): useMaintenanceWindowReturn => {
  const { loading, value } = useFeatureFlag('maintenanceWindow', "");
  const splitValue = value.trim().split(',');
  if (!value || splitValue.length !== 2) {
    return {
      isMaintenanceLoading: loading,
      isMaintenance: false,
    }
  }

  const [startDate, endDate] = splitValue;

  const maintenanceStartTime = new Date(startDate);
  const maintenanceEndTime = new Date(endDate);

  const currentDateTime = new Date();
  const inMaintenanceWindow = currentDateTime >= maintenanceStartTime && currentDateTime <= maintenanceEndTime;
  if (!inMaintenanceWindow) {
    return {
      isMaintenanceLoading: loading,
      isMaintenance: false,
    }
  }

  const isMaintenance = true;
  return {
    isMaintenanceLoading: loading,
    isMaintenance,
    maintenanceStartTime,
    maintenanceEndTime,
  };
}
