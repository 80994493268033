import { Theme, createTheme } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';
import { Typography } from '@material-ui/core/styles/createTypography';
import { Overrides } from '@material-ui/core/styles/overrides';
import { Recoleta500Normal } from 'assets/fonts/Recoleta-Normal';
import {
  WorkSans400Italic,
  WorkSans500Italic,
  WorkSans600Italic,
} from 'assets/fonts/Work-Sans-Italic';
import {
  WorkSans400Normal,
  WorkSans500Normal,
  WorkSans600Normal,
} from 'assets/fonts/Work-Sans-Normal';

//INFO: This is migrated directly from showcase-ui. We should clean this up.
// Add new theme definitions here
const baseTheme = createTheme({
  palette: createPalette({
    type: 'light',
    primary: {
      main: '#000000',
      dark: '#616161',
      light: '#757575',
      contrastText: '#F5F0F0',
    },
    secondary: {
      main: '#65CBCC',
      light: '#A7EFE3',
      dark: '#057EB6',
      contrastText: '#433D3D',
    },
    info: {
      light: '#A7EFE3',
      main: '#65CBCC',
      dark: '#057EB6',
      contrastText: '#433D3D',
    },
    error: { light: '#FFAF66', main: '#D62E00', dark: '#B72900', contrastText: '#F5F4F4' },
    warning: {
      light: '#FFD966',
      main: '#FFB100',
      dark: '#B77300',
      contrastText: '#433D3D',
    },
    success: { light: '#90EF79', main: '#28CC24', dark: '#129225', contrastText: '#433D3D' },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.65)',
      disabled: 'rgba(255, 255, 255, 0.38)',
      hint: 'rgba(255, 255, 255, 0.38)',
    },
    background: { paper: '#fff', default: '#fafafa' },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.2,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  }),
});
const typography: Typography = {
  ...baseTheme.typography,
  fontFamily: ['Recoleta', 'Work Sans', 'sans-serif'].join(','),
  fontSize: 16,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: '"Recoleta", sans-serif',
    fontWeight: 500,
    fontSize: '5rem',
    lineHeight: 1.167,
    letterSpacing: '-0.0625em',
  },
  h2: {
    fontFamily: '"Recoleta", sans-serif',
    fontWeight: 500,
    fontSize: '4rem',
    lineHeight: 1.2,
    letterSpacing: '-0.0625em',
  },
  h3: {
    fontFamily: '"Recoleta", sans-serif',
    fontWeight: 500,
    fontSize: '3rem',
    lineHeight: 1.167,
    letterSpacing: '-0.01875em',
  },
  h4: {
    fontFamily: '"Recoleta", sans-serif',
    fontWeight: 500,
    fontSize: '2.225rem',
    lineHeight: 1.235,
    letterSpacing: '0em',
  },
  h5: {
    fontFamily: '"Work Sans", sans-serif',
    fontWeight: 600,
    fontSize: '1.5625rem',
    lineHeight: 1.334,
    letterSpacing: '-0.01875em',
  },
  h6: {
    fontFamily: '"Work Sans", sans-serif',
    fontWeight: 600,
    fontSize: '1.2rem',
    lineHeight: 1.6,
    letterSpacing: '0.009375em',
  },
  subtitle1: {
    fontFamily: '"Work Sans", sans-serif',
    fontWeight: 500,
    fontStyle: 'italic',
    fontSize: '1.2rem',
    lineHeight: 1.75,
    letterSpacing: '0em',
  },
  subtitle2: {
    fontFamily: '"Work Sans", sans-serif',
    fontWeight: 500,
    fontSize: '1.2rem',
    lineHeight: 1.57,
    letterSpacing: '0.00625em',
  },
  body1: {
    fontFamily: '"Work Sans", sans-serif',
    fontWeight: 400,
    fontSize: '1.1rem',
    lineHeight: 1.5,
    letterSpacing: '0em',
  },
  body2: {
    fontFamily: '"Work Sans", sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.4,
    letterSpacing: '0em',
  },
  button: {
    fontFamily: '"Work Sans", sans-serif',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 1.75,
    letterSpacing: '0.03125em',
    textTransform: 'uppercase',
  },
  caption: {
    fontFamily: '"Work Sans", sans-serif',
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: '0.875rem',
    lineHeight: 1.66,
    letterSpacing: '0.0125em',
  },
  overline: {
    fontFamily: '"Work Sans", sans-serif',
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: 2.66,
    letterSpacing: '0.03125em',
    textTransform: 'uppercase',
  },
};

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      html: {
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      root: {
        height: '100%',
        width: '100%',
      },
      '@font-face': [
        Recoleta500Normal,
        WorkSans400Normal,
        WorkSans500Normal,
        WorkSans600Normal,
        WorkSans400Italic,
        WorkSans500Italic,
        WorkSans600Italic,
      ],
    },
  },
  MuiPaper: {
    root: {
      background: 'transparent',
    },
  },
  MuiButton: {
    root: {
      borderRadius: 0,
      fontWeight: 600,
      padding: baseTheme.spacing(1, 3),
      color: baseTheme.palette.common.black,
      textTransform: 'none',
    },
    contained: {
      backgroundColor: baseTheme.palette.action.disabledBackground,
    },
    outlined: {},
    containedPrimary: {
      backgroundColor: baseTheme.palette.primary.main,
      color: baseTheme.palette.primary.contrastText,
    },
    outlinedPrimary: {
      color: baseTheme.palette.primary.main,
      borderColor: baseTheme.palette.primary.main,
      padding: `${baseTheme.spacing(1) - 1}px ${baseTheme.spacing(3)}px`,
      borderWidth: '1px',
      borderStyle: 'solid',
      boxSizing: 'border-box',
      marginRight: 0,
    },
    disabled: {
      backgroundColor: baseTheme.palette.action.disabledBackground,
      color: baseTheme.palette.action.disabled,
    },
  },
  MuiAppBar: {
    colorDefault: {
      backgroundColor: baseTheme.palette.primary.main,
      padding: baseTheme.spacing(4, 6),
      maxHeight: baseTheme.spacing(13),
    },
  },
  MuiLink: {
    root: {
      fontWeight: 600,
      fontSize: '1rem',
      cursor: 'pointer',
    },
  },
  MuiInputLabel: {
    root: {
      fontFamily: '"Work Sans", sans-serif',
      backgroundColor: '#ffffff', // for label placement over border
      paddingRight: 6, // for label placement over border
    },
    outlined: {
      top: '-10px',
      color: baseTheme.palette.text.primary,
      '&$focused': {
        color: baseTheme.palette.text.primary,
      },
      '&$disabled': {
        color: baseTheme.palette.grey.A700,
      },
      '&$error': {
        color: baseTheme.palette.error.main,
      },
    },
    shrink: {
      top: '0px !important', // for label vert alignment
      textTransform: 'uppercase',
      color: baseTheme.palette.text.primary,
      '&$focused': {
        color: baseTheme.palette.text.primary,
      },
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: '0.75rem',
      fontFamily: '"Work Sans", sans-serif',
    },
  },
  MuiOutlinedInput: {
    root: {
      padding: baseTheme.spacing(0.5),
      borderRadius: 0,
      position: 'relative',
      '& $input': {
        padding: baseTheme.spacing(0.5),
      },
      '& $notchedOutline': {},
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {},
      '&$focused $notchedOutline': {
        borderColor: baseTheme.palette.common.black,
        borderWidth: 2,
      },
      '&$error $notchedOutline': {
        borderColor: baseTheme.palette.error.main,
      },
      '&$disabled $notchedOutline': {
        borderColor: baseTheme.palette.action.disabled,
      },
    },
    /* Styles applied to the root element if the component is focused. */
    focused: {},
    /* Styles applied to the root element if `disabled={true}`. */
    disabled: {},
    /* Styles applied to the root element if `error={true}`. */
    error: {},
    /* Styles applied to the `NotchedOutline` element. */
    notchedOutline: {},
  },
  MuiBadge: {
    badge: {
      backgroundColor: baseTheme.palette.error.main,
      color: baseTheme.palette.error.contrastText,
    },
  },
  MuiTabs: {
    root: { marginBottom: 25 },
    indicator: {
      height: '3px',
    },
  },
};

const ShowcaseTheme: Theme = {
  ...baseTheme,
  typography,
  overrides,
};

ShowcaseTheme.props = {
  MuiButton: {
    disableElevation: true,
  },
};

export default ShowcaseTheme;
