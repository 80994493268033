import React from 'react';
import history from './utils/History';
import { Auth0Provider } from '@auth0/auth0-react';
import { AppState } from '@auth0/auth0-react/dist/auth0-provider';
import { APP_CONSTANTS } from 'constants/AppConstants';
import { SignUpAppState } from 'types/Authentication';

const Auth0ProviderWithHistory = ({ children }: JSX.ElementChildrenAttribute): JSX.Element => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN ? encodeURI(process.env.REACT_APP_AUTH0_DOMAIN) : '';
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ? encodeURI(process.env.REACT_APP_AUTH0_CLIENT_ID) : '';
    const audience = process.env.REACT_APP_AUDIENCE ? encodeURI(process.env.REACT_APP_AUDIENCE) : '';

    const onRedirectCallback = async (appState?: AppState): Promise<void> => {
        // this action will complete before user object in app.tsx will be ready
        try {
            console.log('const onRedirectCallback = (appState): ', appState);
            const userFirstLogin = appState?.actionType === APP_CONSTANTS.ONBOARDING.APP_STATE_SIGNUP_VALUE;
            if (userFirstLogin) {
                // pass signupState so we can access in our profile route
                const signUpAppState: SignUpAppState = appState as SignUpAppState;
                history.push('/profile', { ...signUpAppState });
            } else {
                history.push('/home');
            }
        } catch (error) {
            console.log(error);
            history.push('/login');
        }
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            audience={audience}
            scope={'query:all'}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
