import PhoneInput from 'components/Forms/PhoneInput';
import { APP_CONSTANTS } from 'constants/AppConstants';
import { useIsPhoneAvailable, useSendPhoneVerificationCode } from 'hooks/api/phone';
import { ChangeEvent, useEffect, useState } from 'react';
import { debounce } from 'utils/debounce';
import { localStorage } from 'utils/localStorage';
import { applyMaskToPhone, getPhoneFromMaskedValue } from 'utils/phoneHelpers';

import { Box, Button, Typography } from 'components/shared';

import SectionHeader from './SectionHeader';
import { StepProps } from './types';

const MESSAGES = {
  PHONE_TAKEN: 'This phone number is already associated with an account',
  CODE_SENT: 'Your verification code will arrive shortly',
  CODE_ISSUE:
    'We were unable to send you a code, please confirm your number and try again',
};

export const PhoneVerification = (props: StepProps): JSX.Element => {
  const [phone, setPhone] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneAvailableForUse, setPhoneAvailableForUse] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>('');
  const { isPhoneAvailable } = useIsPhoneAvailable();
  const { sendPhoneCode } = useSendPhoneVerificationCode();

  useEffect(() => {
    // should only run when component first loaded
    const phone = localStorage.getItem(APP_CONSTANTS.ONBOARDING.PHONE_STORAGE_KEY);
    if (phone) {
      const maskedNumber = applyMaskToPhone(phone);
      setPhone(maskedNumber ? maskedNumber : '');
      verifyPhone();
    }
  }, []);

  useEffect(() => verifyPhone(), [phone]);

  const verifyPhone = debounce(() => {
    // don't pass channel type bc we're only checking if
    // the phone number is valid format (local) and not in use (db row)
    checkPhone();
  }, 300);

  const phoneNumberisAvailable = async (phoneNumber: string): Promise<boolean> => {
    try {
      const phonesAvailableResp = await isPhoneAvailable(phoneNumber);
      return phonesAvailableResp.isAvailable;
    } catch (e) {
      return false;
    }
  };

  const checkPhone = async (): Promise<void> => {
    if (error) {
      setError(false);
    }

    // check if number in form input is valid format
    const phoneNumber = getPhoneFromMaskedValue(phone);
    if (phoneNumber) {
      setLoading(true);

      // confirm number is available for use in our db
      const phoneAvailable = await phoneNumberisAvailable(phoneNumber);
      if (phoneAvailable) {
        // enable buttons to let user pick text or voice for phone confirmation
        setHelperText('');
        setPhoneAvailableForUse(true);
        setLoading(false);
      } else {
        setHelperText(MESSAGES.PHONE_TAKEN);
        setError(true);
        setLoading(false);
      }
    } else {
      setPhoneAvailableForUse(false);
      setLoading(false);
    }
  };

  const sendCode = async (channel: 'sms' | 'call'): Promise<void> => {
    if (!phoneAvailableForUse) {
      return;
    }
    // the phone is available and we sent a code, move to next step

    const phoneNumber = getPhoneFromMaskedValue(phone);
    if (!phoneNumber) {
      setPhoneAvailableForUse(false);
      return;
    }

    // send the sms code
    setLoading(true);
    try {
      const sendCodeResult = await sendPhoneCode(phoneNumber, channel)

      const messageText = sendCodeResult.ok ? MESSAGES.CODE_SENT : MESSAGES.CODE_ISSUE;

      if (!sendCodeResult.ok) {
        setHelperText(messageText);
        setLoading(false);
        return;
      }

      // add to local storage for next step
      localStorage.setItem(APP_CONSTANTS.ONBOARDING.PHONE_STORAGE_KEY, phoneNumber);
      // pass phone to next section for api use
      props.nextStep();
    } catch {
      setHelperText(MESSAGES.CODE_ISSUE)
      setLoading(false);
      return
    }
  };

  const sendSmsCode = async (): Promise<void> => sendCode('sms');

  const sendVoiceCode = async (): Promise<void> => sendCode('call');

  const onChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setPhone(event.target.value);

  return (
    <>
      <SectionHeader
        title={`Hello, ${props?.user?.given_name}!`}
        body={
          'Please take a moment to verify your phone number. This helps us confirm your identity and keep your account secure.'
        }
      />
      <Box
        style={{
          marginBottom: '24px',
        }}
      >
        <PhoneInput
          value={phone}
          onChange={onChange}
          id={'phone'}
          label={'PHONE NUMBER'}
          placeholder={'(___)___-____'}
          variant={'outlined'}
          helperText={
            helperText || 'We will send a one-time verification code to this number'
          }
          loading={loading}
          error={error}
          success={phoneAvailableForUse}
          autoFocus
        />
      </Box>
      <Box
        style={{
          marginBottom: '16px',
          display: 'flex',
          flexDirection: 'row',
          flex: '1 1 100%',
          width: '100%',
          maxWidth: '100%',
        }}
      >
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flexStart',
            marginTop: '15px',
          }}
        >
          <Box marginRight="16px">
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={sendSmsCode}
              disabled={!phoneAvailableForUse || loading}
            >
              Text Me
            </Button>
          </Box>
          <Button
            variant={'outlined'}
            color={'primary'}
            onClick={sendVoiceCode}
            disabled={!phoneAvailableForUse || loading}
          >
            Call Me
          </Button>
        </Box>
      </Box>
      <Typography variant={'caption'}>Message and data rates may apply.</Typography>
    </>
  );
};

export default PhoneVerification;
