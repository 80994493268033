import React from 'react';
import { GridContainerProps } from './types';
import { getBorderStyle } from './utils';
import styles from './styles.module.scss';

const GridContainer = ({
    borderBottom,
    borderTop,
    borderLeft,
    borderRight,
    children,
    style,
    onClick,
}: GridContainerProps): JSX.Element => {
    const classes =
        // 'container' +
        styles.container + getBorderStyle(borderTop, borderBottom, borderLeft, borderRight);
    return (
        <div style={style} className={classes} onClick={onClick}>
            {children}
        </div>
    );
};

export default GridContainer;
