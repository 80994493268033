import { useVerifyPhoneCode } from 'hooks/api/phone';
import { ChangeEvent, useEffect, useState } from 'react';
import { debounce } from 'utils/debounce';

import CodeInput from 'components/Forms/CodeInput';
import { Box } from 'components/shared';

interface PhoneCodeVerificationInputProps {
  phone: string;
  codeVerifiedText: string;
  invalidCodeText: string;
  onVerified: () => void;
}
export const PhoneCodeVerificationInput = (
  props: PhoneCodeVerificationInputProps
): JSX.Element => {
  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [codeIsValid, setCodeIsValid] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>('');
  const { verifyPhoneCode } = useVerifyPhoneCode();

  useEffect(() => {
    if (props.phone) {
      debounceCode();
    }
  }, [code]);

  const debounceCode = debounce(async () => {
    if (code?.length > 5) {
      await validate();
    }
  }, 300);

  const validate = async (): Promise<void> => {
    setError(false);
    setLoading(true);
    try {
      const response = await verifyPhoneCode(props.phone, code);

      if (response.ok) {
        setHelperText(props.codeVerifiedText);
        setCodeIsValid(true);
        setError(false);
        setTimeout(() => props.onVerified(), 500);
      } else if (response.status == 400) {
        setHelperText(props.invalidCodeText);
        setCodeIsValid(false);
        setError(true);
        setLoading(false);
      } else {
        setHelperText('There was an error with the request');
        setCodeIsValid(false);
        setError(true);
        setLoading(false);
        return;
      }
    } catch {
      setHelperText('There was an error with the request');
      setCodeIsValid(false);
      setError(true);
      setLoading(false);
      return;
    }
  };

  const onCodeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setCode(event.target.value);
  };

  return (
    <>
      <Box marginBottom="24px">
        <CodeInput
          value={code}
          onChange={onCodeChange}
          id={'verify-phone'}
          label={'VERIFICATION CODE'}
          variant={'outlined'}
          error={error}
          success={codeIsValid}
          loading={loading}
          helperText={helperText || 'Please enter your verification code here'}
          autoFocus
        />
      </Box>
    </>
  );
};

export default PhoneCodeVerificationInput;
