import { REFERRAL_CODE_MSGS } from '.';
import type { RefCodeInputTheme } from 'components/login/RefCodeInput';
import { ValidatePromoCodeResult } from 'data/services/api-types';

interface RefCodeInputValues {
  validReferralCode: boolean;
  referralCodeId: string;
  helperText: string;
  refCodeInputTheme: RefCodeInputTheme;
}

function getRefCodeInputValues(
  refCode: ValidatePromoCodeResult,
  code: string
): RefCodeInputValues {
  // Valid Referral Code
  if (refCode?.isValid) {
    return {
      validReferralCode: true,
      referralCodeId: code,
      helperText: REFERRAL_CODE_MSGS.success,
      refCodeInputTheme: 'success',
    };
  }

  return {
    validReferralCode: false,
    referralCodeId: '',
    refCodeInputTheme: 'error',
    helperText: REFERRAL_CODE_MSGS.error,
  };
}

export { getRefCodeInputValues };
