import { List, ListItem, useTheme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useUpdateAddresses } from 'hooks/api/addresses';
import { useCustomerAddress } from 'hooks/useCustomerAddress';
import { ToastVariant, useToast } from 'hooks/useToast';
import React, { useState } from 'react';
import { Address, Customer } from 'types/Customer';

import { CustomerAddressInputs } from 'components/AddressForm/CustomerAddressInputs';
import { AddressSelectionModal } from 'components/Modals/AddressSelectionModal';
import { Box, Button, Grid, Typography } from 'components/shared';

type MessageType = 'success' | 'error' | 'second-line-missing';

const mutationMessages: { [key in MessageType]: string } = {
  success: 'Address saved successfully',
  'second-line-missing':
    'The address is missing line 2 information (ie apt or suite number). Please update the address and try again.',
  error:
    'The address could not be validated.  Address validation returned the following errors.',
};

export type AddressSectionProps = {
  user: any;
  customer: Customer;
  refetchCustomer: () => void;
  onFormHasChanged?: (formHasChanged: boolean) => void;
  logError?: (section: 'Address' | 'Basic', error: string) => void;
};

const AddressSection = (props: AddressSectionProps): JSX.Element => {
  const { showToast } = useToast();
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<MessageType | undefined>(undefined);
  const [useSuggestedAddress, setUseSuggestedAddress] = useState(false);
  const [displayAddressSelectionModal, setDisplayAddressSelectionModal] =
    useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const { updateAddress: updateAddressHlApi } = useUpdateAddresses();
  const {
    inputs,
    suggestedAddress,
    isValidRecommendedAddress,
    formHasChanged,
    validateForm,
    updateInitialValuesToCurrentValues,
    validateAddress,
    setInputsFromAddress,
    getResponseDescriptions,
  } = useCustomerAddress(props);

  const _onSubmitForm = async (): Promise<void> => {
    setLoading(true);
    setMessageType(undefined);

    // Check if input is valid
    const inputsValid = validateForm();
    if (!inputsValid) {
      setLoading(false);
      return;
    }

    // Perform address validation
    const addressValidation = await validateAddress();
    if (!addressValidation?.isValid) {
      // If invalid, show the errors we got back
      if (addressValidation?.details) {
        setMessageType('error');
        const errors = getResponseDescriptions(addressValidation?.details);
        if (addressValidation.isPoBox) {
          errors.push('PO Boxes are not allowed');
        }
        if (!addressValidation.isValidState) {
          errors.push('The submitted state is not valid');
        }
        setErrors(errors);
      }
      setDisplayAddressSelectionModal(true);
      setLoading(false);
      return;
    }

    // Put together address and submit it
    const customerAddress =
      props.customer?.address instanceof Array
        ? props.customer.address?.[0]
        : (props.customer.address! as Address);
    const addressId = customerAddress.id;
    const address: Address = {
      id: addressId,
      lineOne: inputs.addressOne.value.trim(),
      lineTwo: inputs.addressTwo?.value?.length > 0 ? inputs.addressTwo.value.trim() : '',
      city: inputs.city.value.trim(),
      postalCode: inputs.postalCode.value.trim(),
      country: inputs.country.value.trim(),
      state: inputs.state.value.trim(),
    };
    const updateAddressResponse = await updateAddressHlApi(address);
    if (updateAddressResponse?.status == 200) {
      showToast({
        variant: ToastVariant.Success,
        text: mutationMessages.success,
      });
      setMessageType(undefined);
      // set cached data / hook state to current values
      updateInitialValuesToCurrentValues(); // so formHasChanged functions correctly on next use
      props.refetchCustomer(); // so state up the tree is in sync with the db
    } else {
      // show specific error if we can resolve the smarty streets analysis object to a specific error message
      // otherwise show a generic error message
      setMessageType('error');
    }

    setLoading(false);
  };

  React.useEffect(() => {
    // form has changed
    // console.log('formHasChanged', { formHasChanged });
    if (props.onFormHasChanged) {
      props.onFormHasChanged(formHasChanged);
    }
  }, [formHasChanged]);

  React.useEffect(() => {
    // user has selected to use the suggested address
    if (useSuggestedAddress) {
      _onSubmitForm();
      setUseSuggestedAddress(false);
    }
  }, [useSuggestedAddress]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        style={{ marginBottom: messageType === undefined ? 24 : 0 }}
      >
        <Grid item>
          <Typography variant="h5">{'Address'}</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color={'primary'}
            onClick={_onSubmitForm}
            endIcon={loading ? <CircularProgress size={20} /> : undefined}
            disabled={!formHasChanged || loading}
            id={'save-address-info-button'}
          >
            {loading ? 'Loading' : 'Save Address'}
          </Button>
        </Grid>
      </Grid>
      {messageType &&
      (messageType === 'error' || messageType === 'second-line-missing') ? (
        <Box marginBottom="24px">
          <Typography
            variant="body2"
            style={{ marginTop: 16, color: theme.palette.warning.main }}
          >
            {mutationMessages[messageType]}
          </Typography>
          <List style={{ listStyleType: 'disc', marginLeft: 32 }}>
            {errors.map((error) => (
              <ListItem
                key={error}
                style={{ display: 'list-item', color: theme.palette.warning.main }}
              >
                {error}
              </ListItem>
            ))}
          </List>
        </Box>
      ) : undefined}
      <Grid container spacing={3}>
        <Grid item>
          <CustomerAddressInputs customer={props.customer} {...inputs} />
        </Grid>
      </Grid>
      {suggestedAddress && (
        <AddressSelectionModal
          title={'Verify Address'}
          subtitle={`We think there's a problem with the address you entered. Please use our suggestion or edit your original address.`}
          open={displayAddressSelectionModal}
          userEnteredAddress={{
            addressOne: inputs.addressOne.value.trim(),
            addressTwo:
              inputs.addressTwo?.value?.length > 0
                ? inputs.addressTwo.value.trim()
                : undefined,
            city: inputs.city.value.trim(),
            postalCode: inputs.postalCode.value.trim(),
            country: inputs.country.value.trim(),
            state: inputs.state.value.trim(),
          }}
          suggestedAddress={suggestedAddress}
          isValidRecommendedAddress={isValidRecommendedAddress}
          onClose={() => {
            // ignore close request (ie click outside modal on overlay)
            // to force the user to select among the modal options
          }}
          onClickUseSuggestedAddress={() => {
            // manually set the browser inputs to the suggested address then
            //  fire the onSubmit again to continue with the add//update address op
            setInputsFromAddress(suggestedAddress);
            setUseSuggestedAddress(true);
            setDisplayAddressSelectionModal(false);
          }}
          onClickEditUserEnteredAddress={() => {
            setDisplayAddressSelectionModal(false);
          }}
          buttons={[]}
        />
      )}
    </>
  );
};
export default AddressSection;
