import React from 'react';
import { Link } from 'components/shared';
import { useHeaderStyles } from './Header.styles';
import { PrivateRouteName, PublicRouteName, getRouteName } from './headerUtils';

type RouteName = PublicRouteName | PrivateRouteName;

interface LinkWrapperProps<T extends RouteName> {
  routeName: T;
  onClick: (routeName: T) => void;
  isSmallFont?: boolean;
}

export const LinkWrapper = <T extends RouteName>({
  routeName,
  onClick,
  isSmallFont = false,
}: LinkWrapperProps<T>) => {
  const classes = useHeaderStyles();

  return (
    <Link
      id={`header-${routeName}`}
      className={isSmallFont ? '' : classes.largeLinkText}
      color="textSecondary"
      onClick={() => onClick(routeName)}
    >
      {getRouteName(routeName)}
    </Link>
  );
};
