import React from 'react';
import { Box, Link, Typography } from 'components/shared';
import { makeStyles } from '@material-ui/core/styles';
import { APP_CONSTANTS } from 'constants/AppConstants';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '14px',
    paddingBottom: '10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
    paddingLeft: `${theme.spacing(3.25)}px`,
    paddingRight: `${theme.spacing(1.81)}px`,
    [theme.breakpoints.up('md')]: {
      paddingLeft: `${theme.spacing(6.5)}px`,
      paddingRight: `${theme.spacing(6)}px`,
    },
  },
  textStyles: {
    color: theme.palette.primary.contrastText,
  },
  link: {
    fontWeight: 400,
  },
}));

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const classes = useStyles();

  return (
    <footer>
      <Box className={classes.container}>
        <Typography variant="body1" className={classes.textStyles}>
          &#169;{currentYear} Highlight. All Rights Reserved.
        </Typography>

        <Link
          className={classes.link}
          href={APP_CONSTANTS.PRIVACY_POLICY_LINK}
          target="_blank"
          color="textSecondary"
        >
          Privacy Policy
        </Link>

        <Link
          className={classes.link}
          href={APP_CONSTANTS.TOS_LINK}
          target="_blank"
          color="textSecondary"
        >
          Terms of Service
        </Link>

        <Link
          className={classes.link}
          href={APP_CONSTANTS.YPC_LINK}
          target="_blank"
          color="textSecondary"
        >
          Your Privacy Choices
        </Link>

        <Link
          className={classes.link}
          href={APP_CONSTANTS.CN_LINK}
          target="_blank"
          color="textSecondary"
        >
          Collection Notice
        </Link>
      </Box>
    </footer>
  );
};
