import React, { useState, ChangeEvent } from 'react';
import { InputAdornment } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Input, Spinner } from 'components/shared';
import { debounce } from 'utils/debounce';

export type RefCodeInputTheme =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'error'
  | 'warning'
  | 'success';

interface RefCodeInputProps {
  initialValue?: string;
  theme: RefCodeInputTheme;
  helperText: string;
  onChange: (value: string) => void;
  onEnterKeyPress?: () => void;
  loading: boolean;
  validReferralCode: boolean;
}

export const RefCodeInput = ({
  initialValue = '',
  onChange,
  onEnterKeyPress,
  theme,
  helperText,
  loading,
  validReferralCode,
}: RefCodeInputProps) => {
  const [code, setCode] = useState<string>(initialValue);

  const debouncedCode = debounce((value: string) => {
    onChange(value);
  }, 300);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setCode(event.target.value);
    debouncedCode(event.target.value);
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onEnterKeyPress?.();
    }
  };

  return (
    <Input
      id="referral-code"
      label="INVITE CODE"
      variant="outlined"
      value={code}
      onChange={handleChange}
      onKeyDown={handleEnterKeyPress}
      theme={theme}
      helperText={helperText}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {loading ? (
              <Spinner />
            ) : theme === 'error' ? (
              <ErrorOutlineIcon color="inherit" data-testid="error-icon" />
            ) : validReferralCode ? (
              <CheckCircleOutlineIcon color="inherit" data-testid="valid-icon" />
            ) : (
              <div />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};
