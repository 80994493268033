import React from 'react';
import Icon from '@material-ui/icons/RemoveCircleOutlineSharp';
import { Typography } from '@material-ui/core';
import { APP_CONSTANTS } from 'constants/AppConstants';
import { BaseEmptyState, Link } from 'components/shared';

const AccessRevoked = (): JSX.Element => {
  return (
    <BaseEmptyState
      icon={<Icon fontSize="inherit" color="inherit" />}
      title={<Typography variant="h5">{'Oh no!'}</Typography>}
      body={
        <Typography variant="body1">
          {`We hate to do this but your account was most likely locked due to incomplete offer assignments. Is this an error? `}
          <Link
            variant="body1"
            color="primary"
            href={APP_CONSTANTS.CONSUMER_CONTACT_FORM_LINK}
            target="_blank"
          >
            Contact us
          </Link>
          {` and we’ll get it straightened out.`}
        </Typography>
      }
    />
  );
};

export default AccessRevoked;
