import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Dialog as MuiDialog,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    padding: '1rem 1rem',
    textAlign: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    padding: '1rem 1rem',
    lineHeight: 0,
    color: theme.palette.text.primary,
  },
}));

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: React.ReactNode;
  content?: React.ReactNode;
}

export const Dialog = ({
  content,
  isOpen,
  onClose,
  onConfirm,
  title = 'Please confirm this action.',
}: DialogProps) => {
  const classes = useStyles();

  return (
    <MuiDialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box className={classes.title}>{title}</Box>
      </DialogTitle>

      <Divider />

      {content && (
        <DialogContent id="alert-dialog-description" className={classes.content}>
          {content}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};
