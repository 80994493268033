import { Theme, makeStyles, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';

import { Box, Link } from 'components/shared';

export enum ToastVariant {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    fontWeight: 800,
    fontSize: '1rem',
    wordWrap: 'break-word',
    width: '100%',
  },
}));

interface ToastProps {
  text: React.ReactNode;
  variant?: ToastVariant;
}

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const classes = useStyles();

  const getStylesByVariant = (variant: ToastVariant, theme: Theme) => {
    switch (variant) {
      case ToastVariant.Success:
        return {
          color: theme.palette.success.contrastText,
          backgroundColor: theme.palette.success.main,
        };
      case ToastVariant.Error:
        return {
          color: theme.palette.error.contrastText,
          backgroundColor: theme.palette.error.main,
        };
      case ToastVariant.Warning:
        return {
          color: theme.palette.warning.contrastText,
          backgroundColor: theme.palette.warning.main,
        };
      case ToastVariant.Info:
      default:
        return {
          color: theme.palette.info.contrastText,
          backgroundColor: theme.palette.info.main,
        };
    }
  };

  const showToast = ({ text, variant = ToastVariant.Info }: ToastProps) => {
    enqueueSnackbar(
      <Box component="span" className={classes.root}>
        {text}
      </Box>,
      {
        variant,
        style: getStylesByVariant(variant, theme),
      }
    );
  };

  return { showToast };
};

const ErrorToastWithContactUsLink = ({ errorText }: { errorText: string }) => {
  return (
    <span>
      {errorText}
      <br />
      Please{' '}
      <Link href="mailto:support@highlighters.letshighlight.com" target="_blank">
        click here
      </Link>{' '}
      to contact us so that we can help resolve this issue!
    </span>
  );
};

export { useToast, ErrorToastWithContactUsLink };
