import styles from './styles.module.scss';

function getBorderStyle(
    borderTop?: boolean,
    borderBottom?: boolean,
    borderLeft?: boolean,
    borderRight?: boolean
): string {
    return `${borderTop ? ' ' + styles.borderTop : ''
        }${borderBottom ? ' ' + styles.borderBottom : ''
        }${borderLeft ? ' ' + styles.borderLeft : ''
        }${borderRight ? ' ' + styles.borderRight : ''
        }`;
}

export { getBorderStyle }
