function getEnvFromWindow(options: { allowLocalHost?: boolean }): 'prod' | 'tst' | 'dev' | 'localhost' | undefined {
    try {
        if (window.location.host.split('.')?.[0]) {
            const env = window.location.host.split('.')[0];
            if (env) {
                if (env === 'app') {
                    return 'prod';
                } else if (env === 'tst') {
                    return 'tst';
                } else if (env === 'dev') {
                    return 'dev';
                } else if (options.allowLocalHost && env.split(':')[0] === 'localhost') {
                    return 'localhost';
                }
            }
        }
    } catch (error) {
        console.log(error);
    }

    return undefined;
}

export { getEnvFromWindow };
