import { QuestionAnswers } from 'types/Answer';
import { PossibleAnswer } from 'types/Questions';

export const getUpdatedAnswers = (
  answer: PossibleAnswer,
  currentAnswers: NonNullable<QuestionAnswers[string]>,
  possibleAnswers: PossibleAnswer[],
  allowMultiple: boolean
): NonNullable<QuestionAnswers[string]> => {
  const exclusiveAnswers = [
    'none of these',
    'none of the above',
    'prefer not to disclose',
  ];
  const exclusiveAnswerPreviouslySelected = possibleAnswers
    .map((a: PossibleAnswer) => {
      return (
        exclusiveAnswers.includes(a.description.toLocaleLowerCase()) &&
        currentAnswers[a.id]
      );
    })
    .includes(true);
  const shouldKeepOldAnswers =
    !exclusiveAnswers.includes(answer.description.toLocaleLowerCase()) &&
    allowMultiple &&
    !exclusiveAnswerPreviouslySelected;
  const updatedSelectedAnswers = {
    ...(shouldKeepOldAnswers ? currentAnswers : {}),
    [answer.id]: !currentAnswers[answer.id],
  };
  return updatedSelectedAnswers;
};

export const getIsDirtyValue = (
  updatedAnswers: NonNullable<QuestionAnswers[string]>,
  originalQuestionAnswers: QuestionAnswers[string]
): boolean => {
  const orig = originalQuestionAnswers ?? {};
  const newSet = new Set(
    Object.entries(updatedAnswers)
      .filter(([, v]) => !!v)
      .map(([k]) => k)
  );
  const origSet = new Set(
    Object.entries(orig)
      .filter(([, v]) => v)
      .map(([k]) => k)
  );
  const noAnswersSelected = newSet.size === 0;
  if (noAnswersSelected) {
    return false;
  }
  const isEqualSet =
    newSet.size === origSet.size && Array.from(newSet).every((x) => origSet.has(x));
  return !isEqualSet;
};
