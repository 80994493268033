// TODO: This is a copy of the component from showcase-ui
// Refactor this when we can
import {
  Box,
  PaperProps,
  Theme,
  Typography,
  TypographyProps,
  WithStyles,
  createStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

import { Button } from 'components/shared';

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: string;
  onClose: () => void;
}

interface ButtonProps {
  text: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'default' | 'inherit' | 'primary' | 'secondary';
  disabled?: boolean;
  'data-testid'?: string;
}

export interface DefaultModalProps {
  open: boolean;
  title: string | React.ReactNode;
  onClose: () => void;
  buttons: ButtonProps[];
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  scroll?: 'paper' | 'body' | undefined;
  body?: string | React.ReactNode;
  fullWidth?: boolean;
  fullScreen?: boolean;
}

const defaultPaperProps: Partial<PaperProps> = {
  // TODO see if we can delete the zindex
  style: { borderRadius: 0, padding: 24, zIndex: 2300 },
};

const defaultFullWidthPaperProps: Partial<PaperProps> = {
  // TODO see if we can delete the zindex
  style: { borderRadius: 0, padding: 24, maxWidth: 755, zIndex: 2300 },
};

const Title = (props: TypographyProps): JSX.Element => (
  <Typography {...props} variant={'h3'} />
);

const styles = (theme: Theme): StyleRules =>
  createStyles({
    closeButton: {
      marginRight: -1 * theme.spacing(2),
      marginTop: -1 * theme.spacing(2),
      float: 'right',
      color: theme.palette.grey[500],
    },
  });

export const StyledModal = withStyles({
  root: {
    // @ts-ignore
    // TODO check if we can delete this
    zIndex: '2201 !important',
  },
})(Dialog);

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <Box marginBottom="24px">
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <Title>{children}</Title>
    </Box>
  );
});

export const Modal = (props: DefaultModalProps): JSX.Element => {
  const _handleClose = (): void => props.onClose();

  return (
    <StyledModal
      onClose={_handleClose}
      open={props.open}
      PaperProps={props.fullWidth ? defaultFullWidthPaperProps : defaultPaperProps}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      scroll={props.scroll}
      fullScreen={props.fullScreen}
    >
      {typeof props.title === 'string' ? (
        <DialogTitle id={'customized-dialog-title'} onClose={_handleClose}>
          {props.title}
        </DialogTitle>
      ) : (
        props.title
      )}
      {typeof props.body === 'string' ? (
        <Box marginBottom="24px">
          <Typography variant={'body2'}>{props.body}</Typography>
        </Box>
      ) : (
        props.body
      )}
      <DialogActions>
        {props.buttons?.map((btn: ButtonProps) => (
          <Button
            key={btn.text}
            style={{ marginLeft: '24px' }}
            variant={btn.variant || 'contained'}
            color={btn.color || 'primary'}
            onClick={btn.onClick}
            disabled={btn.disabled}
            data-testid={btn['data-testid']}
          >
            {btn.text}
          </Button>
        ))}
      </DialogActions>
    </StyledModal>
  );
};
