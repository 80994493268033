import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CustomerStatus } from 'types/Customer';
import {
  DispatchedItemSurvey,
  ItemSurveyInstance,
  OneOffSurveyInstance,
} from 'types/Offer';
import { ActiveSurveyInstance } from 'types/Offer';

import AccessRevoked from 'components/EmptyState/AccessRevoked';
import NoActiveOffers from 'components/EmptyState/NoActiveOffers';
import { ItemSurveyCard } from 'components/Surveys/ItemSurveyCard';
import { OneOffSurveyCard } from 'components/Surveys/OneOffSurveyCard';
import { Box, Grid } from 'components/shared';

const useStyles = makeStyles(() => ({
  emptyStateContainer: {
    margin: '0 auto',
  },
}));

export interface ActiveOfferProps {
  customerStatus: CustomerStatus;
  itemSurveys: ItemSurveyInstance[];
  dispatchedItemSurveys?: DispatchedItemSurvey[];
  oneOffSurveys?: OneOffSurveyInstance[];
  onPressItemSurvey: (data: ActiveSurveyInstance) => void;
}

export const ActiveOffers = (props: ActiveOfferProps) => {
  const { customerStatus, itemSurveys, oneOffSurveys, onPressItemSurvey } = props;
  const accountLocked = customerStatus === 'disabled' || customerStatus === 'banned';
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getDispatchedItemSurveyCards = (): JSX.Element[] => {
    if (itemSurveys.length <= 0) {
      return [];
    }

    const dispatchedItemCards = itemSurveys.map((itemSurvey: ItemSurveyInstance) => {
      return (
        <Grid item key={itemSurvey.id}>
          <ItemSurveyCard survey={itemSurvey} onPressButton={onPressItemSurvey} />
        </Grid>
      );
    });
    return dispatchedItemCards;
  };

  const getOneOffSurveyCards = (): JSX.Element[] => {
    if (!oneOffSurveys || oneOffSurveys.length <= 0) {
      return [];
    }
    const oneOffSurveyEles = oneOffSurveys.map((oneOffSurvey: OneOffSurveyInstance) => {
      return (
        <Grid item key={oneOffSurvey.id}>
          <OneOffSurveyCard oneOffSurvey={oneOffSurvey} onPress={onPressItemSurvey} />
        </Grid>
      );
    });
    return oneOffSurveyEles;
  };

  const getSurveyCards = (): JSX.Element[] => {
    return getDispatchedItemSurveyCards().concat(getOneOffSurveyCards());
  };

  const surveyCards = getSurveyCards();

  return (
    <Grid
      container
      spacing={3}
      justifyContent={isSmallScreen ? 'center' : 'flex-start'}
      style={{ marginBottom: 25 }}
    >
      {accountLocked ? (
        <Box className={classes.emptyStateContainer}>
          <AccessRevoked />
        </Box>
      ) : surveyCards.length > 0 ? (
        surveyCards
      ) : (
        <Box className={classes.emptyStateContainer}>
          <NoActiveOffers />
        </Box>
      )}
    </Grid>
  );
};
