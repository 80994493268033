import React from 'react';
import { QuestionAnswers } from 'types/Answer';
import { HighlighterQuestion, PossibleAnswer } from 'types/Questions';

import { Typography } from 'components/shared';
import DemoQuestionsBubbleButton from 'components/shared/Bubble/DemoQuestionsBubbleButton';

interface BubbleQuestionPropsNew {
  question: HighlighterQuestion;
  currentAnswers: QuestionAnswers[string];
  onSave: (answers: QuestionAnswers[string], questionId: string) => void;
  onSelectAnswer: (
    question: HighlighterQuestion,
    answer: PossibleAnswer,
    possibleAnswers: PossibleAnswer[]
  ) => void;
  onSaveEligibilityChange?: (id: string, saveEligibile: boolean) => void;
  useMobileStyles: boolean;
  showHelpText?: boolean;
}
export const DemoQuestions = (props: BubbleQuestionPropsNew): JSX.Element => {
  const { question, currentAnswers, useMobileStyles, onSelectAnswer, showHelpText } =
    props;

  return (
    <>
      <div>
        <Typography variant={useMobileStyles ? 'h6' : 'h5'}>
          {question.description}
        </Typography>
        {showHelpText && (
          <Typography variant={'caption'}>
            {question.allowMultiple
              ? 'Please select all that apply'
              : 'Please select one'}
          </Typography>
        )}
      </div>
      {question.possibleAnswers.map((answer: PossibleAnswer) => (
        <DemoQuestionsBubbleButton
          answer={answer}
          onClick={() => onSelectAnswer(question, answer, question.possibleAnswers)}
          key={answer.id}
          selected={!!currentAnswers?.[answer.id]}
        />
      ))}
    </>
  );
};
