import { Box, Typography } from 'components/shared';
import React from 'react';

import { SectionHeaderProps } from './types';

const SectionHeader = (props: SectionHeaderProps): JSX.Element => (
  <>
    <Box marginBottom="24px">
      <Typography variant="h3" style={{ marginBottom: '16px' }}>
        {props.title}
      </Typography>
    </Box>
    <Box marginBottom="24px">
      <Typography variant={'body2'}>{props.body}</Typography>
    </Box>
  </>
);

export default SectionHeader;
