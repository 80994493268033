// TODO: This is a copy of the component from showcase-ui
// Refactor this when we can
import React, { FC } from 'react';
import { Button } from 'components/shared';
import { BubbleButtonProps } from './types';
import { styled as muiStyled } from '@material-ui/core/styles';

const StyledButton = muiStyled(Button)(({ theme }) => ({
  fontFamily: '"Work Sans", sans-serif',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 400,
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  borderWidth: '1px',
  borderStyle: 'solid',
  boxSizing: 'border-box',
  margin: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 0`,
  borderRadius: '25px',
  '& .MuiButton-label': {
    whiteSpace: 'normal',
  },
}));

const BubbleButton: FC<BubbleButtonProps> = (props: BubbleButtonProps): JSX.Element => {
  const { answer, onClick } = props;
  const _onClick = (): void => onClick(answer);
  return (
    <StyledButton
      {...props}
      onClick={_onClick}
      variant={answer.selected ? 'contained' : 'outlined'}
      color={answer.selected ? 'primary' : 'inherit'}
      key={answer.id}
    >
      {answer.description}
    </StyledButton>
  );
};

export default BubbleButton;
