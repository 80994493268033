import { Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      textAlign: 'center',
      padding: '20px',
    },
    dueDate: {
      color: theme.palette.error.main,
      lineHeight: '1rem',
      marginBottom: 15,
      fontWeight: 400,
    },
    boldDueText: {
      fontWeight: 900,
    },
    surveyName: {
        display: 'block',
        width: '310px',
        textAlign: 'center',
        marginBottom: 12,
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    noThanksButton: {
      color: theme.palette.grey[800],
      textDecoration: 'underline',
    }
  }));
