import React from 'react';
import { AuthenticatedUser } from 'types/Authentication';
import { Customer } from 'types/Customer';

import { Box } from 'components/shared';

import DemoQuestionsSection from './DemoQuestions';

export interface PreferencesSectionProps {
  user: AuthenticatedUser;
  customer: Customer;
  onSaveEligibilityChange: (id: string, saveEligibile: boolean) => void;
}

export const PreferencesSection = (props: PreferencesSectionProps): JSX.Element => {
  const { onSaveEligibilityChange } = props;
  return (
    <div style={{ maxWidth: 705, width: '100%' }}>
      <Box marginBottom="24px">
        <DemoQuestionsSection onSaveEligibilityChange={onSaveEligibilityChange} customer={props.customer} />
      </Box>
    </div>
  );
};
