// TODO: When we start using the new one-off survey,
// we can combine this component with ItemSurveyCard
// to handle any survey type
import { useUpdateSurveys } from 'hooks/api/surveys';
import { useState } from 'react';
import type { ActiveSurveyInstance, OptInSurveyInstance } from 'types/Offer';
import { SurveyStatus, SurveyType } from 'types/Offer';
import {
  formatSurveyDueDateFromDate,
  getSurveyDaysLeftFromDate,
} from 'utils/dateHelpers';

import { useStyles } from 'components/offers/SurveyCard/SurveyCard.styles';
import { SurveyImage } from 'components/offers/SurveyImage';
import { Box, Button, Dialog, ErrorRounded, Paper, Typography } from 'components/shared';

interface OptInSurveyCardProps {
  onPressButton: (data: ActiveSurveyInstance) => void;
  optInSurveyInstance: OptInSurveyInstance;
}

export const OptInSurveyCard = (props: OptInSurveyCardProps): JSX.Element => {
  const { onPressButton, optInSurveyInstance } = props;
  const { id, name, linkUrl, deadline, surveyId } = optInSurveyInstance;
  const [isRemovalDialogOpen, setIsRemovalDialogOpen] = useState(false);
  const classes = useStyles();
  const daysLeft = getSurveyDaysLeftFromDate({ deadline });
  const { updateSurvey } = useUpdateSurveys();

  const handlePressNoThanksButton = async () => {
    await updateSurvey({
      surveyInstanceId: optInSurveyInstance.id,
      req: { data: { status: SurveyStatus.Declined } },
    });
    setIsRemovalDialogOpen(false);
  };

  const getBadgeText = () => {
    if (daysLeft <= 1) {
      return 'Complete \nASAP!';
    } else return 'Days Left!';
  };

  const handlePressButton = (): void => {
    onPressButton({
      surveyUrl: linkUrl ?? '',
      id,
      surveyType: SurveyType.OptIn,
      surveyId,
    });
  };

  return (
    <>
      <Paper elevation={3} className={classes.container}>
        <SurveyImage
          surveyType={SurveyType.OptIn}
          alt={`Opt-in survey: ${name}`}
          onClick={handlePressButton}
          badge={{ value: daysLeft, text: getBadgeText() }}
        />

        <Typography title={name} variant="h6" className={classes.surveyName}>
          {name}
        </Typography>

        <Box className={classes.buttonWrapper}>
          <Typography variant="subtitle1" className={classes.dueDate}>
            <span className={classes.boldDueText}>Due: </span>
            {formatSurveyDueDateFromDate({ deadline })}
          </Typography>

          <Button onClick={handlePressButton} variant="contained" color="primary">
            {"Let's get started"}
          </Button>
          <Button
            variant="text"
            className={classes.noThanksButton}
            onClick={() => setIsRemovalDialogOpen(true)}
          >
            No Thanks
          </Button>
        </Box>
      </Paper>

      <Dialog
        isOpen={isRemovalDialogOpen}
        onClose={() => setIsRemovalDialogOpen(false)}
        onConfirm={handlePressNoThanksButton}
        title={
          <>
            <ErrorRounded color="error" fontSize="large" />
            <Typography variant="h4">
              Are you sure you want to remove this Opt-In from your account?
            </Typography>
          </>
        }
        content={
          <Box>
            <Typography variant="subtitle2">
              {
                "We can't bring it back once it's gone, so make sure you're ready to see it go before proceeding!"
              }
            </Typography>
            <Typography variant="caption">
              Removing the Opt-In from your account will have <strong>no impact</strong>{' '}
              on your status as a Highlighter.
            </Typography>
          </Box>
        }
      />
    </>
  );
};
