import { fetchHighlighterApi } from 'data/services/RequestMethods';

export const useToken = () => {
  const getHighlightApiAccessToken = async (jwt:string) : Promise<string> => {
    try {
      const resp = await fetchHighlighterApi({
        route: '/auth/token',
        method: 'POST',
        data: { 
          grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
          assertion: jwt
        },
      });
      const responseJson = await resp.json();
      if (responseJson.access_token) {
        return responseJson.access_token;
      } else {
        throw new Error("error retrieving highlighter access token");
      }
    } catch (error) {
      console.error(`error occurred when updating highlighter info ${error}`);
      throw error;
    }
  };

  return { getHighlightApiAccessToken }
}
