import { makeStyles } from '@material-ui/core/styles';
import HIGHLIGHT_LOGO from 'assets/images/Logo.png';
import { useHistory } from 'react-router-dom';
import type { Customer } from 'types/Customer';

import { AppBar, Toolbar } from 'components/shared';

import { PrivateHeader } from './PrivateHeader';
import { PublicHeader } from './PublicHeader';
import {
  PrivateRouteName,
  PublicRouteName,
  getProfilePageTabIndex,
  onClickPrivateRoute,
  onClickPublicRoute,
} from './headerUtils';

// Info: These styles were copied directly from the old code, hardcoded sizes will be refactored when we cleanup the design system
const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    height: '84px',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2.5),
      height: '104px',
    },
  },
  highlightLogo: {
    width: '100%',
    maxWidth: '168px',
    height: 'auto',
    cursor: 'pointer',
    marginRight: 0,
    [theme.breakpoints.up('md')]: {
      marginRight: '24px',
    },
  },
}));

interface HeaderProps {
  isAuthenticated: boolean;
  logout: (returnUrl: string) => void;
  customer?: Customer;
  isMaintenance?: boolean;
}

export const Header = (props: HeaderProps) => {
  const { isAuthenticated, logout, customer, isMaintenance } = props;
  const history = useHistory();
  const classes = useStyles();

  const handleClickLogo = () => {
    history.push('/home');
  };

  const handleExternalNav = (url: string, shouldOpenNewTab: boolean) => {
    window.open(url, shouldOpenNewTab ? '_blank' : '_self');
  };

  const handleLogout = () => {
    logout(window.location.origin);
  };

  const handleOnClickPublicRoute = (routeName: PublicRouteName) => {
    onClickPublicRoute({
      onExternalNav: handleExternalNav,
      onLogout: handleLogout,
      routeName,
    });
  };

  const handleOnClickPrivateRoute = (routeName: PrivateRouteName) => {
    onClickPrivateRoute({
      isAuthenticated,
      onExternalNav: handleExternalNav,
      onLogout: handleLogout,
      onNavigate: (url, tab) => history.push(url, getProfilePageTabIndex(tab)),
      routeName,
    });
  };

  return (
    <AppBar position="sticky" className={classes.header}>
      <Toolbar>
        <img
          className={classes.highlightLogo}
          src={HIGHLIGHT_LOGO}
          onClick={handleClickLogo}
          id="header-highlight-logo"
        />

        {isAuthenticated && customer?.email ? (
          <PrivateHeader onClickPrivateRoute={handleOnClickPrivateRoute} />
        ) : (
          <PublicHeader
            onClickPublicRoute={handleOnClickPublicRoute}
            isAuthenticated={isAuthenticated}
            isMaintenance={isMaintenance}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};
