import React from 'react';
import { AuthenticatedUser } from 'types/Authentication';
import { Customer } from 'types/Customer';

import { Box } from 'components/shared';

import AddressSection, { AddressSectionProps } from './AddressSection';
import BasicSection from './BasicSection';

export interface PersonalSectionProps {
  user: AuthenticatedUser;
  customer: Customer;
  onFormHasChanged: (formHasChanged: boolean) => void;
  logError: (section: 'Address' | 'Basic', error: string) => void;
}

export const PersonalSection = (props: PersonalSectionProps): JSX.Element => {
  const addressSectionProps: AddressSectionProps = {
    ...props,
    refetchCustomer: () => {},
  };
  return (
    <div style={{ maxWidth: 705, width: '100%' }}>
      <Box marginBottom="24px">
        <BasicSection {...props} />
      </Box>
      <Box marginBottom="24px">
        <AddressSection {...addressSectionProps} />
      </Box>
    </div>
  );
};
