import { MenuItem, Menu as MuiMenu, SvgIconProps, makeStyles } from '@material-ui/core';
import React from 'react';

import { Box, Button } from 'components/shared';

const useStyles = makeStyles((theme) => ({
  menu: {
    backgroundColor: theme.palette.primary.main,
  },
}));

interface MenuProps {
  buttonText: string;
  children: React.ReactNode;
  icon?: React.ElementType<SvgIconProps>;
  ['data-testid']?: string;
}

export const Menu = ({ buttonText, children, icon: Icon, ...props }: MenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box data-testid={props['data-testid']}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        endIcon={Icon ? <Icon /> : undefined}
        onClick={handleClick}
        variant="contained"
      >
        {buttonText}
      </Button>
      <MuiMenu
        anchorEl={anchorEl}
        classes={{ paper: classes.menu }}
        id="simple-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {React.Children.map(children, (child, index) => (
          <MenuItem key={index}>{child}</MenuItem>
        ))}
      </MuiMenu>
    </Box>
  );
};
