import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'components/shared';

interface PossibleIncorrectUserAccountProps {
  onLogout: () => void;
}

export const PossibleIncorrectUserAccount = (
  props: PossibleIncorrectUserAccountProps
) => {
  const { onLogout } = props;

  return (
    <Box data-testid="possible-incorrect-user-account" marginBottom="32px">
      <Typography variant="h4">Used Highlight before?</Typography>

      <Typography variant="body2">
        Please <Link href={'#'} onClick={onLogout}>logout</Link> and make sure you
        signed in with the correct Google account.
      </Typography>
    </Box>
  );
}
