import React, { FC } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import type { DefaultModalProps } from 'components/shared/Modal';
import { Modal, Typography } from 'components/shared';
import styles from './styles.module.scss';

export interface WalkthroughModalProps extends DefaultModalProps {
    subtitle: string;
}

export const WalkthroughModal: FC<WalkthroughModalProps> = (props: WalkthroughModalProps) => {
    const theme = useTheme();
    const smAndDownScreens = useMediaQuery(theme.breakpoints.down('xs'));
    const _handleClose = (): void => props.onClose();

    return (
        <Modal
            onClose={_handleClose}
            open={props.open}
            fullScreen={smAndDownScreens}
            scroll={props.scroll}
            maxWidth={'lg'}
            title={
                <div className={styles.modalHeaderContainer}>
                    <Typography variant={'h5'}>{props.title}</Typography>
                    <Typography variant={'subtitle1'}>{props.subtitle}</Typography>
                </div>
            }
            body={props.body}
            buttons={props.buttons}
        />
    );
};
