import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useCreateAddresses } from 'hooks/api/addresses';
import { useCustomerAddress } from 'hooks/useCustomerAddress';
import React, { useState } from 'react';
import { Address } from 'types/Customer';
import history from 'utils/History';

import { CustomerAddressInputs } from 'components/AddressForm/CustomerAddressInputs';
import { AddressSelectionModal } from 'components/Modals/AddressSelectionModal';
import { OnboardingResultModal } from 'components/Modals/OnboardingResultModal';
import { Box, Button, CircularProgress, Typography } from 'components/shared';

import SectionHeader from './SectionHeader';
import { StepProps } from './types';
import { useActivateHighlighter } from '../../hooks/api/highlighter';
import { List, ListItem } from '@material-ui/core';

type MessageType = 'success' | 'error' | 'second-line-missing';

const mutationMessages: { [key in MessageType]: string } = {
  success: 'Address saved successfully',
  'second-line-missing':
    'The address is missing line 2 information (ie apt or suite number). Please update the address and try again.',
  error: 'The address could not be validated. Address validation returned the following errors.',
};

const ModalMessages = {
  success:
    'Now we can match you with the perfect studies tailored to your interests and the details in your profile! Please click the button below to proceed to your home page, where you can check for any opportunities we have available for you.',
  warning:
    'There was an error saving some of your profile information. Your profile has been created, but you may need to check your preferences and complete any remaining questions before we can match you with the perfect offers for you. Please click below to continue.',
};

type AddressSectionProps = StepProps & {
  form: useCustomerAddress;
  refetchCustomer: () => void;
  shouldSkipAddressCreation: boolean;
};

const AddressSection = (props: AddressSectionProps): JSX.Element => {
  const theme = useTheme();
  const smAndDownScreens = useMediaQuery(theme.breakpoints.down('xs'));
  const [loading, setLoading] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<MessageType | undefined>(undefined);
    useState<boolean>(false);
  const [useSuggestedAddress, setUseSuggestedAddress] = useState(false);
  const [displayAddressSelectionModal, setDisplayAddressSelectionModal] =
    useState<boolean>(false);
  // display success/failure modal
  const [modalStatus, setModalStatus] = useState<'warning' | 'success'>('warning');
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const { createAddress } = useCreateAddresses();

  const { activateHighlighter } = useActivateHighlighter();

  const {
    inputs,
    suggestedAddress,
    isValidRecommendedAddress,
    validateForm,
    validateAddress,
    setInputsFromAddress,
    getResponseDescriptions,
  } = useCustomerAddress({ ...props, logError: undefined });

  const _onSubmitForm = async (): Promise<void> => {
    setLoading(true);
    setMessageType(undefined);

    if (!props.shouldSkipAddressCreation) {
      // Check if input is valid
      const inputsValid = validateForm();
      if (!inputsValid){
        setLoading(false);
        return;
      }

      // Perform address validation
      const addressValidation = await validateAddress();
      if (!addressValidation?.isValid) {
        if (addressValidation?.details) {
          setMessageType('error');
          const errors = getResponseDescriptions(addressValidation?.details)
          if (addressValidation.isPoBox) {
            errors.push("PO Boxes are not allowed")
          }
          if (!addressValidation.isValidState) {
            errors.push("The submitted state is not valid")
          }
          setErrors(errors)
        }
        setDisplayAddressSelectionModal(true);
        setLoading(false);
        return;
      }

      const address: Address = {
        lineOne: inputs.addressOne.value.trim(),
        lineTwo:
          inputs.addressTwo?.value?.length > 0
            ? inputs.addressTwo.value.trim()
            : undefined,
        city: inputs.city.value.trim(),
        postalCode: inputs.postalCode.value.trim(),
        country: inputs.country.value.trim(),
        state: inputs.state.value.trim(),
      };

      const resp = await createAddress(address);
      if (!resp.ok) {
        if (props.setErrorDuringOnboarding) {
          props.setErrorDuringOnboarding(
            true,
            'AddressSection',
            'could not create address'
          );
        }
        // update state
        setModalStatus('warning');
        setModalIsVisible(true);
        return;
      }
    }

    // Activate Highlighter
    const activateHighlighterResponse = await activateHighlighter();

    if (!activateHighlighterResponse.ok) {
      // unable to update user, log error
      if (props.setErrorDuringOnboarding) {
        const errorMessage = 'Unable to update customer to active status';
        props.setErrorDuringOnboarding(true, 'AddressSection', errorMessage);
      }
    }

    if (activateHighlighterResponse.ok && props.errorDuringOnboarding === false) {
      setModalStatus('success');
      setModalIsVisible(true);
    } else {
      setModalStatus('warning');
      setModalIsVisible(true);
    }

    setLoading(false);
  };

  const _onModalButtonPress = async (): Promise<void> => {
    await props.refetchCustomer();
    if (modalStatus === 'success') {
      history.push('home');
    } else {
      history.push('profile', { initialTabIndex: 1 });
    }
  };

  React.useEffect(() => {
    // user has selected to use the suggested address
    if (useSuggestedAddress) {
      _onSubmitForm();
      setUseSuggestedAddress(false);
    }
  }, [useSuggestedAddress]);

  return (
    <>
      <SectionHeader
        title={`Let's fill out your profile!`}
        body={`We use these questions to match you with the perfect offers for you. Filling in the questions honestly
                and fully ensures you are eligible for the most offers.`}
      />
      <Box marginBottom="24px">
        <Typography variant={'h5'}>What is your address?</Typography>
        <Typography variant={'caption'}>
          We need this so we can send products to you!
        </Typography>
      </Box>
      <Box style={{ width: '100%', maxWidth: '536px' }}>
        {!smAndDownScreens &&
        messageType &&
        (messageType === 'error' || messageType === 'second-line-missing') ? (
          <Box marginBottom="24px">
            <Typography
              variant="body2"
              style={{ marginTop: 16, color: theme.palette.warning.main }}
            >
              {mutationMessages[messageType]}
            </Typography>
            <List style={{listStyleType: 'disc', marginLeft: 32 }}>
              {errors.map(error => (
                <ListItem key={error} style={{ display: 'list-item', color: theme.palette.warning.main }}>
                    {error}
                </ListItem>
              ))}
            </List>

          </Box>

        ) : undefined}
        <CustomerAddressInputs customer={props.customer} {...inputs} />
        {smAndDownScreens &&
        messageType &&
        (messageType === 'error' || messageType === 'second-line-missing') ? (
          <Box marginBottom="24px">
            <Typography
              variant="body2"
              style={{ marginTop: 16, color: theme.palette.warning.main }}
            >
              {mutationMessages[messageType]}
            </Typography>
          </Box>
        ) : undefined}
      </Box>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '15px',
        }}
      >
        <Box marginRight="16px">
          <Button
            variant={'outlined'}
            color={'primary'}
            onClick={(): void => props.previousStep()}
            disabled={loading}
          >
            Go Back
          </Button>
        </Box>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={_onSubmitForm}
          endIcon={loading ? <CircularProgress size={20} /> : undefined}
          disabled={loading}
        >
          {loading ? 'Loading' : 'Submit'}
        </Button>
      </Box>
      <OnboardingResultModal
        open={modalIsVisible}
        status={modalStatus}
        title={
          modalStatus === 'success'
            ? 'Profile complete! Sweet! '
            : 'Profile created with errors '
        }
        body={modalStatus === 'success' ? ModalMessages.success : ModalMessages.warning}
        onClose={(): null => null}
        buttons={[
          {
            text: modalStatus === 'success' ? 'Home' : 'Preferences',
            onClick: _onModalButtonPress,
          },
        ]}
      />
      {suggestedAddress && (
        <AddressSelectionModal
          title={'Verify Address'}
          subtitle={`We think there's a problem with the address you entered. Please use our suggestion or edit your original address.`}
          open={displayAddressSelectionModal}
          userEnteredAddress={{
            addressOne: inputs.addressOne.value.trim(),
            addressTwo:
              inputs.addressTwo?.value?.length > 0
                ? inputs.addressTwo.value.trim()
                : undefined,
            city: inputs.city.value.trim(),
            postalCode: inputs.postalCode.value.trim(),
            country: inputs.country.value.trim(),
            state: inputs.state.value.trim(),
          }}
          suggestedAddress={suggestedAddress}
          isValidRecommendedAddress={isValidRecommendedAddress}
          onClose={() => {
            // ignore close request (ie click outside modal on overlay)
            // to force the user to select among the modal options
          }}
          onClickUseSuggestedAddress={() => {
            // manually set the browser inputs to the suggested address then
            //  fire the onSubmit again to continue with the add//update address op
            setInputsFromAddress(suggestedAddress);
            setUseSuggestedAddress(true);
            setDisplayAddressSelectionModal(false);
          }}
          onClickEditUserEnteredAddress={() => {
            setDisplayAddressSelectionModal(false);
          }}
          buttons={[]}
        />
      )}
    </>
  );
};
export default AddressSection;
