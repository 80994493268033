import {
  MessagePayload,
  SurveyPlatform,
  SurveyPlatformName,
  SurveyPlatforms,
  SurveyStatus,
} from './types';

import { SurveyType } from 'types/Offer';

/**
 * construct base url + query params
 */
export const getFullSurveyUrlByPlatform = (
  platform: SurveyPlatformName,
  url: string,
  userId: string,
  firstName: string,
  surveyId: string,
  surveyType: SurveyType
): string => {
  let fullUrl = undefined;
  if (platform === 'typeform') {
    fullUrl = `${url}?userid=${userId}&firstname=${firstName}`;
  } else if (platform === 'alchemer') {
    const alchemerSurveyType = mapSurveyTypeToAlchemerSurveyType(surveyType);
    fullUrl = `${url}?userid=${userId}&firstname=${firstName}&dispatched_survey_id=${surveyId}&survey_type=${alchemerSurveyType}&__sguid_alias=dispatched_survey_id`;
  } else {
    throw 'invalid platform: unable to resolve full url';
  }
  return fullUrl;
};

/**
 * confirm the passed payload indicates a success state
 */
export const verifyPayloadByPlatform = (
  platform: SurveyPlatformName,
  messageType: SurveyStatus,
  payload: MessagePayload
): boolean => {
  let successIndicated = false;
  if (messageType) {
    if (platform === 'typeform') {
      successIndicated = payload && payload?.responseId ? true : false;
    } else if (platform === 'alchemer') {
      successIndicated = payload && payload?.surveyId ? true : false;
    }
  }

  return successIndicated;
};

/**
 * get platform name from URL
 * note: this is a placeholder function until db stores type and can be queried
 */
export const getPlatformBySurveyUrl = (
  survey_url: string,
  surveyPlatforms: SurveyPlatforms
): SurveyPlatform => {
  const urlObject = new URL(survey_url);
  const hostname = urlObject.hostname;
  for (let index = 0; index < surveyPlatforms.length; index++) {
    const platform: SurveyPlatform = surveyPlatforms[index];
    if (platform.hostName === hostname) {
      return platform;
    }
  }

  // if we reached here something went wrong
  // @ts-ignore
  return undefined;
};

export const mapSurveyTypeToAlchemerSurveyType = (surveyType: SurveyType): 'item' | 'opt-in' | 'oneOff' => {
  switch (surveyType) {
    case SurveyType.OneOff:
      return 'oneOff';
    case SurveyType.OptIn:
      return 'opt-in';
    case SurveyType.Item:
      return 'item';
    default:
      throw new Error('Invalid SurveyType');
  }
}
