import MomentUtils from '@date-io/moment';
import { Box, CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { responsiveFontSizes } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ToastProvider } from 'ToastProvider';
import { APP_CONSTANTS } from 'constants/AppConstants';
import { useAuth } from 'hooks/useAuth';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useGoogleLoginListener } from 'hooks/useGoogleLoginListener';
import { useMaintenanceWindow } from 'hooks/useMaintenanceWindow';
import AppRouter from 'router/Router';
import { SWRConfig } from 'swr';
import { localStorage } from 'utils/localStorage';
import { Maintenance } from 'views/Maintenance';

import { Header } from 'components/shared';
import { Spinner } from 'components/shared';
// styles to override chat widget
import { Footer } from 'components/shared/Footer';

import './App.css';
import showcaseTheme from './theme';

// https://material-ui.com/customization/typography/#responsive-font-sizes
const theme = responsiveFontSizes(showcaseTheme);

const App = (): JSX.Element => {
  const maintenanceWindow = useMaintenanceWindow();

  const {
    isAuthenticated,
    logout,
    user,
    isUserLoading,
    accessToken,
    authenticateGoogleUser,
  } = useAuth();
  const { customer, isCustomerLoading, refetchCustomer } = useCurrentUser({
    accessToken,
    email: user?.email,
    isUserLoading,
  });

  useGoogleLoginListener(authenticateGoogleUser);

  // save the customer
  if (customer !== undefined)
    localStorage.setItem(APP_CONSTANTS.StorageKeys.CustomerName, customer.firstName);

  if (isUserLoading || isCustomerLoading || maintenanceWindow.isMaintenanceLoading) {
    return <Spinner variant="highlight" />;
  }

  const isMaintenance = maintenanceWindow.isMaintenance;

  return (
    <SWRConfig value={{ provider: () => new Map() }}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ToastProvider>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  minHeight: '100vh',
                  backgroundColor: '#fff',
                }}
              >
                <Box>
                  {isMaintenance ? (
                    <>
                      <Header
                        isAuthenticated={false}
                        logout={() => {}}
                        isMaintenance={true}
                      />
                      <Maintenance
                        maintenanceEndTime={maintenanceWindow.maintenanceEndTime}
                      />
                    </>
                  ) : (
                    <>
                      <Header
                        isAuthenticated={isAuthenticated}
                        logout={logout}
                        customer={customer}
                      />
                      <AppRouter
                        customer={customer}
                        user={user}
                        refetchCustomer={refetchCustomer}
                        logout={logout}
                        accessToken={accessToken}
                      />
                    </>
                  )}
                </Box>
                <Footer />
              </Box>
            </ToastProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </SWRConfig>
  );
};

export default App;
