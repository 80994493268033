import { GoogleLogin } from '@react-oauth/google';
import { useLocation } from 'react-router-dom';

import { onLoginFailure, onLoginSuccess } from './GoogleLoginEventHandlers';

export type GoogleLoginText =
  | 'signin_with'
  | 'signup_with'
  | 'continue_with'
  | 'signin'
  | undefined;

export type LoginContext = 'login' | 'signup';

/** This component should *only* be referenced by the /signin route. To display the login button, use the GoogleLoginFrame component. */
export const GoogleLoginButton = () => {
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const buttonText = queryString.get('text') as GoogleLoginText;
  const context = queryString.get('context') as LoginContext;
  const referralCode = queryString.get('refCode') ?? undefined;
  const eph = queryString.get('eph');

  // in eph environments, we build the target (parent) origin from the provided eph name
  // in all other environments, the parent and child (iframe) origins must be identical
  const targetOrigin = eph
    ? `https://${eph}-highlighter-portal.eph.letshighlightdev.com`
    : window.location.origin;

  return (
    <GoogleLogin
      text={buttonText}
      width={context == 'signup' ? 208 : undefined}
      onSuccess={onLoginSuccess(window.parent, targetOrigin, context, referralCode)}
      onError={onLoginFailure(window.parent, targetOrigin, context)}
    />
  );
};
