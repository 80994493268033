// TODO: When we start using the new one-off survey,
// we can combine this component with OptInSurveyCard
// to handle any survey type
import React from 'react';
import { ActiveSurveyInstance, ItemSurveyInstance, SurveyType } from 'types/Offer';
import {
  formatSurveyDueDateFromDate,
  getSurveyDaysLeftFromDate,
} from 'utils/dateHelpers';

import { useStyles } from 'components/offers/SurveyCard/SurveyCard.styles';
import { SurveyImage } from 'components/offers/SurveyImage';
import { Box, Button, Paper, Typography } from 'components/shared';

interface ItemSurveyProps {
  survey: ItemSurveyInstance;
  onPressButton: (data: ActiveSurveyInstance) => void;
}

export const ItemSurveyCard = (props: ItemSurveyProps): JSX.Element => {
  const { onPressButton, survey } = props;
  const { id, name, deadline, linkUrl, isOverdue, surveyId } = survey;

  const classes = useStyles();
  const daysLeft = getSurveyDaysLeftFromDate({ deadline });

  const getBadgeText = () => {
    if (isOverdue) {
      return 'Overdue!';
    } else if (daysLeft <= 1) {
      return 'Complete \nASAP!';
    } else return 'Days Left!';
  };

  const handlePressButton = (): void => {
    onPressButton({
      surveyUrl: linkUrl ?? '',
      id,
      surveyType: SurveyType.Item,
      surveyId,
    });
  };

  return (
    <Paper elevation={3} className={classes.container}>
      <SurveyImage
        surveyType={SurveyType.Item}
        alt={`Item survey: ${name}`}
        onClick={handlePressButton}
        badge={{ value: daysLeft, text: getBadgeText() }}
      />

      <Typography title={name} variant="h6" className={classes.surveyName}>
        {name}
      </Typography>

      <Box className={classes.buttonWrapper}>
        <Typography variant="subtitle1" className={classes.dueDate}>
          {isOverdue ? (
            'Overdue, Complete ASAP!'
          ) : (
            <>
              <span className={classes.boldDueText}>Due: </span>
              {formatSurveyDueDateFromDate({ deadline })}
            </>
          )}
        </Typography>

        <Button onClick={handlePressButton} variant="contained" color="primary">
          Complete
        </Button>
      </Box>
    </Paper>
  );
};
