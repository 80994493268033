import React, { useState, FocusEvent } from 'react';
import TextMaskCustom from 'components/Forms/TextMaskCustom';
import { makeStyles } from '@material-ui/core';
import { CheckCircleOutline, ErrorOutline, Input, InputAdornment, PhoneSharp, Spinner } from 'components/shared'

export const useStyles = makeStyles(() => ({
  labelOffset: {
    transform: 'translate(44px, 20px) scale(1)',
  },
}));

const PhoneInput = (props: any): JSX.Element => {
  const [shrink, setShrink] = useState(false);
  const { error, success, loading, onFocus, onBlur, ...otherProps } = props;
  const classes = useStyles();

  return (
    <Input
      {...otherProps}
      onFocus={(event: FocusEvent<HTMLInputElement>): void => {
        setShrink(true);
        onFocus?.(event);
      }}
      onBlur={(event: FocusEvent<HTMLInputElement>): void => {
        if (event.target.value.length === 0) {
          setShrink(false);
        }
        onBlur?.(event);
      }}
      theme={error ? 'error' : success ? 'success' : 'primary'}
      InputLabelProps={{ shrink: shrink, classes: { root: classes.labelOffset } }}
      InputProps={{
        inputComponent: TextMaskCustom,
        startAdornment: (
          <InputAdornment position="start">
            <PhoneSharp color="inherit" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {loading ? (
              <Spinner />
            ) : error ? (
              <ErrorOutline color="inherit" />
            ) : success ? (
              <CheckCircleOutline color="inherit" />
            ) : (
              <div />
            )}
          </InputAdornment>
        ),
      }}
      type={'tel'}
    />
  );
};

export default PhoneInput;
