import { fetchPublicHighlighterApi } from '../../data/services/RequestMethods';
import { ValidatePromoCodeResult } from '../../data/services/api-types';

const useCheckReferralCode = () => {
  const checkReferralCode = async (code: string): Promise<ValidatePromoCodeResult | undefined> => {
    try {
      const res = await fetchPublicHighlighterApi({route: `/ReferralCodes/validate/${code}`})
      if(res.ok) {
        const result = await res.json();
        return { isValid: result.isValid }
      }
    } catch(error) {
      console.error(`error occurred checking referral code:  ${error}`)
      throw error;
    }
  }
  return { checkReferralCode }
};

export { useCheckReferralCode };
