import { Theme, makeStyles } from '@material-ui/core';
import React from 'react';

import { Box, Typography } from 'components/shared';
import { SurveyType } from 'types/Offer';

import AnimatedLetters from './AnimatedLetters';

export const FALLBACK_SURVEY_IMG_SRC = 'default-survey-image.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'inline-block',
    transition: '0.5s',
    '&:hover': {
      transform: 'scale(1.05)',
      '& $waveItem': {
        animation: '$wave 0.25s ease-out',
      },
    },
  },
  waveItem: {
    display: 'inline-block',
    transformOrigin: 'bottom',
  },
  '@keyframes wave': {
    '0%': { transform: 'translateY(0)' },
    '60%': { transform: 'translateY(-11px)' },
    '100%': { transform: 'translateY(0)' },
  },
  surveyImg: {
    cursor: 'auto',
    height: '270px',
    width: '270px',
    paddingBottom: 10,
  },
  bold: {
    fontWeight: 700,
    fontSize: '1rem',
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  badge: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'absolute',
    top: '5px',
    right: '5px',
    padding: 5,
    borderRadius: '50%',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    height: '100px',
    width: '100px',
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
  },
}));

interface SurveyImageProps {
  alt: string;
  onClick?: () => void;
  surveyType: SurveyType;
  badge?: { value?: number; text: string };
}

export const SurveyImage = (props: SurveyImageProps) => {
  const { alt, badge, onClick } = props;
  const classes = useStyles({ hasPointer: !!onClick });
  const shouldShowBadgeValue = !!badge?.value;

  const imgSrc = {
    [SurveyType.Item]: 'default-survey-image.png',
    [SurveyType.OneOff]: 'default-survey-image.png',
    [SurveyType.OptIn]: 'default-opt-in-image.png',
  }[props.surveyType] ?? FALLBACK_SURVEY_IMG_SRC;

  return (
    <Box className={classes.container}>
      <img
        alt={alt}
        className={`${classes.surveyImg} ${onClick ? classes.pointerCursor : ''}`}
        onClick={onClick}
        src={imgSrc}
      />
      {badge && (
        <Box className={classes.badge} data-testid="survey-image-badge">
          {shouldShowBadgeValue && (
            <Typography variant="h4" style={{ lineHeight: '2rem' }}>
              {badge.value}
            </Typography>
          )}
          <Typography variant="body2">
            <AnimatedLetters
              text={badge.text}
              animatedItemClass={
                badge.value ? classes.waveItem : `${classes.waveItem} ${classes.bold}`
              }
            />
          </Typography>
        </Box>
      )}
    </Box>
  );
};
