import { Theme, TypographyProps } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AddressFormInputs } from 'hooks/useCustomerAddress';
import React, { FC } from 'react';

import { Button, Modal, ModalIconHeader, Typography } from 'components/shared';
import type { DefaultModalProps } from 'components/shared/Modal';

export interface AddressSelectionModalProps extends DefaultModalProps {
  subtitle: string;
  suggestedAddress: AddressFormInputs;
  isValidRecommendedAddress: boolean;
  userEnteredAddress: AddressFormInputs;
  onClickUseSuggestedAddress: () => void;
  onClickEditUserEnteredAddress: () => void;
}

export const AddressSelectionModal: FC<AddressSelectionModalProps> = (
  props: AddressSelectionModalProps
) => {
  const theme = useTheme();
  const smAndDownScreens = useMediaQuery(theme.breakpoints.down('xs'));
  const _handleClose = (): void => props.onClose();
  const { userEnteredAddress, suggestedAddress, isValidRecommendedAddress } = props;
  return (
    <Modal
      onClose={_handleClose}
      open={props.open}
      fullScreen={smAndDownScreens}
      scroll={props.scroll}
      maxWidth={'lg'}
      title={<ModalIconHeader title={props.title} status={'warning'} />}
      body={
        <>
          <Typography variant={'body2'} gutterBottom>
            {props.subtitle}
          </Typography>
          <div style={{ marginTop: 16, marginBottom: -16 }}>
            <div style={{ marginBottom: 24 }}>
              {/*
                                user entered address
                            */}
              <Typography variant="h6">You Entered:</Typography>
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <AddressText>{userEnteredAddress.addressOne}</AddressText>
                {userEnteredAddress.addressTwo ? (
                  <AddressText>{userEnteredAddress.addressTwo}</AddressText>
                ) : undefined}
                <AddressText>
                  {userEnteredAddress.city}, {userEnteredAddress.state}{' '}
                  {userEnteredAddress.postalCode}
                </AddressText>
              </div>

              <div style={{ display: 'inline-block' }}>
                <Typography
                  style={{
                    textTransform: 'none',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    padding: 8,
                  }}
                  display="block"
                  variant="button"
                  onClick={props.onClickEditUserEnteredAddress}
                  tabIndex={0} // include in tab key nav
                >
                  Edit Submission
                </Typography>
              </div>
            </div>
            <div>
              {/*
                                suggested by smarty streets
                            */}
              {isValidRecommendedAddress && (
                <>
                  <Typography variant="h6">We Recommend:</Typography>
                  <div style={{ marginTop: 8, marginBottom: 8 }}>
                    <AddressText
                      highlight={
                        suggestedAddress.addressOne !== userEnteredAddress.addressOne
                      }
                    >
                      {suggestedAddress.addressOne}
                    </AddressText>
                    {suggestedAddress.addressTwo ? (
                      <AddressText
                        highlight={
                          suggestedAddress.addressTwo !== userEnteredAddress.addressTwo
                        }
                      >
                        {suggestedAddress.addressTwo}
                      </AddressText>
                    ) : undefined}

                    <AddressText
                      display="inline"
                      highlight={suggestedAddress.city !== userEnteredAddress.city}
                    >
                      {suggestedAddress.city}
                      {', '}
                    </AddressText>
                    <AddressText
                      display="inline"
                      highlight={suggestedAddress.state !== userEnteredAddress.state}
                    >
                      {suggestedAddress.state}{' '}
                    </AddressText>
                    <AddressText
                      display="inline"
                      highlight={
                        suggestedAddress.postalCode !== userEnteredAddress.postalCode
                      }
                    >
                      {suggestedAddress.postalCode}
                    </AddressText>
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={props.onClickUseSuggestedAddress}
                  >
                    Use Recommended
                  </Button>
                </>
              )}
            </div>
          </div>
        </>
      }
      buttons={props.buttons}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.success.main,
  },
}));
interface GreenTypographyProps extends TypographyProps {
  highlight?: boolean;
}
const AddressText = (props: GreenTypographyProps) => {
  const classes = useStyles();
  console.log('AddressText', { props });
  return (
    <Typography
      variant="body2"
      className={props.highlight ? classes.root : undefined}
      {...props}
    />
  );
};
