import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * access query string params and related ops via URLSearchParams
 * example: const queryString = useQueryString();
 *          const referralCodeFromUrl = queryString.get('referralCode');
 * @returns URLSearchParams
 */
export function useQueryString(): URLSearchParams {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
