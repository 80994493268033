import { AddressResponseDetails, ValidateAddressResponse } from 'data/services/api-types';
import { useVerifyAddress } from 'hooks/api/addresses';
import { useInput } from 'hooks/useInput';
import React from 'react';
import { Address } from 'types/Customer';

import { AddressSectionProps } from 'components/profile/AddressSection';

//? smarty docs: https://www.smarty.com/docs/cloud/us-street-api

export interface AddressFormInputs {
  addressOne: string;
  addressTwo?: string;
  postalCode: string;
  state: string;
  city: string;
  country: string;
}

export interface AddressMatchInfo {
  addressOneMatches: boolean;
  addressTwoMatches: boolean;
  cityMatches: boolean;
  stateMatches: boolean;
  postalCode: boolean;
}

// Does of the inputs changed from the initial value?
const formHasBeenChanged = (inputs: any[]): boolean => {
  console.log('const formHasBeenChanged = (inputs: any[]): boolean => ', { inputs });
  for (let index = 0; index < inputs.length; index++) {
    const input = inputs[index];
    if (input?.hasChanged === true) {
      return true;
    }
  }

  return false;
};

// check if user inputs (address) is valid
// if the address matches what smarty streets suggests isValid = true, otherwise isValid = false
// if a suggested address is sent back from smarty streets, suggestedAddress & addressMatchInfo will be an object
const useSmartyStreetsValidation = async (inputs: AddressFormInputs) => {
  // use this to comp to a suggested address (if applicable)
  const userEnteredAddress = {
    lineOne: inputs.addressOne.trim(),
    lineTwo: inputs.addressTwo?.trim(),
    postalCode: inputs.postalCode.trim(),
    state: inputs.state.trim(),
    city: inputs.city.trim(),
    country: inputs.country.trim(),
  };

  // backend endpoint that wraps around smartyStreets
  const { verifyAddress } = useVerifyAddress();

  let resp: Response;
  try {
    resp = await verifyAddress(userEnteredAddress);
  } catch (error) {
    console.error('Error verifying address', error);
    return undefined;
  }

  const result: ValidateAddressResponse = await resp.json();
  let suggestedAddress: AddressFormInputs | null = null;
  let addressMatchInfo: AddressMatchInfo | null = null;
  let isValid = result.isValid;
  const isValidRecommendation = result.isValid;

  // did we get some suggestions?
  if (result?.address?.components) {
    const comps = result.address.components;
    suggestedAddress = {
      addressOne: result.address.delivery_line_1,
      addressTwo: result.address.delivery_line_2 ?? '',
      city: comps.city_name,
      state: comps.state_abbreviation,
      postalCode: `${comps.zipcode}${comps.plus4_code ? '-' + comps.plus4_code : ''}`,
      country: inputs.country.trim(), // country isn't returned by smartyStreets
    };

    // check inputs against the suggested address
    addressMatchInfo = {
      addressOneMatches: userEnteredAddress.lineOne === suggestedAddress.addressOne,
      addressTwoMatches:
        userEnteredAddress.lineTwo === suggestedAddress.addressTwo ||
        !userEnteredAddress.lineTwo === !suggestedAddress.addressTwo, // handle when empty string, null, or undefined are comapred
      cityMatches: userEnteredAddress.city === suggestedAddress.city,
      stateMatches: userEnteredAddress.state === suggestedAddress.state,
      postalCode: userEnteredAddress.postalCode === suggestedAddress.postalCode,
    };

    // the passed address is valid if it matches the suggested address
    const matchesSuggestion =
      addressMatchInfo.addressOneMatches &&
      addressMatchInfo.addressTwoMatches &&
      addressMatchInfo.cityMatches &&
      addressMatchInfo.stateMatches &&
      addressMatchInfo.postalCode;

    isValid = (result.isValid && matchesSuggestion);

  } else {
    // no suggestions provided by smarty streets
    console.log('No address components (suggestions) were passed from smarty streets');
  }

  const data = {
    // ...result, // pass back everything from smarty streets
    address: result.address,
    isValid: isValid,
    isValidAddress: result.isValidAddress,
    isPoBox: result.isPoBox,
    isValidState: result.isValidState,
    details: result.details,
    isValidRecommendation: isValidRecommendation,
    suggestedAddress: suggestedAddress,
    addressMatchInfo: addressMatchInfo,
  };
  return data;
};

const validateForm = (inputs: { validateInput: () => boolean }[]): boolean => {
  try {
    // check form values client side
    let formIsValid = true;
    inputs.forEach((input) => {
      const valid = input.validateInput();
      if (!valid) {
        formIsValid = valid;
      }
    });
    return formIsValid;
  } catch (error) {
    return false;
  }
};

export interface useCustomerAddress {
  inputs: {
    addressOne: any;
    addressTwo: any;
    city: any;
    postalCode: any;
    country: any;
    state: any;
  };
  suggestedAddress: AddressFormInputs | undefined;
  isValidRecommendedAddress: boolean;
  formHasChanged: boolean;
  validateForm: () => any;
  updateInitialValuesToCurrentValues: () => void;
  setInitialValuesFromAddress: (address: AddressFormInputs) => void;
  validateAddress: () => Promise<ValidateAddressResponse | undefined>;
  setInputsFromAddress: (address: AddressFormInputs) => void;
  getResponseDescriptions: (details: AddressResponseDetails) => string[];
}

const postalCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/; // /^[0-9]*$/;

// populate with input validation rules
// and then pass to respective input
const validations = {
  postalCode: {
    required: { value: true, message: 'Required ' },
    pattern: { value: postalCodeRegex, message: 'Enter a valid Postal Code' },
  },
};

// HOC to provide customer address inputs and verification
// customer needs to be passed form caller inorder to prepopulate fields
// NOTE: designed to be used with <CustomerAddressInputs />
// NOTE: any component can utilze the inputs and validation methods
// NOTE: there is no validation around if a address or customer address already exits (ie db rows)

export const useCustomerAddress = (props: AddressSectionProps): useCustomerAddress => {
  // TODO-rk: update when useGetHighlighterFromHighlighterApi is removed.
  // showcase-api gives address as a list, but highlighter-api gives a single value.
  const address: Address | undefined =
    props?.customer.address instanceof Array
      ? props.customer.address?.[0]
      : props?.customer.address;

  // const invalidStates = ['HI', 'AK'];
  // inputs
  const addressOne = useInput(address?.lineOne || '');
  const addressTwo = useInput(address?.lineTwo || '', {
    required: { value: false },
  });
  const city = useInput(address?.city || '');
  const postalCode = useInput(address?.postalCode || '', validations.postalCode);
  const country = useInput(address?.country || 'USA');
  const state = useInput(address?.state || '');
  const [suggestedAddress, setSuggestedAddress] = React.useState<
    AddressFormInputs | undefined
  >(undefined);
  const [isValidRecommendedAddress, setIsValidRecommendedAddress] =
    React.useState<boolean>(false);

  const formHasChanged = formHasBeenChanged([
    addressOne,
    addressTwo,
    city,
    postalCode,
    country,
    state,
  ]);

  // client-side validation
  const _validateForm = (): boolean => {
    return validateForm([addressOne, addressTwo, city, postalCode, country, state]);
  };

  // server-side address verification
  const _validateAddressValues = async () => {
    const result = await useSmartyStreetsValidation({
      addressOne: addressOne.value,
      addressTwo: addressTwo.value,
      city: city.value,
      postalCode: postalCode.value,
      country: country.value,
      state: state.value,
    });

    // check if a user tried to create an address in a different state to prompt the suggestion
    if (!result?.isValidState) {
      addressOne.setError(true);
      addressOne.setHelperText(
        'Cannot use address from Hawaii, Alaska, or outside of USA.'
      );
    }

    // check if shippable address
    if (!result?.isValidAddress) {
      addressOne.setError(true);
      addressOne.setHelperText('This address cannot be shipped to.');
    }

    // Check if POBox
    if (result?.isPoBox) {
      postalCode.setError(true);
      postalCode.setHelperText('Cannot use PO/CPO box');
    } else {
      postalCode.setError(false);
      postalCode.setHelperText('');

      // store the suggested address in state (or undefined if we couldn't get suggestion info)
      if (result && result?.suggestedAddress) {
        setSuggestedAddress(result.suggestedAddress);
        setIsValidRecommendedAddress(result.isValidRecommendation);
      } else {
        setSuggestedAddress(undefined);
      }
    }
    return result;
  };

  const _setInitialValuesToCurrentValues = (): void => {
    [addressOne, addressTwo, city, postalCode, country, state].forEach((input) => {
      input.setInitialValue(input.value);
    });
  };

  const _setInitialValuesFromAddress = (address: AddressFormInputs): void => {
    addressOne.setInitialValue(address?.addressOne ? address.addressOne : undefined);
    addressTwo.setInitialValue(address?.addressTwo ? address.addressTwo : undefined);
    city.setInitialValue(address?.city ? address.city : undefined);
    postalCode.setInitialValue(address?.postalCode ? address.postalCode : undefined);
    country.setInitialValue(address?.country ? address.country : undefined);
    state.setInitialValue(address?.state ? address.state : undefined);
  };

  const _setInputsFromAddress = (address: AddressFormInputs): void => {
    addressOne.setValue(address?.addressOne ? address.addressOne : undefined);
    addressTwo.setValue(address?.addressTwo ? address.addressTwo : undefined);
    city.setValue(address?.city ? address.city : undefined);
    postalCode.setValue(address?.postalCode ? address.postalCode : undefined);
    country.setValue(address?.country ? address.country : undefined);
    state.setValue(address?.state ? address.state : undefined);
  };

  const _getResponseDescriptions = (details: AddressResponseDetails): string[] => {
    const noticeList: string[] = [];
    if (details){
      if (details.deliveryPointValidationResults){
        for (const k of Object.keys(details.deliveryPointValidationResults)) {
          switch (k) {
            case "N":
              noticeList.push("Not a valid USPS Address");
              break;
            case "S":
              noticeList.push("Invalid USPS address due to unrecognised apartment/suite");
              break;
            case "D":
              noticeList.push("Invalid USPS Address due to missing apartment/suite");
              break;
          }
        }
      }
      if (details.deliveryPointValidationFootnotes){
        for (const [k, v] of Object.entries(details.deliveryPointValidationFootnotes)){
          if (k != "AA" && k != "BB" && k != "CC"){ // ignore valid footnotes
            noticeList.push(v);
          }
        }
      }
      if(details.footnotes){
        for (const v of Object.values(details.footnotes)){
          noticeList.push(`${v["note"]}: ${v["description"]}`)
        }
      }
    }
    return noticeList;
  };

  return {
    inputs: {
      addressOne: addressOne,
      addressTwo: addressTwo,
      city: city,
      postalCode: postalCode,
      country: country,
      state: state,
    },
    suggestedAddress: suggestedAddress,
    isValidRecommendedAddress: isValidRecommendedAddress,
    formHasChanged: formHasChanged,
    validateForm: _validateForm,
    updateInitialValuesToCurrentValues: _setInitialValuesToCurrentValues,
    setInitialValuesFromAddress: _setInitialValuesFromAddress,
    validateAddress: _validateAddressValues,
    setInputsFromAddress: _setInputsFromAddress,
    getResponseDescriptions: _getResponseDescriptions,
  };
};
