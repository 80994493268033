
function getPhoneFromMaskedValue(phone: string): string | undefined {
    try {
        // '+1 (___) ___-____'
        console.log(`getPhoneFromMaskedValue(${phone})`);
        // if we find an underscore (present from the mask), the phone is invalid
        if (phone?.indexOf('_') === -1) {
            return (
                // '+'
                // '1'
                // ' '
                // '('
                phone[4] +
                phone[5] +
                phone[6] +
                // ')'
                // ' '
                phone[9] +
                phone[10] +
                phone[11] +
                // '-'
                phone[13] +
                phone[14] +
                phone[15] +
                phone[16]
            );
        } else {
            return undefined;
        }
    } catch (error) {
        console.log(`ERROR getPhoneNumberFromState(${phone})`, error);
        return undefined;
    }
}

function applyMaskToPhone(phone: string): string | undefined {
    try {
        // '+1 (___) ___-____'
        console.log(`applyMaskToPhone(${phone})`);
        // if we find an underscore (present from the mask), the phone is invalid
        if (phone?.length === 10 && phone?.indexOf('_') === -1) {
            return '+' + '1' + ' ' + '(' + phone[0] + phone[1] + phone[2] + ')' + ' ' + phone[3] + phone[4] + phone[5] + '-' + phone[6] + phone[7] + phone[8] + phone[9];
        } else {
            return undefined;
        }
    } catch (error) {
        console.log(`ERROR getPhoneNumberFromState(${phone})`, error);
        return undefined;
    }
}

export { getPhoneFromMaskedValue, applyMaskToPhone }
