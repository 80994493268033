import React, { FC } from 'react';
import { Input, MenuItem, Typography } from 'components/shared';
import type { InputProps } from 'components/shared/Input';

export const CountrySelect: FC<InputProps> = (props: InputProps): JSX.Element => {
  return (
    <Input {...props} select>
      <MenuItem value="USA">
        <Typography variant="body2">United States</Typography>
      </MenuItem>
    </Input>
  );
};
