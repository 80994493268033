import { styled as muiStyled } from '@material-ui/core/styles';
import React from 'react';
import { PossibleAnswer } from 'types/Questions';

import { Button } from 'components/shared';

const StyledButton = muiStyled(Button)(({ theme }) => ({
  fontFamily: '"Work Sans", sans-serif',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 400,
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  borderWidth: '1px',
  borderStyle: 'solid',
  boxSizing: 'border-box',
  margin: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 0`,
  borderRadius: '25px',
  '& .MuiButton-label': {
    whiteSpace: 'normal',
  },
}));

export interface DemoQuestionsBubbleButtonProps {
  answer: PossibleAnswer;
  selected: boolean;
  onClick: (answer: PossibleAnswer) => void;
}
const DemoQuestionsBubbleButton = (
  props: DemoQuestionsBubbleButtonProps
): JSX.Element => {
  const { answer, onClick, selected } = props;
  const _onClick = (): void => onClick(answer);
  return (
    <StyledButton
      onClick={_onClick}
      variant={selected ? 'contained' : 'outlined'}
      color={selected ? 'primary' : 'inherit'}
      key={answer.id}
    >
      {answer.description}
    </StyledButton>
  );
};

export default DemoQuestionsBubbleButton;
