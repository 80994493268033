import { SWR_QUERY_KEY } from 'constants/api';
import {
  fetchHighlighterApi,
  swrHighlighterApiFetcher,
} from 'data/services/RequestMethods';
import { ErrorToastWithContactUsLink, ToastVariant, useToast } from 'hooks/useToast';
import useSWR, { useSWRConfig } from 'swr';
import { HighlighterAnswerDto, QuestionAnswers } from 'types/Answer';

const useSetHighlighterAnswers = () => {
  const { mutate } = useSWRConfig();
  const setHighlighterAnswers = async (
    questionAnswers: QuestionAnswers
  ): Promise<void> => {
    const apiAnswers = uiAnswersToApiAnswers(questionAnswers);
    try {
      await fetchHighlighterApi({
        route: '/my/highlighteranswers',
        method: 'PUT',
        data: apiAnswers,
      });
      await mutate(SWR_QUERY_KEY.GetAnswersHttp);
    } catch (error) {
      console.error(`error occurred when setting highlighter answers ${error}`);
      throw error;
    }
  };
  return { setHighlighterAnswers };
};
const useGetHighlighterAnswers = (email: string|undefined):
  | { questionAnswers: QuestionAnswers; isHlApiLoading: false }
  | { questionAnswers: undefined; isHlApiLoading: true } => {
  const { showToast } = useToast();
  const { data: answers, isLoading: isHlApiLoading } = useSWR<HighlighterAnswerDto[]>(
    [SWR_QUERY_KEY.GetAnswersHttp, email],
    ([url]) => swrHighlighterApiFetcher(url),
    {
      revalidateOnFocus: false,
      onError: (error) => {
        console.error(`error fetching highlighter answers ${error}`);
        showToast({
          text: <ErrorToastWithContactUsLink errorText={`Error fetching answers.`} />,
          variant: ToastVariant.Error,
        });
      },
    }
  );
  if (isHlApiLoading || answers == undefined) {
    return { questionAnswers: undefined, isHlApiLoading: true };
  }
  const questionAnswers = apiAnswersToUiAnswers(answers);

  return { questionAnswers, isHlApiLoading };
};

// transform answer shape from the api into answers the ui can use
const apiAnswersToUiAnswers = (apiAnswers: HighlighterAnswerDto[]): QuestionAnswers => {
  const uiAnswers: QuestionAnswers = {};
  apiAnswers.forEach((answer: HighlighterAnswerDto) => {
    const questionId = answer.questionId;
    uiAnswers[questionId] = {};
    answer.answerIds.forEach((answerId: string) => {
      uiAnswers[questionId]![answerId] = true;
    });
  });
  return uiAnswers;
};

// transform answer shape from the ui into answers the api can use
const uiAnswersToApiAnswers = (uiAnswers: QuestionAnswers): HighlighterAnswerDto[] => {
  const apiAnswers: HighlighterAnswerDto[] = [];
  for (const question in uiAnswers) {
    const answerIds = [];
    for (const answer in uiAnswers[question]) {
      if (uiAnswers?.[question]?.[answer]) answerIds.push(answer);
    }
    apiAnswers.push({ questionId: question, answerIds });
  }
  return apiAnswers;
};

const _testing = { apiAnswersToUiAnswers, uiAnswersToApiAnswers };
export { useGetHighlighterAnswers, useSetHighlighterAnswers, _testing };
