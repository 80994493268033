import Typography from '@material-ui/core/Typography';
import { useGetHighlighterAnswers, useSetHighlighterAnswers } from 'hooks/api/answers';
import { useGetHighlighterQuestions } from 'hooks/api/questions';
import React, { useEffect, useState } from 'react';
import { QuestionAnswers } from 'types/Answer';
import { HighlighterQuestion, PossibleAnswer } from 'types/Questions';

import { DemoQuestions } from 'components/DemoQuestions/DemoQuestions';
import { getUpdatedAnswers } from 'components/DemoQuestions/utils';
import { Box, Button, CircularProgress, Spinner } from 'components/shared';

import SectionHeader from './SectionHeader';
import { StepData } from './types';
import { Customer } from 'types/Customer';

type DemoQuestionsSectionProps = {
  nextStep: (data?: StepData) => void;
  customer: Customer;
};
const DemoQuestionsSection = (props: DemoQuestionsSectionProps): JSX.Element => {
  const { questions, isHlApiLoading: isGetQuestionsLoading } =
    useGetHighlighterQuestions();
  const {
    questionAnswers: originalQuestionAnswers,
    isHlApiLoading: isGetAnswersLoading,
  } = useGetHighlighterAnswers(props.customer.email);
  const { setHighlighterAnswers } = useSetHighlighterAnswers();

  const [isLoading, setIsLoading] = useState(false);

  const [currentQuestionAnswers, setCurrentQuestionAnswers] = useState<QuestionAnswers>({
    /*initialized via the use effect */
  });
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const isContinueDisabled = (questionId: string) => {
    return !Object.values(currentQuestionAnswers[questionId] ?? {}).includes(true);
  };

  useEffect(() => {
    setCurrentQuestionAnswers({ ...originalQuestionAnswers });
  }, [isGetAnswersLoading]);

  const _onSelect = (
    question: HighlighterQuestion,
    answer: PossibleAnswer,
    possibleAnswers: PossibleAnswer[]
  ) => {
    const updatedSelectedAnswers = getUpdatedAnswers(
      answer,
      currentQuestionAnswers[question.id] ?? {},
      possibleAnswers,
      question.allowMultiple
    );

    setCurrentQuestionAnswers({
      ...currentQuestionAnswers,
      [question.id]: updatedSelectedAnswers,
    });
  };

  const handleSetHighlighterAnswers = async (
    answers: QuestionAnswers[string],
    questionId: string
  ) => {
    const questionAnswersTobeSaved: QuestionAnswers = {
      [questionId]: {
        ...answers,
      },
    };
    setIsLoading(true);
    await setHighlighterAnswers(questionAnswersTobeSaved);
    setIsLoading(false);

    // next demo question or next onboarding step
    const nextQuestionIndex = activeQuestionIndex + 1;
    if (nextQuestionIndex >= questions!.length) {
      props.nextStep();
    } else {
      setActiveQuestionIndex(nextQuestionIndex);
    }
  };

  const _prevQuestion = (): void => {
    if (activeQuestionIndex > 0) {
      setActiveQuestionIndex((currentValue: number) => currentValue - 1);
    }
  };

  return (
    <>
      <SectionHeader
        title={`Let's fill out your profile!`}
        body={`We use these questions to match you with the perfect offers for you. Filling in the questions honestly
                and fully ensures you are eligible for the most offers.`}
      />
      {questions && questions[activeQuestionIndex] ? (
        <div>
          <DemoQuestions
            question={questions[activeQuestionIndex]}
            onSelectAnswer={_onSelect}
            currentAnswers={currentQuestionAnswers[questions[activeQuestionIndex].id]}
            onSave={handleSetHighlighterAnswers}
            useMobileStyles
            showHelpText={true}
          />
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '15px',
            }}
          >
            <Box marginRight="16px">
              <Button
                variant={'outlined'}
                color={'primary'}
                onClick={_prevQuestion}
                disabled={activeQuestionIndex == 0 || isLoading}
              >
                {'Go Back'}
              </Button>
            </Box>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() =>
                handleSetHighlighterAnswers(
                  currentQuestionAnswers[questions[activeQuestionIndex].id],
                  questions[activeQuestionIndex].id
                )
              }
              endIcon={isLoading ? <CircularProgress size={20} /> : undefined}
              disabled={isContinueDisabled(questions[activeQuestionIndex].id)}
            >
              {isLoading ? 'Loading' : 'Continue'}
            </Button>
          </Box>
        </div>
      ) : isGetQuestionsLoading || isGetAnswersLoading ? (
        <Spinner variant="highlight" />
      ) : (
        <Typography>{'We were unable to grab your questions'}</Typography>
      )}
    </>
  );
};

export default DemoQuestionsSection;
