import { Typography } from '@material-ui/core';
import React from 'react';
import { CustomerStatus } from 'types/Customer';

export interface PageHeaderProps {
  tab: 'active' | 'new';
  activeCount: number;
  newCount: number;
  customerStatus: CustomerStatus;
  customerName: string;
}

export const PageHeader = (props: PageHeaderProps): JSX.Element => {
  const { tab, customerName, customerStatus, activeCount, newCount } = props;
  let text = '';
  if (customerStatus === 'disabled' || customerStatus === 'banned') {
    text = `Sorry ${customerName}, you've been locked out.`;
  } else if (tab === 'new') {
    if (newCount > 0) {
      text = `${customerName}, check out these exciting Opt-Ins for you!`;
    } else {
      text = "We'll have our people contact your people.";
    }
  } else {
    // tab === 'active'
    if (activeCount > 0) {
      text = `${customerName}, let's put your feedback at the forefront!`;
    } else {
      text = `Ta-da! No more to-do's`;
    }
  }

  return <Typography variant="h3">{text}</Typography>;
};
