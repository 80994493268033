import { Box, Typography } from '@material-ui/core';
import { APP_CONSTANTS } from 'constants/AppConstants';
import { useHistory } from 'react-router-dom';
import { localStorage } from 'utils/localStorage';

import { GoogleLoginFrame } from 'components/login/GoogleLoginFrame';
import { PossibleIncorrectUserAccount } from 'components/login/PossibleIncorrectUserAccount';
import { Link } from 'components/shared';

interface WelcomeBackCardProps {
  onLogout: () => void;
  loginError: boolean;
}

export const WelcomeBackCard = (props: WelcomeBackCardProps) => {
  const { loginError, onLogout } = props;
  const history = useHistory();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="40vh"
      minHeight="330px"
    >
      {loginError && <PossibleIncorrectUserAccount onLogout={onLogout} />}

      <Box marginBottom="32px">
        <Typography variant="h4">
          Welcome back to Highlight,{' '}
          {localStorage.getItem(APP_CONSTANTS.StorageKeys.CustomerName)}!
        </Typography>
      </Box>

      <Box marginBottom="32px">
        <GoogleLoginFrame text="signin_with" context="login" />
      </Box>

      <Box marginBottom="32px">
        <Typography variant="caption">
          Not {localStorage.getItem(APP_CONSTANTS.StorageKeys.CustomerName)} or don&apos;t
          already have an account? Click
          <Link
            onClick={() => {
              localStorage.deleteItem(APP_CONSTANTS.StorageKeys.CustomerName);
              history.push(history.location.pathname);
            }}
            target="_blank"
            variant="inherit"
          >
            {' here'}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
