import { GoogleLoginText, LoginContext } from '../GoogleLoginButton';

interface GoogleLoginFrameProps {
  text: GoogleLoginText,
  context: LoginContext,
  referralCode?: string
}

export const GoogleLoginFrame = (props:GoogleLoginFrameProps): JSX.Element => {
  const { text, context, referralCode } = props;

  const host = window.location.host;
  const eph = host.includes('.eph.letshighlightdev.com') 
    ? host.substring(0, host.indexOf('-highlighter-portal'))
    : undefined;
  
  const url = process.env.REACT_APP_GOOGLE_LOGIN_BUTTON_SRC ?? `${window.location.origin}/signin`;
  const search = new URLSearchParams({ 
    text: text || '' as string,
    context: context ?? '' as string,
    refCode: referralCode ?? '',
    eph: eph ?? ''
  });

  const style = context == "signup" 
    ? { border: 0, marginRight: -23, verticalAlign: 'middle', height: '60px', width: '240px' } 
    : { border: 0, height: '60px', verticalAlign: 'middle' };

  return (
    <iframe style={style} src={`${url}?${search}`} data-testid='google-login-frame' />
  )
}
