// TODO: This is a copy of the component from showcase-ui
// Refactor this when we can
import React from 'react';
import { Typography } from '@material-ui/core';
import { EmptyStateProps } from './types';
import BaseEmptyState from './Base';

const DefaultEmptyState = ({ icon, title, body, footnote }: EmptyStateProps): JSX.Element => {
    return (
        <BaseEmptyState
            icon={icon}
            title={<Typography variant="h5">{title}</Typography>}
            body={body ? <Typography variant="body1">{body}</Typography> : undefined}
            footnote={footnote ? <Typography variant="body1">{footnote}</Typography> : undefined}
        />
    );
};

export default DefaultEmptyState;