import { useHistory } from 'react-router-dom';
import { AuthenticationHandler, loginError, signUpError, referralError } from './useAuth';
import { useToast, ToastVariant } from './useToast';
import { GoogleLoginSuccess, GoogleLoginFailure } from 'components/login/GoogleLoginButton/GoogleLoginEventHandlers';
import { APP_CONSTANTS } from 'constants/AppConstants';
import { LoginContext } from 'components/login/GoogleLoginButton';

export const useGoogleLoginHandler = (authenticate:AuthenticationHandler) => {
  const history = useHistory();
  const { showToast } = useToast();

  const handleSuccess = async (message:GoogleLoginSuccess) => {
    const { idToken, context, referralCode } = message;

    switch (context) {
      case 'login':
        try {          
          await authenticate(idToken);
          history.push('/home');
        } catch {
          onError(message.context);
        }        
        break;
      case 'signup':
        if (referralCode) {
          try {
            await authenticate(idToken);
            const state = { 
              actionType: APP_CONSTANTS.ONBOARDING.APP_STATE_SIGNUP_VALUE,
              params: {
                referralCode: referralCode,
                referralCodeId: referralCode,
              },
            };
            history.push('/profile', state);
          } catch {
            onError(message.context);
          }
        } else {
          history.push('/login');
          showToast({ variant: ToastVariant.Error, text: referralError });
        }
        break;
    }
  }

  const handleFailure = (message:GoogleLoginFailure) => onError(message.context);

  const onError = (context:LoginContext) => {
    switch (context) {
      case 'login':
        history.push('/login');
        showToast({ variant: ToastVariant.Error, text: loginError });
        break;
      case 'signup':
        history.push('/login');
        showToast({ variant: ToastVariant.Error, text: signUpError });
        break;
    }
  }

  return { handleSuccess, handleFailure }
}