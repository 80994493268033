import { SURVEY_VENDOR } from 'constants/AppConstants';
import { useGetSurveys, useUpdateSurveys } from 'hooks/api/surveys';
import { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AuthenticatedUser } from 'types/Authentication';
import { Customer } from 'types/Customer';
import { ActiveSurveyInstance, SurveyStatus } from 'types/Offer';
import { getFeedbackBadgeValue, parseSurveyVendorUrl } from 'utils/offersUtils';

import { usePageStyles } from 'components/PageElements';
import Survey from 'components/Survey';
import { StatusChangePayload } from 'components/SurveyIFrame/types';
import { WalkthroughModalManager } from 'components/WalkthroughManager/WalkthroughManager';
import { ActiveOffers } from 'components/offers/ActiveOffers';
import { NewOffers } from 'components/offers/NewOffers';
import { PageHeader } from 'components/offers/PageHeader';
import { Box, Spinner, Tab, TabPanel, Tabs } from 'components/shared';

export interface OffersViewProps extends RouteComponentProps {
  customer: Customer;
  user: AuthenticatedUser;
}

const Offers = (props: OffersViewProps): JSX.Element => {
  const pageClasses = usePageStyles();
  const params = props?.history?.location?.state as
    | { initialTabIndex?: number }
    | undefined;
  const [tabIndex, setTabIndex] = useState<number>(params?.initialTabIndex || 0);
  const [timeoutIds, setTimeoutIds] = useState<number[]>([]);
  const [activeSurveyInstance, setActiveSurveyInstance] =
    useState<ActiveSurveyInstance | null>(null);
  const [formModalVisible, setFormModalVisible] = useState(false);

  const [vendor] = useMemo(
    () => parseSurveyVendorUrl(activeSurveyInstance?.surveyUrl ?? '') ?? [],
    [activeSurveyInstance]
  );
  const shouldRevalidateOnClose = useMemo(
    () => vendor === SURVEY_VENDOR.TYPEFORM,
    [vendor]
  );

  const { updateSurvey } = useUpdateSurveys();
  const {
    isHlApiLoading: isGetSurveysLoading,
    optInSurveyInstances,
    itemSurveyInstances,
    oneOffSurveyInstances,
    revalidateSurveyInstances,
  } = useGetSurveys();

  useEffect(() => {
    // clear timer on unmount
    return (): void => {
      if (timeoutIds.length > 0) {
        timeoutIds.forEach((id: number) => {
          clearTimeout(id);
        });
      }
    };
  }, [timeoutIds]);

  const openSurveyModal = (data: ActiveSurveyInstance): void => {
    setActiveSurveyInstance({ ...data });
    setFormModalVisible(true);
  };
  const _onCloseForm = (): void => {
    if (shouldRevalidateOnClose) {
      revalidateSurveyInstances();
    }
    setActiveSurveyInstance(null);
    setFormModalVisible(false);
  };

  const closeSurveyModal = (): void => {
    const formCloseDelay = 6000;
    const timerId = window.setTimeout(_onCloseForm, formCloseDelay);
    setTimeoutIds((prevIds: number[]) => {
      return [...prevIds, timerId];
    });
  };

  const surveyStatusUpdatedSuccessfully = async (
    eventType: SurveyStatus
  ): Promise<void> => {
    if (!activeSurveyInstance?.id) {
      return;
    }

    await updateSurvey({
      surveyInstanceId: activeSurveyInstance.id,
      req: { data: { status: eventType } },
      shouldSkipRefetch: shouldRevalidateOnClose,
    });
  };

  const _onStatusChange = async (event: StatusChangePayload): Promise<void> => {
    const responseId = event.surveyId;
    const eventType = event.eventType;
    const surveyCompleted =
      eventType === SurveyStatus.Completed || eventType === SurveyStatus.Disqualified || eventType === SurveyStatus.PendingResponse;

    if (responseId && activeSurveyInstance) {
      // only allow specific event types / survey statuses
      if (
        eventType === SurveyStatus.Started ||
        eventType === SurveyStatus.Disqualified ||
        eventType === SurveyStatus.Completed ||
        eventType === SurveyStatus.PendingResponse
      ) {
        // auto close
        if (surveyCompleted && !shouldRevalidateOnClose) {
          closeSurveyModal();
        }

        // setup api request
        await surveyStatusUpdatedSuccessfully(eventType);
      } else {
        console.error(
          `invalid surveys status was passed to _onStatusChange(${eventType})`
        );
      }
    } else {
      // we couldn't get the response id or activeSurveyInstance
      console.error(
        `Offers Error: onSubmitForm - Unable to update survey status: response_id or ActiveSurveyInstance is invalid`
      );
    }
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  if (isGetSurveysLoading) {
    return <Spinner variant="highlight" />;
  }

  return (
    <Box className={pageClasses.page}>
      <Box mb="25px">
        <PageHeader
          tab={tabIndex === 1 ? 'active' : 'new'}
          activeCount={itemSurveyInstances.length}
          newCount={optInSurveyInstances.length}
          customerName={props.customer.firstName}
          customerStatus={props.customer.status}
        />
      </Box>

      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="tabs"
        TabIndicatorProps={{
          style: {
            backgroundColor: '#FFBF00',
          },
        }}
      >
        <Tab label="Opt-Ins" badgeValue={optInSurveyInstances?.length} />
        <Tab
          label="Feedback"
          badgeValue={getFeedbackBadgeValue(oneOffSurveyInstances, itemSurveyInstances)}
        />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <NewOffers
          customerStatus={props.customer.status}
          optInSurveyInstances={optInSurveyInstances}
          onPressOptInSurvey={openSurveyModal}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <ActiveOffers
          customerStatus={props.customer.status}
          itemSurveys={itemSurveyInstances}
          oneOffSurveys={oneOffSurveyInstances}
          onPressItemSurvey={openSurveyModal}
        />
      </TabPanel>

      {formModalVisible && activeSurveyInstance?.surveyUrl ? (
        <Survey
          customer={props.customer}
          onClose={_onCloseForm}
          onStatusChange={_onStatusChange}
          surveyInstance={activeSurveyInstance}
        />
      ) : undefined}

      <WalkthroughModalManager customer={props.customer} />
    </Box>
  );
};

export default Offers;
