const supported = !(typeof Storage === 'undefined');

function setItem(key: string, item: any): boolean {
    try {
        const itemAsString = JSON.stringify(item);
        window.localStorage.setItem(key, itemAsString);
        return true;
    } catch (error) {
        return false;
    }
}

function getItem(key: string): any | undefined {
    try {
        const item = window.localStorage.getItem(key);
        if (item) {
            return JSON.parse(item);
        } else {
            return undefined;
        }
    } catch (error) {
        return undefined;
    }
}

function deleteItem(key: string): boolean {
    try {
        window.localStorage.removeItem(key);
        return true;
    } catch (error) {
        return false;
    }
}

export const localStorage = {
    supported,
    getItem,
    setItem,
    deleteItem,
};
