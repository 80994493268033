import React, { useEffect, useState } from 'react';
import IFrame from 'components/iFrame';
import { StyledModal } from 'components/shared';
import { CloseButton } from './CloseButton';
import { SurveyPlatform } from './SurveyPlatforms/types';
import { surveyPlatforms } from './SurveyPlatforms/platforms';
import { SurveyModalProps } from './types';
import { getPlatformBySurveyUrl } from './SurveyPlatforms/utils';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SurveyStatus } from 'types/Offer';

const SurveyModal = (props: SurveyModalProps): JSX.Element => {
  console.log('SurveyModal: rendering with props', { props });
  const [surveyPlatform] = useState<SurveyPlatform>(getPlatformBySurveyUrl(props.src, surveyPlatforms));
  const surveyUrl = surveyPlatform.getFullSurveyUrl(
    props.src,
    props.data.userId,
    props.data.userFirstName,
    props.data.surveyId,
    props.data.surveyType
  );
  const uiTheme = useTheme();
  const smAndUpScreen = useMediaQuery(uiTheme.breakpoints.up('sm'));

  // handles a window event 'message' from the survey platform's iFrame
  // debug hint: window.addEventListener('message', (msg) => console.log('msg event => ', msg)) for logging all message events
  const _onMessageReceived = (event: MessageEvent<any>) => {
    console.log(`_onMessageReceived(): event.data.type = "${event?.data?.type}", from ${event.origin}`, {
      data: event.data,
      event,
    });

    // only look at events from allowed origin
    if (event.origin === surveyPlatform.originUrl) {
      const payloadType: string | undefined = event?.data?.type;
      console.log(`_onMessageReceived(): event.data.type = "${payloadType}", from ${event.origin}`, {
        data: event.data,
        event,
      });

      // look for specific events
      // note: platforms added in the future may need have the evet.data handler be on the platform config obj
      if (payloadType) {
        // when the caught event match the platform's event name/type
        // map from platform event to db friendly event
        if (payloadType === surveyPlatform.eventTypes.disqualified) {
          const payloadIsVerified = surveyPlatform.verifyPostMessagePayload('disqualified', event.data);
          if (payloadIsVerified) {
            props.onStatusChange({
              eventType: SurveyStatus.Disqualified,
              userId: props.data.userId,
              surveyId: props.data.surveyId,
            });
          }
        } else if (payloadType === surveyPlatform.eventTypes.completed) {
          const payloadIsVerified = surveyPlatform.verifyPostMessagePayload('completed', event.data);
          if (payloadIsVerified) {
            props.onStatusChange({
              eventType: SurveyStatus.Completed,
              userId: props.data.userId,
              surveyId: props.data.surveyId,
            });
          }
        }
      }
    }
  };

  // Always make the API call to update the survey status to "started" when the modal is opened
  // This is meant to occur upon receiving a 'started' message from Alchemer,
  // however in practice this 'started' message wasn't always occurring. This is an override
  useEffect(() => {
    const setSurveyStatusToSent = async (): Promise<void> => {
      if (props.data.surveyId) {
        props.onStatusChange({
          eventType: SurveyStatus.Started,
          userId: props.data.userId,
          surveyId: props.data.surveyId,
        });
      }
    };

    setSurveyStatusToSent();
  }, []);

  // setup event listener for post messages
  useEffect(() => {
    const listenerOptions: boolean | AddEventListenerOptions | undefined = false;

    // listen to postMessages from the platform's iFrame
    window.addEventListener('message', _onMessageReceived, listenerOptions);

    return () => {
      window.removeEventListener('message', _onMessageReceived, listenerOptions);
    };
  }, []);

  return (
    <StyledModal
      onClose={props.onClose}
      open={props.modalIsOpen}
      fullScreen={true}
      style={{ padding: smAndUpScreen ? 50 : 0 }}
    >
      <CloseButton onClose={props.onClose} />
      <IFrame src={surveyUrl} onLoad={props.onIframeLoad} title={`${surveyPlatform.name} survey`} id={`${surveyPlatform.name}-survey-iframe`} />
    </StyledModal>
  );
};

export default SurveyModal;
