import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const redirectToLogin = ({ showAuthError, from }: { showAuthError: boolean; from: string }): JSX.Element => {
    return (
        <Redirect
            to={{
                pathname: '/login',
                state: {
                    reason: showAuthError ? 'unauthenticated' : undefined,
                    from: from,
                },
            }}
        />
    );
};

// eslint-disable-next-line
const PrivateRoute = (props: any): JSX.Element => {
    const { component: Component, user, customer, skipCustomerCheck, ...rest } = props;
    return (
        <Route
            {...rest}
            render={(renderProps): JSX.Element => {
                if (user?.email) {
                    // skip used to enable customer creation
                    if (customer?.email || skipCustomerCheck) {
                        return <Component {...renderProps} {...props} />;
                    } else {
                        return redirectToLogin({ showAuthError: true, from: props.location });
                    }
                } else {
                    return redirectToLogin({ showAuthError: false, from: props.location });
                }
            }}
        />
    );
};

export default PrivateRoute;
