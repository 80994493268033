import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiLink, { LinkProps as MuiLinkProps } from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props: MuiLinkProps): string =>
      props.color === 'secondary'
        ? theme.palette.secondary.main
        : props.color === 'primary'
        ? theme.palette.primary.main
        : props.color === 'textSecondary'
        ? theme.palette.common.white
        : theme.palette.text.primary,
    '&&:hover': {
      color: (props: MuiLinkProps): string =>
        props.color === 'secondary' || props.color === 'textSecondary'
          ? theme.palette.secondary.light
          : props.color === 'primary'
          ? theme.palette.primary.light
          : theme.palette.text.primary,
    },
  },
}));

export const Link = (props: MuiLinkProps) => {
  const classes = useStyles({ ...props, color: props.color || 'secondary' });
  const { children, underline = 'none', onClick, ...rest } = props;

  return (
    <MuiLink
      classes={{ root: classes.root }}
      underline={underline}
      onClick={onClick}
      {...rest}
    >
      {children}
    </MuiLink>
  );
};
