import { SnackbarProvider, closeSnackbar } from 'notistack';
import React from 'react';

import { Button } from 'components/shared';

export const ToastProvider = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={10000}
      action={(key) => (
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          Dismiss
        </Button>
      )}
      //? Mui styles isn't applying to the snackbar, so we have to do it manually
      style={{
        maxWidth: '600px',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        padding: 20,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
