import { fetchHighlighterApi } from 'data/services/RequestMethods';
import { Address } from 'types/Customer';

const useUpdateAddresses = () => {
  const updateAddress = async (address: Address): Promise<Response> => {
    if (address.id == null || address.id == '') {
      console.warn('can not update an address without an id.');
    }
    try {
      const result = await fetchHighlighterApi({
        route: '/my/highlighteraddresses',
        method: 'PUT',
        data: address,
      });
      return result;
    } catch (error) {
      console.error(`error occurred when updating address info ${error}`);
      throw error;
    }
  };
  return { updateAddress };
};

const useCreateAddresses = () => {
  const createAddress = async (address: Address): Promise<Response> => {
    try {
      const result = await fetchHighlighterApi({
        route: '/my/highlighteraddresses',
        method: 'POST',
        data: address,
      });
      return result;
    } catch (error) {
      console.error(`error occurred when updating address info ${error}`);
      throw error;
    }
  };
  return { createAddress };
};

const useVerifyAddress = () => {
  const verifyAddress = async (address: Address): Promise<Response> => {
    try {
      const result = await fetchHighlighterApi({
        route: '/my/highlighteraddresses/verification',
        method: 'POST',
        data: address,
      });
      return result;
    } catch (error) {
      console.error(`error occured when verifying address ${error}`);
      throw error;
    }
  };
  return { verifyAddress };
};

export { useUpdateAddresses, useCreateAddresses, useVerifyAddress };
