import { SurveyType } from 'types/Offer';
import { SurveyImage } from 'components/offers/SurveyImage';
import { Divider, Typography } from 'components/shared';

export default function TestPage() {
  return (
    <>
      <Typography variant="h3">Test Page</Typography>
      <Typography variant="h6">
        This page is available on localhost & dev to help debug strange components
      </Typography>
      <Divider
        style={{
          width: '100%',
          backgroundColor: 'black',
          marginTop: 30,
          marginBottom: 30,
        }}
      />
      <SurveyImage surveyType={SurveyType.Item} alt="whatever" badge={{ value: 444, text: 'Days Left!' }} />
    </>
  );
}
