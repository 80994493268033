import React, { FC } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Tab, TabPanel, Tabs } from 'components/shared';

interface ProfileTabProps {
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  activeIndex: number;
  children: Array<JSX.Element>;
}

const ProfileTabs: FC<ProfileTabProps> = (props: ProfileTabProps): JSX.Element => {
  const uiTheme = useTheme();
  const smAndUpScreen = useMediaQuery(uiTheme.breakpoints.up('sm'));
  const { activeIndex, onChange } = props;
  return (
    <>
      <Tabs
        value={activeIndex} 
        // Using any due to an issue with the version of mui we're using
        // https://github.com/mui/material-ui/issues/17454
        onChange={onChange as any}
        aria-label={'tabs'}
        indicatorColor={'primary'}
        style={{ marginBottom: 25 }}
      >
        <Tab
          label={'Settings'}
          // @ts-ignore
          id={'settings-tab-button'}
        />
        <Tab
          label={smAndUpScreen ? 'Preferences' : 'Prefs'}
          // @ts-ignore
          id={'preferences-tab-button'}
        />
      </Tabs>
      <TabPanel value={activeIndex} index={0}>
        {props.children[0]}
      </TabPanel>
      <TabPanel value={activeIndex} index={1}>
        {props.children[1]}
      </TabPanel>
    </>
  );
};

export { ProfileTabs };
