const baseUrl = 'https://www.letshighlight.com';

const LINKS = {
    APPLY_TO_JOIN_LINK: 'https://apply.letshighlight.com/',
    PRIVACY_POLICY_LINK: baseUrl + '/privacy-policy',
    TOS_LINK: baseUrl + '/terms-of-service',
    CONSUMER_SUPPORT_LINK: 'https://help.app.letshighlight.com',
    HIGHLIGHT_HOME_LINK: baseUrl + '',
    CAPABILITIES_LINK: baseUrl + '/capabilities',
    RESOURCES_LINK: baseUrl + '/resources',
    HIGHLIGHTERS_LINK: baseUrl + '/highlighter',
    CONSUMER_CONTACT_FORM_LINK: baseUrl + '/highlighter/contact/',
    TYPEFORM_BASE_LINK: 'https://showcaseinsights.typeform.com',
    YPC_LINK: baseUrl + '/your-privacy-choices/',
    CN_LINK: baseUrl + '/collection-notice/',
};

const ONBOARDING = {
    APP_STATE_SIGNUP_VALUE: 'signup', // passed to auth0 loginWithRedirect and used for signup logic
    SIGNUP_CODE_STORAGE_KEY: 'signupReferralCode',
    PHONE_VERIFIED_STORAGE_KEY: 'phoneVerified',
    PHONE_STORAGE_KEY: 'phone',
    DOB_STORAGE_KEY: 'dob',
    CUSTOMER_STORAGE_KEY: 'customer',
};

export enum StorageKeys {
    CustomerName = 'customerName'
}

export const SURVEY_VENDOR = {
  ALCHEMER: 'alchemer',
  TYPEFORM: 'typeform',
} as const;

//
// TO: make LINKS a prop on APP_CONSTANTS and
// update imports in files that utilize
export const APP_CONSTANTS = {
    StorageKeys,
    ...LINKS,
    ONBOARDING,
};
