import React from 'react';
import { ColumnProps } from './types';
import { getBorderStyle } from './utils';
import styles from './styles.module.scss';

const Column = ({
    borderBottom,
    borderTop,
    borderLeft,
    borderRight,
    children,
    onClick,
    style,
    className,
}: ColumnProps): JSX.Element => {
    const classes =
        styles.column +
        getBorderStyle(borderTop, borderBottom, borderLeft, borderRight) +
        (className ? ' ' + className : '');
    return (
        <div style={style} onClick={onClick} className={classes}>
            {children}
        </div>
    );
};

export default Column;
