// material-ui
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useFeatureFlag } from 'configcat-react';
import { APP_CONSTANTS } from 'constants/AppConstants';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { localStorage } from 'utils/localStorage';

import { usePageStyles } from 'components/PageElements';
import { LoginCard } from 'components/login/LoginCard';
import { SignupCard } from 'components/login/SignupCard';
import { WelcomeBackCard } from 'components/login/WelcomeBackCard';
import { Box, Divider, Spinner } from 'components/shared';

interface State {
  reason?: string;
}

export const Login = (props: { logout: (returnUrl: string) => void }): JSX.Element => {
  const { logout } = props;
  const history = useHistory();
  const { value: isSignupDisabled, loading: isFeatureFlagLoading } = useFeatureFlag(
    'isSignupDisabled',
    false
  );
  const uiTheme = useTheme();
  const pageClasses = usePageStyles();
  const mdAndUpScreen = useMediaQuery(uiTheme.breakpoints.up('md'));
  const loginError = (history.location.state as State)?.reason === 'unauthenticated';

  const handleLogout = () => {
    logout(window.location.origin);
  };

  //TODO: We also do this in App.tsx, does it need to be in both places
  const hasCustomerName =
    localStorage.getItem(APP_CONSTANTS.StorageKeys.CustomerName) !== undefined;

  if (isFeatureFlagLoading) return <Spinner variant="highlight" />;

  return (
    <Box className={pageClasses.page}>
      <Box
        display="flex"
        // Info: This ternary is because the original Login page's design was unintuitive -- we should rethink this if we ever redesign the page
        flexDirection={mdAndUpScreen ? 'row' : loginError ? 'column-reverse' : 'column'}
        justifyContent="center"
      >
        {hasCustomerName ? (
          <WelcomeBackCard loginError={loginError} onLogout={handleLogout} />
        ) : (
          <>
            <SignupCard loginError={loginError} isSignupDisabled={isSignupDisabled} />

            {mdAndUpScreen && (
              <Divider
                data-testid="login-divider"
                orientation="vertical"
                flexItem
                style={{ backgroundColor: 'black', margin: '64px 112px 64px 112px' }}
              />
            )}

            <Box display="flex" flexDirection="column" flex={1} justifyContent="center">
              <LoginCard loginError={loginError} onLogout={handleLogout} />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Login;
