import { makeStyles } from '@material-ui/core/styles';

export const usePageStyles = makeStyles((theme) => ({
  page: {
    width: '100%',
    maxWidth: theme.breakpoints.values.lg + 'px',
    padding: theme.spacing(3.875) + 'px ' + theme.spacing(2) + 'px',
    [theme.breakpoints.up('md')]: {
      padding:
        theme.spacing(3) +
        'px ' +
        theme.spacing(11) +
        'px 0px ' +
        theme.spacing(11) +
        'px',
    },
    [theme.breakpoints.up('lg')]: {
      padding:
        theme.spacing(3) +
        'px ' +
        theme.spacing(11) +
        'px 0px ' +
        theme.spacing(11) +
        'px',
      margin: '0 auto',
    },
  },
}));
