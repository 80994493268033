/*
 * TO ADD A NEW PLATFORM
 *  1. Add name to SurveyPlatformName type
 *  2. Add PostMessageOnReady event type and PostMessageOnSubmit event type if needed
 *  3. Add confg obj with required values and url resolver below
 *  4. Add to exported surveyPlatforms obj
 */
import { MessagePayload, SurveyPlatform, SurveyPlatforms, SurveyStatus } from './types';
import { getFullSurveyUrlByPlatform, verifyPayloadByPlatform } from './utils';
import { SurveyType } from 'types/Offer';

//
// individual platform configurations
//
const typeformPlatform: SurveyPlatform = {
  name: 'typeform',
  originUrl: 'https://showcaseinsights.typeform.com',
  hostName: 'showcaseinsights.typeform.com',
  eventSystem: 'iframe-post-message',
  eventTypes: {
    started: 'form-ready',
    disqualified: undefined,
    completed: 'form-submit',
  },
  getFullSurveyUrl: function(
    url: string,
    userId: string,
    firstName: string,
    surveyId: string,
    surveyType: SurveyType
  ): string {
    return getFullSurveyUrlByPlatform(
      this.name,
      url,
      userId,
      firstName,
      surveyId,
      surveyType
    );
  },
  verifyPostMessagePayload: function(
    messageType: SurveyStatus,
    messagePayload: MessagePayload
  ): boolean {
    return verifyPayloadByPlatform(this.name, messageType, messagePayload);
  },
};

const alchemerPlatform: SurveyPlatform = {
  name: 'alchemer',
  originUrl: 'https://survey.alchemer.com',
  hostName: 'survey.alchemer.com',
  eventSystem: 'iframe-post-message',
  eventTypes: {
    started: 'started',
    disqualified: 'disqualified',
    completed: 'form-submit',
  },
  getFullSurveyUrl: function(
    url: string,
    userId: string,
    firstName: string,
    surveyId: string,
    surveyType: SurveyType
  ): string {
    return getFullSurveyUrlByPlatform(
      this.name,
      url,
      userId,
      firstName,
      surveyId,
      surveyType
    );
  },
  verifyPostMessagePayload: function(
    messageType: SurveyStatus,
    messagePayload: MessagePayload
  ): boolean {
    return verifyPayloadByPlatform(this.name, messageType, messagePayload);
  },
};

// platform [key] is used to grab platform config/info in survey modal
export const surveyPlatforms: SurveyPlatforms = [typeformPlatform, alchemerPlatform];
