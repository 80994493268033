// TODO: This is a copy of the component from showcase-ui
// refactor this when we have the time
import {
  Box,
  Stepper as MuiStepper,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Theme,
  makeStyles,
} from '@material-ui/core';
import {
  Business as BusinessIcon,
  Person as PersonIcon,
  PhoneIphone as PhoneIphoneIcon,
  Room as RoomIcon,
} from '@material-ui/icons';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  stepperWrapper: {
    width: '100%',
  },

  // StepConnector Styles
  root: {
    width: '100%',
    maxWidth: '80px',
    marginLeft: '10px',
    marginRight: '10px',
    border: '0',
  },
  line: {
    height: '2px',
    width: '100%',
    border: '0',
    backgroundColor: theme.palette.action.disabledBackground,
    '&$active': {
      backgroundColor: theme.palette.primary.main,
    },
    '&$completed': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  active: {}, // needed so that the &$active works
  completed: {}, // needed so that the &$completed works

  // StepLabel Styles
  stepLabel: {
    paddingRight: 0,
    backgroundColor: theme.palette.common.white,
  },

  // Icon Styles
  iconRoot: {
    // TODO see if we can delete the zindex
    zIndex: 1,
    padding: 0,
    margin: 0,
    color: theme.palette.action.disabled,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  iconActive: {
    color: theme.palette.primary.main,
  },
  iconCompleted: {
    color: theme.palette.primary.main,
  },

  // shared styles
  noPaddingOrMargin: {
    padding: 0,
    margin: 0,
  },
}));

const icons: { [index: string]: React.ReactElement } = {
  1: <PhoneIphoneIcon />,
  2: <PersonIcon />,
  3: <BusinessIcon />,
  4: <RoomIcon />,
};

export type StepperProps = {
  steps: string[];
  activeStep: number;
};

export const Stepper: FunctionComponent<StepperProps> = ({
  steps,
  activeStep,
}: StepperProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.stepperWrapper}>
      <MuiStepper
        className={classes.noPaddingOrMargin}
        activeStep={activeStep}
        connector={
          <StepConnector
            classes={{
              root: classes.root,
              line: classes.line,
              active: classes.active,
              completed: classes.completed,
            }}
          />
        }
      >
        {steps.map((label) => (
          <Step className={classes.noPaddingOrMargin} key={label}>
            <StepLabel
              classes={{ iconContainer: classes.stepLabel }}
              StepIconComponent={(stepIconProps: StepIconProps): JSX.Element => {
                let className = classes.iconRoot;
                if (stepIconProps.active) className += ` ${classes.iconActive}`;
                if (stepIconProps.completed) className += ` ${classes.iconCompleted}`;
                return (
                  <Box className={className}>{icons[String(stepIconProps.icon)]}</Box>
                );
              }}
            />
          </Step>
        ))}
      </MuiStepper>
    </Box>
  );
};
