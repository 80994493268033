import { APP_CONSTANTS } from 'constants/AppConstants';

export type PrivateRouteName =
  | 'home'
  | 'help-faq'
  | 'invite-friends'
  | 'logout'
  | 'preferences'
  | 'settings';
export type PublicRouteName = 'return' | 'help-faq' | 'login' | 'join' | 'logout';

const ROUTE_NAME_MAP: Record<PrivateRouteName | PublicRouteName, string> = {
  home: 'Home',
  'help-faq': 'Help/FAQ',
  'invite-friends': 'Invite Friends',
  join: 'Apply to join',
  login: 'Log in',
  logout: 'Logout',
  preferences: 'Preferences',
  return: 'Return to letshighlight.com',
  settings: 'Settings',
};

const getRouteName = (
  routeName: PrivateRouteName | PublicRouteName
): string | undefined => {
  return ROUTE_NAME_MAP[routeName] || undefined;
};

const onClickPrivateRoute = ({
  isAuthenticated,
  onExternalNav,
  onLogout,
  onNavigate,
  routeName,
}: {
  isAuthenticated: boolean;
  onExternalNav: (url: string, shouldOpenNewTab: boolean) => void;
  onLogout: () => void;
  onNavigate: (route: string, tab?: 'settings' | 'preferences') => void;
  routeName: PrivateRouteName;
}) => {
  if (!isAuthenticated) return;

  switch (routeName) {
    case 'help-faq':
      onExternalNav(APP_CONSTANTS.CONSUMER_SUPPORT_LINK, true);
      break;
    case 'invite-friends':
    case 'home':
      onNavigate(`/${routeName}`);
      break;
    case 'logout':
      onLogout();
      break;
    case 'preferences':
      onNavigate('/profile', 'preferences');
      break;
    case 'settings':
      onNavigate('/profile', 'settings');
      break;
    default:
      const exhaustiveCheck: 'exhaustiveCheck' = routeName;
      console.error(
        'onClickPrivateRoute was called with an invalid route: ',
        exhaustiveCheck
      );
      break;
  }
};

const onClickPublicRoute = ({
  onExternalNav,
  onLogout,
  routeName,
}: {
  onExternalNav: (url: string, shouldOpenNewTab: boolean) => void;
  onLogout: () => void;
  routeName: PublicRouteName;
}) => {
  switch (routeName) {
    case 'help-faq':
      onExternalNav(APP_CONSTANTS.CONSUMER_SUPPORT_LINK, true);
      break;
    case 'join':
      onExternalNav(APP_CONSTANTS.APPLY_TO_JOIN_LINK, false);
      break;
    case 'login':
      break;
    case 'logout':
      onLogout();
      break;
    case 'return':
      onExternalNav(APP_CONSTANTS.HIGHLIGHT_HOME_LINK, false);
      break;
    default:
      const exhaustiveCheck: 'exhaustiveCheck' = routeName;
      console.error(
        'onClickPublicRoute was called with an invalid route: ',
        exhaustiveCheck
      );
      break;
  }
};

const getProfilePageTabIndex = (
  tabName?: 'settings' | 'preferences'
): { initialTabIndex: number } | undefined => {
  if (tabName === 'preferences') return { initialTabIndex: 1 };
  if (tabName === 'settings') return { initialTabIndex: 0 };
  else return;
};

export { getRouteName, onClickPrivateRoute, onClickPublicRoute, getProfilePageTabIndex };
