import { AuthenticatedUser } from 'types/Authentication';
import { Customer } from 'types/Customer';
import { isNewCustomer } from 'utils/profileUtils';

import CompletedProfile from 'components/profile/CompletedProfile';
import FirstTimeProfile from 'components/profile/FirstTimeProfile';
import { useProfile } from 'components/profile/useProfile';
import { Spinner } from 'components/shared';

interface ProfileProps {
  customer?: Customer;
  user?: AuthenticatedUser;
  accessToken: string | null;
  refetchCustomer?: () => void;
}

export default function Profile(props: ProfileProps) {
  const { customer: existingCustomer, user, accessToken, refetchCustomer } = props;
  const { customer, isCreatingCustomer } = useProfile({
    existingCustomer,
    user,
    accessToken,
  });
  console.log('use profile hook thinks customer is ', customer, customer?.status);
  const isNewUser = isNewCustomer(customer);

  // TODO: refetchCustomer should be able to be removed after we refactor the profile pages
  if (isCreatingCustomer || !customer || !refetchCustomer || !user) {
    return <Spinner variant="highlight" />;
  }

  return (
    <>
      {isNewUser ? (
        <FirstTimeProfile
          user={user}
          customer={customer}
          refetchCustomer={refetchCustomer}
        />
      ) : (
        <CompletedProfile
          user={user}
          customer={customer}
          refetchCustomer={refetchCustomer}
        />
      )}
    </>
  );
}
