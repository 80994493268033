import { FontFace } from 'csstype';

/* recoleta-500normal - latin */
export const Recoleta500Normal: FontFace = {
    fontFamily: 'Recoleta',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
        local('Recoleta Medium'),
        local('Recoleta-Medium'),
        url(/fonts/Latinotype-Recoleta-Medium.otf) format('truetype')
    `,
};