import { useTheme } from '@material-ui/core';
import { useUpdateHighlighter } from 'hooks/api/highlighter';
import { useSendPhoneVerificationCode } from 'hooks/api/phone';
import { useInput } from 'hooks/useInput';
import { ToastVariant, useToast } from 'hooks/useToast';
import React, { ComponentType, useEffect, useState } from 'react';
import { AuthenticatedUser } from 'types/Authentication';
import { Customer } from 'types/Customer';

import { StepProps } from 'components/FirstTimeProfile/types';
import { PhoneCodeVerificationModal } from 'components/Modals/PhoneCodeVerificationModal';
import { Box, Button, Grid, Input, Typography } from 'components/shared';

import { removeMaskFromPhone, validatePhoneNumber } from './utils';

function applyMaskToPhone(phone: string): string | undefined {
  try {
    if (phone?.length === 10 && phone?.indexOf('_') === -1) {
      return (
        phone[0] +
        phone[1] +
        phone[2] +
        '-' +
        phone[3] +
        phone[4] +
        phone[5] +
        '-' +
        phone[6] +
        phone[7] +
        phone[8] +
        phone[9]
      );
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
}

export interface WithBasic {
  inputs: {
    firstName: any;
    lastName: any;
    phone: any;
  };
  formHasChanged: boolean;
  updateInitialValuesToCurrentValues: () => void;
}

// Does of the inputs changed from the initial value?
const formHasBeenChanged = (inputs: any[]): boolean => {
  console.log('const formHasBeenChanged = (inputs: any[]): boolean => ', { inputs });
  for (let index = 0; index < inputs.length; index++) {
    const input = inputs[index];
    if (input?.hasChanged === true) {
      return true;
    }
  }

  return false;
};

export interface CustomerFormInputs {
  firstName: any;
  lastName: any;
  phone: any;
}

export const withBasicInformation = (Component: ComponentType<any>): any => {
  const WrapperComponent = (props: StepProps): JSX.Element => {
    // inputs
    const firstName = useInput(props?.customer?.firstName || '');
    const lastName = useInput(props?.customer?.lastName || '');
    console.log(props.customer.phone);
    const phone = useInput(applyMaskToPhone(props.customer.phone) || '');

    const formHasChanged = formHasBeenChanged([firstName, lastName, phone]);

    const _setInitialValuesToCurrentValues = (): void => {
      [firstName, lastName, phone].forEach((input) => {
        input.setInitialValue(input.value);
      });
    };

    console.log('========================== withBasicInformation', {
      formHasChanged,
      inputs: [firstName.value, lastName.value],
    });

    return (
      <Component
        form={{
          inputs: {
            firstName,
            lastName,
            phone,
          },
          formHasChanged: formHasChanged,
          updateInitialValuesToCurrentValues: _setInitialValuesToCurrentValues,
        }}
        {...props}
      />
    );
  };
  return WrapperComponent;
};

type BasicSectionProps = StepProps & {
  form: WithBasic;
  user: AuthenticatedUser;
  customer: Customer;
  onFormHasChanged: (formHasChanged: boolean) => void;
  refetchCustomer: () => Promise<void>;
  logError: (section: 'Basic', error: string) => void;
};

const validateName = (name: string): boolean => {
  // regex must contain case insensitive letters (includes unicode accented characters)
  const alphabetRegex = /^[A-Za-z\p{Letter}-]+$/gu;
  // if no valid characters are found, null is returned
  return name.match(alphabetRegex) !== null;
};

const BasicSection = (props: BasicSectionProps): JSX.Element => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [firstNameErrMsg, setFirstNameErrMsg] = useState<string | null>(null);
  const [lastNameErrMsg, setLastNameErrMsg] = useState<string | null>(null);
  const [phoneErrMsg, setPhoneErrMsg] = useState<string | null>(null);
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const { updateHighlighter } = useUpdateHighlighter();
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const { sendPhoneCode } = useSendPhoneVerificationCode();
  const { showToast } = useToast();

  const { firstName, lastName, phone } = props.form.inputs;

  const _onSubmitForm = async (): Promise<void> => {
    setLoading(true);
    const validFirstName = validateName(firstName.value);
    if (!validFirstName) {
      const errMessage = 'Invalid given name found.';
      setFirstNameErrMsg(errMessage);
      props.logError('Basic', errMessage);
    }

    const validLastName = validateName(lastName.value);
    if (!validLastName) {
      const errMessage = 'Invalid family name found.';
      setLastNameErrMsg(errMessage);
      props.logError('Basic', errMessage);
    }

    const validPhone = validatePhoneNumber(phone.value);
    if (!validPhone) {
      const errMessage =
        'Phone number is not valid. Verify that the number is correct and in the xxx-xxx-xxxx format';
      setPhoneErrMsg(errMessage);
      props.logError('Basic', errMessage);
    }

    // error is already handled by updateUser, this is just for debugging purposes
    try {
      if (validFirstName && validLastName && validPhone) {
        if (phone.hasChanged) {
          setModalIsVisible(true);
        } else {
          await handleUpdateHlApi();
        }

        setFirstNameErrMsg(null);
        setLastNameErrMsg(null);
        setPhoneErrMsg(null);
      }
    } catch (err) {
      console.error(
        '<<<<<<<<<<<<<<<<<< Unable to update user within BasicSection. Error: ' +
          err +
          '>>>>>>>>>>>>>>>>>>>>>'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateHlApi = async () => {
    // we do not currently expose a way to update email or phone in the highlighter portal
    const resp = await updateHighlighter({
      id: props.customer.id!,
      firstName: firstName.value,
      lastName: lastName.value,
      phone: removeMaskFromPhone(phone.value),
    });
    if (resp.ok) {
      props.form.updateInitialValuesToCurrentValues(); // so formHasChanged functions correctly on next use
      props.refetchCustomer();
      showToast({
        text: 'Profile updated successfully',
        variant: ToastVariant.Success,
      });
    } else {
      if (props.logError) {
        const errorMessage = 'Unable to update customer information';
        props.logError('Basic', errorMessage);
      }
    }
  };

  const _handleClose = (): void => {
    setModalIsVisible(false);
  };

  const _handleCodeVerified = async (): Promise<void> => {
    await handleUpdateHlApi();
    setModalIsVisible(false);
    setVerificationCodeSent(false);
  };

  const _sendCallPhoneCode = async () => {
    await sendPhoneCode(removeMaskFromPhone(phone.value), 'Call');
    setVerificationCodeSent(true);
  };

  const _sendTextPhoneCode = async () => {
    await sendPhoneCode(removeMaskFromPhone(phone.value), 'Sms');
    setVerificationCodeSent(true);
  };

  useEffect(() => {
    if (validateName(firstName.value)) setFirstNameErrMsg(null);
    if (validateName(lastName.value)) setLastNameErrMsg(null);
    if (validatePhoneNumber(phone.value)) setPhoneErrMsg(null);
  }, [firstName, lastName, phone]);

  useEffect(() => {
    setVerificationCodeSent(false);
  }, [phone.onChange]);

  useEffect(() => {
    props.onFormHasChanged(props.form.formHasChanged);
  }, [props.form.formHasChanged]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        style={{ marginBottom: 24 }}
      >
        <Grid item>
          <Typography variant="h5">{'Basic'}</Typography>
        </Grid>
        <Grid item>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={_onSubmitForm}
            disabled={!props.form.formHasChanged || loading}
            id={'save-basic-info-button'}
          >
            {loading ? 'Loading' : 'Save Basic'}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {firstNameErrMsg && (
            <Box marginBottom="24px">
              <Typography
                variant="body2"
                style={{ marginTop: 16, color: theme.palette.warning.main }}
              >
                {firstNameErrMsg}
              </Typography>
            </Box>
          )}
          <Input
            id={'givenName'}
            label={'given name'}
            variant={'outlined'}
            fullWidth
            value={firstName.value}
            onChange={firstName.onChange}
            onFocus={firstName.onFocus}
            theme={firstName.error ? 'error' : undefined}
            helperText={firstName.helperText}
            InputProps={{ readOnly: loading }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {lastNameErrMsg && (
            <Box marginBottom="24px">
              <Typography
                variant="body2"
                style={{ marginTop: 16, color: theme.palette.warning.main }}
              >
                {lastNameErrMsg}
              </Typography>
            </Box>
          )}
          <Input
            id={'familyName'}
            label={'Family Name'}
            variant={'outlined'}
            fullWidth
            value={lastName.value}
            onChange={lastName.onChange}
            onFocus={lastName.onFocus}
            theme={lastName.error ? 'error' : undefined}
            helperText={lastName.helperText}
            InputProps={{ readOnly: loading }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            id={'email'}
            label={'Email'}
            variant={'outlined'}
            fullWidth
            value={props.user.email}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {(phoneErrMsg || phone.hasChanged) && (
            <Box marginBottom="24px">
              <Typography
                variant="body2"
                style={{
                  marginTop: 16,
                  color: phoneErrMsg ? theme.palette.warning.main : 'primary',
                }}
              >
                {phoneErrMsg
                  ? phoneErrMsg
                  : 'Your phone number has changed. You will be asked to verify your new number when "Save Basic" is clicked.'}
              </Typography>
            </Box>
          )}
          <Input
            id={'phone'}
            label={'Phone'}
            variant={'outlined'}
            onChange={phone.onChange}
            fullWidth
            value={phone.value}
            InputProps={{ readOnly: false }}
          />
        </Grid>
      </Grid>
      <PhoneCodeVerificationModal
        onVerified={_handleCodeVerified}
        phone={removeMaskFromPhone(phone.value)}
        onClose={_handleClose}
        sendCallCode={_sendCallPhoneCode}
        sendTextCode={_sendTextPhoneCode}
        verificationCodeSent={verificationCodeSent}
        title={'verify phone'}
        open={modalIsVisible}
        buttons={[]}
      />
    </>
  );
};
export default withBasicInformation(BasicSection);
