// TODO: This is a copy of the component from showcase-ui
// Refactor this when we can
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { EmptyStateProps } from './types';

const iconSize = 176;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    maxWidth: '554px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px 0px',
  },
  iconContainer: {
    color: theme.palette.action.disabledBackground,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: `${iconSize}px`,
    height: `${iconSize}px`,
    width: `${iconSize}px`,
    lineHeight: '0',
    marginBottom: '16px',
  },
  textContainer: {
    color: theme.palette.action.disabled,
    marginBottom: '16px',
    textAlign: 'center',
  },
}));

const BaseEmptyState = ({ icon, title, body, footnote }: EmptyStateProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.iconContainer}>{icon}</Box>
      <Box className={classes.textContainer}>{title}</Box>
      {body ? <Box className={classes.textContainer}>{body}</Box> : undefined}
      {footnote ? <Box className={classes.textContainer}>{footnote}</Box> : undefined}
    </Box>
  );
};

export default BaseEmptyState;