import React from 'react';

import { ArrowDropDown, Box, Divider, Hidden, Menu } from 'components/shared';

import { useHeaderStyles } from './Header.styles';
import { HeaderDrawer } from './HeaderDrawer';
import { LinkWrapper } from './LinkWrapper';
import { PrivateRouteName } from './headerUtils';

interface PrivateHeaderProps {
  onClickPrivateRoute: (routeName: PrivateRouteName) => void;
}

export const PrivateHeader = (props: PrivateHeaderProps) => {
  const { onClickPrivateRoute: onClick } = props;

  const classes = useHeaderStyles();

  return (
    <Box data-testid="private-header" className={classes.container}>
      {/* Desktop Header */}
      <Hidden smDown>
        <Box className={classes.linkSpacing}>
          <LinkWrapper routeName="home" onClick={onClick} />
          <LinkWrapper routeName="help-faq" onClick={onClick} />
        </Box>

        <Box>
          <Menu data-testid='private-header-dropdown' buttonText="My Account" icon={ArrowDropDown}>
            <LinkWrapper isSmallFont routeName="settings" onClick={onClick} />
            <LinkWrapper isSmallFont routeName="preferences" onClick={onClick} />
            <LinkWrapper isSmallFont routeName="logout" onClick={onClick} />
          </Menu>
        </Box>
      </Hidden>

      {/* Mobile Header */}
      <Hidden mdUp>
        <HeaderDrawer>
          <LinkWrapper routeName="home" onClick={onClick} />
          <LinkWrapper routeName="help-faq" onClick={onClick} />

          <Divider className={classes.divider} />

          <LinkWrapper routeName="settings" onClick={onClick} />
          <LinkWrapper routeName="preferences" onClick={onClick} />
          <LinkWrapper routeName="logout" onClick={onClick} />
        </HeaderDrawer>
      </Hidden>
    </Box>
  );
};
