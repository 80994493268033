// TODO: Refactor this after we finish migrating everything from showcase-ui
// This is an exact copy of the component from showcase-ui
import React, { FunctionComponent } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

export interface StyleProps {
    color: 'primary' | 'secondary' | 'info' | 'error' | 'warning' | 'success';
}

const useCustomStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            height: 40,
        },
        '& .MuiInputLabel-root': {
            top: -7,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: (props: StyleProps): string => {
                return theme.palette[props.color].main;
            },
        },
        '& .MuiInputLabel-outlined': {
            color: (props: StyleProps): string => {
                return theme.palette[props.color].main;
            },
        },
        '& .MuiFormHelperText-root': {
            color: (props: StyleProps): string => {
                return theme.palette[props.color].main;
            },
        },
        '& .MuiSvgIcon-root': {
            color: (props: StyleProps): string => {
                return theme.palette[props.color].main;
            },
        },
        '& .MuiFormHelperText-contained': {
            marginLeft: 0,
            fontStyle: 'normal',
        },
    },
}));

const getInputVariant = (variant?: string): 'standard' | 'filled' | 'outlined' | undefined => {
    if (variant === 'text') {
        return 'standard';
    } else if (variant === 'outlined') {
        return 'outlined';
    } else if (variant === 'contained') {
        return 'filled';
    } else {
        return 'standard';
    }
};

export const Input: FunctionComponent<InputProps> = (props: InputProps): JSX.Element => {
    const theme = props.theme || 'primary';
    const variant = getInputVariant(props.variant);
    const classes = useCustomStyles({ color: theme });
    return (
        <TextField
            // helperText={props.helperText}
            // onBlur={props.onBlur}
            className={classes.root}
            {...props}
            error={props.error || theme === 'error'}
            variant={variant}
        />
    );
};

export type InputProps = TextFieldProps & {
    id: string;
    label: string;
    variant?: 'text' | 'outlined' | 'contained';
    theme?: 'primary' | 'secondary' | 'info' | 'error' | 'warning' | 'success';
    helperText?: string;
    error?: boolean;
    onBlur?: (e: any | undefined) => any | void;
    onFocus?: (e: any | undefined) => any | void;
};

