import { Widget } from '@typeform/embed-react';
import { SurveyStatus } from 'types/Offer';

import { StyledModal } from 'components/shared';

import { CloseButton } from '../SurveyIFrame/CloseButton';
import styles from './styles.module.scss';
import type { SurveyEmbedProps } from './types';

const SurveyEmbed = (props: SurveyEmbedProps): JSX.Element => {
  console.debug('SurveyEmbed: rendering with props', { props });

  const { externalId, highlighterId, isOpen, onClose, onStatusChange, surveyInstance } =
    props;

  const { id: surveyInstanceId, surveyId } = surveyInstance;

  const handleClose = () => {
    console.debug('SurveyEmbed: Closing Parent Modal.', {
      externalId,
      surveyInstanceId,
    });
    onClose();
  };

  const handleManualClose = () => {
    console.debug('SurveyEmbed: Close Button Clicked.', {
      externalId,
      surveyInstanceId,
    });
    handleClose();
  };

  const handleCloseEvent = () => {
    console.debug('SurveyEmbed: Received Close Event.', {
      externalId,
      surveyInstanceId,
    });
    handleClose();
  };

  // const handleEndingButtonClickEvent = ({ formId }: { formId: string }) => {
  //   console.debug('SurveyEmbed: Received Ending Button Click Event.', {
  //     externalId,
  //     formId,
  //     surveyInstanceId,
  //   });
  //   handleClose();
  // };

  const handleQuestionChangedEvent = ({
    formId,
    ref,
  }: {
    formId: string;
    ref: string;
  }) => {
    console.debug('SurveyEmbed: Received Question Changed Event.', {
      externalId,
      formId,
      ref,
      surveyInstanceId,
    });
  };

  const handleSurveyCompletedEvent = ({
    formId,
    responseId,
  }: {
    formId: string;
    responseId: string;
  }) => {
    console.debug(
      'SurveyEmbed: Received Survey Completed Event. Setting Survey to "Pending Response".',
      {
        externalId,
        formId,
        responseId,
        surveyInstanceId,
      }
    );
    onStatusChange({
      eventType: SurveyStatus.PendingResponse,
      surveyId: surveyInstanceId,
      userId: highlighterId,
    });
  };

  const handleSurveyReadyEvent = ({ formId }: { formId: string }) => {
    console.debug(
      'SurveyEmbed: Received Survey Ready Event. Setting Survey to "Started".',
      {
        externalId,
        formId,
        surveyInstanceId,
      }
    );
    onStatusChange({
      eventType: SurveyStatus.Started,
      surveyId: surveyInstanceId,
      userId: highlighterId,
    });
  };

  const handleSurveyStartedEvent = ({
    formId,
    responseId,
  }: {
    formId: string;
    responseId: string;
  }) =>
    console.debug('SurveyEmbed: Received Survey Started Event.', {
      externalId,
      formId,
      responseId,
      surveyInstanceId,
    });

  return (
    <StyledModal
      className={styles.modal}
      fullScreen
      onClose={handleManualClose}
      open={isOpen}
    >
      <CloseButton onClose={handleManualClose} />

      {/* Has CSS styling in `App.css` that controls hiding the `x` button */}
      <Widget
        className={styles.widget}
        hidden={{
          highlighterId,
          highlightSurveyId: surveyId,
          surveyInstanceId,
        }}
        inlineOnMobile
        id={externalId}
        onClose={handleCloseEvent}
        // Skipping this event since this prevents redirects
        // onEndingButtonClick={handleEndingButtonClickEvent}
        onQuestionChanged={handleQuestionChangedEvent}
        onReady={handleSurveyReadyEvent}
        onStarted={handleSurveyStartedEvent}
        onSubmit={handleSurveyCompletedEvent}
      />
    </StyledModal>
  );
};

export default SurveyEmbed;
