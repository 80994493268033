import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Survey } from 'types/Offer';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

/**
 * @param dt date to convert to unit timestamp
 * @returns number of seconds since epoch
 */
function getUnixFromDate(dt: Date): number {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTime
  // getTime() returns ms since Epoch, so we convert to seconds
  return Math.floor(dt.getTime() / 1000);
}

/**
 *
 * @param timeInSeconds UNIX timestamp
 * @returns date object initialized with passed date
 */
function getDateFromUnix(timeInSeconds: number): Date {
  const milliseconds = timeInSeconds * 1000;
  return new Date(milliseconds);
}

// const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const shortMonth = new Intl.DateTimeFormat('default', { month: 'short' });
const longMonth = new Intl.DateTimeFormat('default', { month: 'long' });

function getDateAsMonthDayYearString(
  dateObj: Date,
  monthFormat?: 'long' | 'short'
): string {
  const day = dateObj.getDate();
  const month =
    monthFormat === 'short' ? shortMonth.format(dateObj) : longMonth.format(dateObj);
  const year = dateObj.getFullYear();

  return `${month} ${day}, ${year}`;
}

const getAbbreviatedDayOfWeek = (dayNumber: number) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  if (dayNumber < 7 && dayNumber >= 0) {
    return days[dayNumber];
  } else return '';
};

const getSurveyDaysLeftFromDate = ({
  deadline,
}: {
  deadline: Survey['deadline'];
}): number => {
  const dateDeadline = new Date(Date.parse(deadline!));
  const oneDay = 24 * 60 * 60 * 1000;
  const today = new Date().getTime();
  const daysLeft = Math.ceil((dateDeadline.getTime() - today) / oneDay);
  return daysLeft > 0 ? daysLeft : 0;
};

const formatSurveyDueDateFromDate = ({
  deadline,
}: {
  deadline: Survey['deadline'];
}): string => {
  const dueDate = dayjs.utc(deadline).tz(dayjs.tz.guess());
  return `${getAbbreviatedDayOfWeek(dueDate.day())}, ${dueDate.format('M/D [@] h A z')}`;
};

// This will no longer be needed after cp-3540, during that work we will invest
// in having the backend calculate and send this bool
const isSurveyOverdueFromDate = ({
  deadline,
}: {
  deadline: Survey['deadline'];
}): boolean => {
  const dateDeadline = new Date(Date.parse(deadline!));
  const today = new Date().getTime();
  const difference = dateDeadline.getTime() - today;
  return difference <= 0 ? true : false;
};

type ISODate = string | undefined;

const isISOString = (input: ISODate): input is string => {
  const parsedDate = Date.parse(input as string);
  if (isNaN(parsedDate)) {
    return false;
  }
  const iso8601Pattern =
    /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[-+]\d{2}:\d{2}))?$/;

  return iso8601Pattern.test(input as string);
};

export {
  getAbbreviatedDayOfWeek,
  getUnixFromDate,
  getDateFromUnix,
  getDateAsMonthDayYearString,
  getSurveyDaysLeftFromDate,
  formatSurveyDueDateFromDate,
  isSurveyOverdueFromDate,
  isISOString,
};
