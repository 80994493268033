// TODO: This is a copy of the component from showcase-ui
// Refactor this when we can
import {
  Badge as MuiBadge,
  makeStyles,
  Tab as MuiTab,
  TabProps as MuiTabProps,
  Theme,
  Typography,
} from '@material-ui/core';

const useTabBadgeStyles = makeStyles(() => ({
  badge: {
    position: 'absolute',
    top: 0,
    right: '-12px',
  },
}));

interface TabBadgeProps {
  badgeValue?: number;
}

const TabBadge = (props: TabBadgeProps): JSX.Element => {
  const classes = useTabBadgeStyles();

  return (
    <MuiBadge
      className={classes.badge}
      badgeContent={props.badgeValue}
      color="error"
      overlap="rectangular"
    />
  );
};

const useTabLabelStyles = makeStyles(() => ({
  underline: {
    backgroundColor: '#e0e0e0',
    height: '3px',
    position: 'absolute',
    bottom: '-5px',
    left: '1px',
    right: '1px',
  },
  container: {
    position: 'relative',
  },
}));

interface TabLabelProps {
  text: string;
  badgeValue?: number;
}

const TabLabel = (props: TabLabelProps): JSX.Element => {
  const classes = useTabLabelStyles();

  return (
    <div className={classes.container}>
      <TabBadge badgeValue={props.badgeValue} />
      <Typography noWrap variant="h4">
        {props.text}
      </Typography>
    </div>
  );
};

const useTabPanelStyles = makeStyles(() => ({
  panel: {
    width: '100%',
    height: '100%',
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string | number;
}
const TabPanel = (props: TabPanelProps): JSX.Element => {
  const classes = useTabPanelStyles();

  return (
    <div
      className={classes.panel}
      role="tabpanel"
      hidden={props.value !== props.index}
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
    >
      {props.children}
    </div>
  );
};

const useTabStyles = makeStyles((theme: Theme) => ({
  root: {
    opacity: 1,
    ...theme.typography.h4,
    '&:hover': {
      opacity: 1,
    },
    maxWidth: '100%',
    minWidth: '1%',
    marginRight: '48px',
    paddingLeft: '0',
    paddingRight: '0',
    overflow: 'visible', // for badge
  },
  labelIcon: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    backgroundColor: 'lightblue',
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    position: 'relative',
    paddingTop: '4px',
  },
  selected: {
    color: theme.palette.text.primary,
  },
}));

export interface TabProps extends Omit<MuiTabProps, 'label' | 'tabIndex'> {
  label: string;
  badgeValue?: number;
}

const Tab = (props: TabProps) => {
  const classes = useTabStyles();
  const { label, badgeValue, ...rest } = props;

  return (
    <MuiTab
      {...rest}
      classes={classes}
      label={<TabLabel text={label} badgeValue={badgeValue} />}
      key={label}
    />
  );
};

export { Tab, TabPanel };
