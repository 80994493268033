import {
  ItemSurveyInstance,
  OneOffSurveyInstance,
  OptInSurveyInstance,
  Survey,
  SurveyType,
} from 'types/Offer';

const isOptInSurvey = (survey: Survey): survey is OptInSurveyInstance => {
  return survey.type === SurveyType.OptIn;
};
const isItemSurvey = (survey: Survey): survey is ItemSurveyInstance => {
  return survey.type === SurveyType.Item;
};

const isOneOffSurvey = (survey: Survey): survey is OneOffSurveyInstance => {
  return survey.type === SurveyType.OneOff;
};

interface FilteredSurveys {
  oneOffSurveyInstances: OneOffSurveyInstance[];
  optInSurveyInstances: OptInSurveyInstance[];
  itemSurveyInstances: ItemSurveyInstance[];
}
const filterSurveysByType = (highlighterSurveys: Survey[]): FilteredSurveys => {
  const oneOffSurveyInstances: OneOffSurveyInstance[] = [];
  const optInSurveyInstances: OptInSurveyInstance[] = [];
  const itemSurveyInstances: ItemSurveyInstance[] = [];

  highlighterSurveys.forEach((survey) => {
    if (survey.isVisible) {
      switch (survey.type) {
        case SurveyType.Item:
          if (isItemSurvey(survey)) {
            itemSurveyInstances.push(survey);
          }
          break;
        case SurveyType.OptIn:
          if (isOptInSurvey(survey)) optInSurveyInstances.push(survey);
          break;
        case SurveyType.OneOff:
          if (isOneOffSurvey(survey)) oneOffSurveyInstances.push(survey);
          break;
        default:
          console.warn(`receieved unrecognized survey type, ${survey.type}`);
      }
    }
  });
  return {
    oneOffSurveyInstances,
    optInSurveyInstances,
    itemSurveyInstances,
  };
};

const getFeedbackBadgeValue = (
  oneOffSurveys: Survey[],
  itemSurveys: Survey[]
): number => {
  return oneOffSurveys.length + itemSurveys.length;
};

const parseSurveyVendorUrl = (surveyUrl: string): string[] => {
  const split = surveyUrl.split('://');
  return [split[0], split?.[1] ?? ''];
}

export { getFeedbackBadgeValue, filterSurveysByType, parseSurveyVendorUrl };
