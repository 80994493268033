import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ConfigCatProvider, LogLevel, createConsoleLogger } from 'configcat-react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Route, Switch } from 'react-router-dom';
import { Router } from 'react-router-dom';
import history from 'utils/History';
import { MonitoringParams, initMonitoring } from 'utils/monitoring';
import { getToken } from 'utils/token';

import App from './App';
import Auth0ProviderWithHistory from './auth0-provider-with-history';
import { GoogleLoginButton } from 'components/login/GoogleLoginButton';

const loggingParams: MonitoringParams = {
  environment: process.env.REACT_APP_ENVIRONMENT ?? 'unknown',
  version: process.env.REACT_APP_VERSION ?? 'unknown',
  host: window.location.host,
};
initMonitoring(loggingParams);

const apiKey = process.env.REACT_APP_HIGHLIGHT_API_KEY ?? '';
const configCatKey = process.env.REACT_APP_CONFIGCAT_SDK_KEY ?? '';
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL
    ? encodeURI(process.env.REACT_APP_API_URL) + '/query'
    : '',
  fetchOptions: {
    mode: 'cors',
  },
});

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...(!headers?.authorization && { Authorization: token ? `Bearer ${token}` : '' }),
      ...(apiKey && { 'x-api-key': apiKey }),
    },
  };
});

export const graphqlClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
const logger = createConsoleLogger(LogLevel.Info);

ReactDOM.render(
  <ConfigCatProvider sdkKey={configCatKey} options={{ logger }}>
    <Router history={history}>
      <Auth0ProviderWithHistory>
        <ApolloProvider client={graphqlClient}>
          <GoogleOAuthProvider clientId={googleClientId}>
            <Switch>
              <Route exact path="/signin" component={GoogleLoginButton} />
              <Route path="/" component={App} />
            </Switch>            
          </GoogleOAuthProvider>
        </ApolloProvider>
      </Auth0ProviderWithHistory>
    </Router>
  </ConfigCatProvider>,
  document.getElementById('root')
);
