import { CredentialResponse } from '@react-oauth/google';
import { LoginContext } from ".";

export interface GoogleLoginSuccess {
  idToken: string,
  context: LoginContext,
  referralCode?: string,
  success: boolean
}

export interface GoogleLoginFailure {
  context: LoginContext,
  success: boolean
}

export const onLoginSuccess = (target: Window, targetOrigin: string, context: LoginContext, referralCode?: string) => 
  (response:CredentialResponse) => {
    const message: GoogleLoginSuccess = {
      idToken: response.credential ?? '',
      context,
      referralCode: referralCode ?? '',
      success: true,
    }
    target.postMessage(message, targetOrigin);
}

export const onLoginFailure = (target: Window, targetOrigin: string, context: LoginContext) => 
  () => target.postMessage({ context, success: false, }, targetOrigin);
