import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItem, MenuSharp } from 'components/shared';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    // TODO: Refactor after design system is migrated. These are the original styles, this is the height of the Header component
    paddingTop: '105px',
    width: '240px',
  },
  listItem: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
}));

interface HeaderDrawerProps {
  children: React.ReactNode;
}

export const HeaderDrawer = ({ children }: HeaderDrawerProps) => {
  const [isDrawerOpen, toggleIsDrawerOpen] = useState(false);
  const classes = useStyles();

  const toggleDrawer = () => {
    toggleIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <IconButton onClick={toggleDrawer} aria-label="open drawer">
        <MenuSharp style={{ color: 'white' }} fontSize="large" />
      </IconButton>

      <Drawer
        open={isDrawerOpen}
        color="primary"
        onClose={toggleDrawer}
        anchor="right"
        BackdropProps={{ invisible: true }}
        classes={{ paper: classes.container }}
      >
        <List>
          {React.Children.map(children, (child, index) => (
            <ListItem className={classes.listItem} key={index}>
              {child}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};
