import { APP_CONSTANTS } from 'constants/AppConstants';
import { useVerifyPhoneCode } from 'hooks/api/phone';
import { ChangeEvent, useEffect, useState } from 'react';
import { debounce } from 'utils/debounce';
import { localStorage } from 'utils/localStorage';

import CodeInput from 'components/Forms/CodeInput';
import { Box, Link, Typography } from 'components/shared';

import SectionHeader from './SectionHeader';
import { StepProps } from './types';

const HELPER_TEXT = {
  DEFAULT: 'Please enter your verification code here',
  CODE_VERIFIED: 'Code verified! Creating your profile now...',
  INVALID_CODE:
    'The code you have entered is invalid. Please enter a valid code or go back to the previous step and send a new one.',
  UNKNOWN_ERROR: 'There was an error with the request',
};

export const CodeVerification = (props: StepProps): JSX.Element => {
  const [phone, setPhone] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [codeIsValid, setCodeIsValid] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>('');
  const { verifyPhoneCode } = useVerifyPhoneCode();

  useEffect(() => {
    setPhone(localStorage.getItem(APP_CONSTANTS.ONBOARDING.PHONE_STORAGE_KEY));
  }, []);

  useEffect(() => {
    if (phone) {
      debounceCode();
    }
  }, [code]);

  const debounceCode = debounce(async () => {
    if (code?.length > 5) {
      await validateCode();
    }
  }, 300);

  const validateCode = async (): Promise<void> => {
    setError(false);
    setLoading(true);

    try {
      const response = await verifyPhoneCode(phone, code);

      if (response.ok) {
        setHelperText(HELPER_TEXT.CODE_VERIFIED);
        setCodeIsValid(true);
        setError(false);
        localStorage.deleteItem(APP_CONSTANTS.ONBOARDING.PHONE_STORAGE_KEY);
        setTimeout(() => props.nextStep(), 500);
      } else if (response.status == 400) {
        setHelperText(HELPER_TEXT.INVALID_CODE);
        setCodeIsValid(false);
        setError(true);
        setLoading(false);
      } else {
        setHelperText(HELPER_TEXT.UNKNOWN_ERROR);
        setCodeIsValid(false);
        setError(true);
        setLoading(false);
        return;
      }
    } catch {
      setHelperText(HELPER_TEXT.UNKNOWN_ERROR);
      setCodeIsValid(false);
      setError(true);
      setLoading(false);
      return;
    }
  };

  const onCodeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setCode(event.target.value);
  };

  const goBack = (): void => props.previousStep();

  return (
    <>
      <SectionHeader
        title={`Hello, ${props?.user?.given_name}!`}
        body={
          'Please take a moment to verify your phone number. This helps us confirm your identity and keep your account secure.'
        }
      />
      <Box marginBottom="24px">
        <CodeInput
          value={code}
          onChange={onCodeChange}
          id={'verify-phone'}
          label={'VERIFICATION CODE'}
          variant={'outlined'}
          error={error}
          success={codeIsValid}
          loading={loading}
          helperText={helperText || HELPER_TEXT.DEFAULT}
          autoFocus
        />
      </Box>
      <Box marginBottom="24px">
        <Typography variant={'caption'}>
          {`Didn't get a code? Wait a minute and then `}
          <Link onClick={goBack}>retry</Link>
        </Typography>
      </Box>
    </>
  );
};

export default CodeVerification;
