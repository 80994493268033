import { useAuth0 } from '@auth0/auth0-react';
import { SWR_QUERY_KEY } from 'constants/api';
import {
  fetchHighlighterApi,
  swrHighlighterApiFetcher,
} from 'data/services/RequestMethods';
import { ErrorToastWithContactUsLink, ToastVariant, useToast } from 'hooks/useToast';
import { useMemo } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { Survey, SurveyStatus } from 'types/Offer';
import { filterSurveysByType } from 'utils/offersUtils';

const useGetSurveys = () => {
  const { showToast } = useToast();

  const {
    data: surveys = [],
    isLoading: isHlApiLoading,
    mutate,
  } = useSWR<Survey[]>(
    SWR_QUERY_KEY.GetCustomerSurveysHttp,
    (url) => swrHighlighterApiFetcher(url),
    {
      revalidateOnFocus: false,
      onError: (error) => {
        console.error(`error fetching oneOffSurveys ${error}`);
        showToast({
          text: (
            <ErrorToastWithContactUsLink errorText={`Error fetching one off surveys.`} />
          ),
          variant: ToastVariant.Error,
        });
      },
    }
  );

  const { itemSurveyInstances, optInSurveyInstances, oneOffSurveyInstances } = useMemo(
    () => filterSurveysByType(surveys),
    [surveys]
  );

  return {
    optInSurveyInstances,
    itemSurveyInstances,
    oneOffSurveyInstances,
    isHlApiLoading,
    revalidateSurveyInstances: mutate,
  };
};

interface UseUpdateSurveyReq {
  data: { status: SurveyStatus };
}

const useUpdateSurveys = () => {
  const { mutate } = useSWRConfig();
  const { showToast } = useToast();
  const { logout } = useAuth0();

  const updateSurvey = async ({
    surveyInstanceId,
    req,
    shouldSkipRefetch = false,
  }: {
    surveyInstanceId: string;
    req: UseUpdateSurveyReq;
    shouldSkipRefetch?: boolean;
  }) => {
    const { data } = req;
    console.log(
      `updating highlighter survey with id ${surveyInstanceId} to status ${req.data.status}`
    );

    try {
      const response = await fetchHighlighterApi({
        route: `/my/HighlighterSurveys/${surveyInstanceId}`,
        method: 'PATCH',
        data: data,
      });

      if (response.status !== 200) {
        const errorMessage = 'could not update survey instance to status, got non-200 response: ' +
          `${surveyInstanceId}, ${req.data.status}, ${response.status}`;
        console.error(errorMessage);
        showToast({
          text: (
            <ErrorToastWithContactUsLink
              errorText={`We weren't able to update your survey.`}
            />
          ),
          variant: ToastVariant.Error,
        });

        // 404 is likely an indication of stale data (PATCH on non-existent survey), since upstream database can be modified manually
        // by CSMs (e.g. 'Refresh Surveys' action). In this edge case, a browser refresh should enforce data agreement
        if (response.status === 404) {
          location.reload();
        }

        // This happens to people who load the page and wait more than the token expiry time
        // (24 hours) to open the survey and complete it. This is a poor user experience, but the
        // alternative is the user re-opening the survey and getting the error toast until they
        // decide to refresh the page or give up.
        if (response.status === 401) {
          logout({ returnTo: window.location.origin });
        }

        return;
      }
    } catch (e) {
      console.error(`could not update highlighter survey with instance id ${surveyInstanceId}. exc: ${e}`);
    }

    if (!shouldSkipRefetch) {
      await mutate(SWR_QUERY_KEY.GetCustomerSurveysHttp);
    }
  };

  return {
    updateSurvey,
  };
};

export { useGetSurveys, useUpdateSurveys };
